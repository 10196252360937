import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { RubricCore } from 'Types/dealGPT';

interface RubricOverallRatingProps {
  rating: number;
  rubricCore: RubricCore[];
}

export const maturityLevelColors = {
  1: '#F52C00',
  2: '#FF8A00',
  3: '#FFC700',
  4: '#00FCB0',
  5: '#1DD566',
};

const RubricRating: React.FC<RubricOverallRatingProps> = ({ rating, rubricCore }) => {
  return (
    <Box display="flex" border="1px solid #C4C4C4" padding={1} gap={2}>
      {rubricCore.map((core) => (
        <Box
          key={core.id}
          display="flex"
          flexDirection="column"
          flex="1 1 0px"
          border={rating === core.maturityLevel ? '1px solid #C4C4C4' : 'none'}
          /* eslint-disable @typescript-eslint/no-explicit-any */
          sx={{
            backgroundColor:
              rating === core.maturityLevel
                ? maturityLevelColors[rating as any as keyof typeof maturityLevelColors]
                : 'transparent',
          }}
          borderRadius="10px"
          padding={1}
          gap={1}
        >
          <Typography>
            Rating:{' '}
            <span
              /* eslint-disable @typescript-eslint/no-explicit-any */
              style={{
                color:
                  rating !== core.maturityLevel
                    ? maturityLevelColors[
                        core.maturityLevel as any as keyof typeof maturityLevelColors
                      ]
                    : 'inherit',
              }}
            >
              {core.maturityLevel}
            </span>
          </Typography>
          <Divider />
          <Box>{core.description}</Box>
        </Box>
      ))}
    </Box>
  );
};

export default RubricRating;
