import { Container } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import FileManagement from '../components/AdminPage/FileManagement';
import UserStatsPage from '../components/AdminPage/UserStats';
import NavDrawer from '../components/Drawer/NavDrawer';
import NavBar, { NavBarMode } from '../components/NavBar';
import { useAuth } from '../components/Providers/AuthProvider';
import { useFeatures } from '../components/Providers/FeatureProvider';
import {
  drawerWidth,
  MainContainer,
  PageContainer,
  StyledTab,
  StyledTabs,
} from '../theme/CustomComponents';
import { AdminTab } from '../Types/enums';

const AdminPage = (): JSX.Element => {
  const features = useFeatures();
  const { isAdmin } = useAuth();
  const navigate = useNavigate();

  // This logic is to make sure that users who don't have access to the stats page are started on the file management page
  const [currentTab, setCurrentTab] = useState<AdminTab>(
    isAdmin || features.stats_page_access ? AdminTab.USER_STATS : AdminTab.FILE_MANAGEMENT
  );
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState<boolean>(
    window.matchMedia('(min-width: 960px)').matches
  );

  if (!(isAdmin || features.stats_page_access || features.file_management_access))
    navigate('/chat');

  const changeTab = (_event: React.SyntheticEvent, newValue: AdminTab) => {
    setCurrentTab(newValue);
  };

  return (
    <PageContainer>
      <NavDrawer
        drawerWidth={drawerWidth}
        drawerOpen={historyDrawerOpen}
        setDrawerOpen={setHistoryDrawerOpen}
      />
      <MainContainer open={historyDrawerOpen}>
        <NavBar mode={NavBarMode.NIGEL} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'auto',
            height: '100%',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <StyledTabs value={currentTab} onChange={changeTab} centered variant="fullWidth">
              {(isAdmin || features.stats_page_access) && (
                <StyledTab label="User Stats" value={AdminTab.USER_STATS} />
              )}
              {(isAdmin || features.file_management_access) && (
                <StyledTab label="File Management" value={AdminTab.FILE_MANAGEMENT} />
              )}
            </StyledTabs>
          </div>
          <Container
            maxWidth="md"
            sx={{
              position: 'relative',
              bgcolor: 'white',
              height: '100%',
              margin: '50px',
              marginTop: '0',
            }}
          >
            {currentTab === AdminTab.USER_STATS && <UserStatsPage />}
            {currentTab === AdminTab.FILE_MANAGEMENT && <FileManagement />}
          </Container>
        </div>
      </MainContainer>
    </PageContainer>
  );
};

export default AdminPage;
