import {
  Container,
  Dialog,
  DialogTitle,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';

import { ProjectRun } from '../../../Types/dealGPT';
import formatDate from '../../../util/dateUtils';
import { AnalyticsEvent, useAnalytics } from '../../Providers/AnalyticsProvider';

const DialogContainer = styled(Container)(({ theme }) => ({
  marginTop: '8px',
  marginBottom: '24px',

  [theme.breakpoints.up('sm')]: {
    padding: '0px',
  },
}));

export type ArrayFilteredDialogProps = {
  projectRuns: ProjectRun[];
  selectedRun: ProjectRun;
  openDialog: boolean;
  handleCloseDialog: () => void;
  selectRun: (run: ProjectRun) => void;
};

const ProjectRunsDialog = ({
  projectRuns,
  selectedRun,
  openDialog,
  handleCloseDialog,
  selectRun,
}: ArrayFilteredDialogProps) => {
  const analytics = useAnalytics();
  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>Project Runs</DialogTitle>
      <DialogContainer maxWidth="lg" sx={{ marginBottom: '0px ', minWidth: '270px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Run</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projectRuns.map((projectRun, index) => (
              <TableRow
                key={projectRun.id}
                onClick={() => {
                  selectRun(projectRun);
                  analytics.event(AnalyticsEvent.WM_IA_D_RUN_SELECTED, {
                    id: projectRun.id,
                    value: `ProjectId=${projectRun.projectId}`,
                  });
                  handleCloseDialog();
                }}
                sx={{
                  cursor: 'pointer',
                  backgroundColor: projectRun.id === selectedRun.id ? '#CED7E6' : 'inherit',
                  '&:hover': {
                    backgroundColor: projectRun.id === selectedRun.id ? '#CED7E6' : '#E8EEF8',
                  },
                }}
              >
                <TableCell>{projectRuns.length - index}</TableCell>
                <TableCell>{projectRun.endedAt ? 'Complete' : 'Incomplete'}</TableCell>
                <TableCell>
                  {formatDate(projectRun.createdAt, {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  })}
                </TableCell>
                <TableCell>
                  {formatDate(projectRun.createdAt, {
                    hour: '2-digit',
                    minute: 'numeric',
                    second: 'numeric',
                  })}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContainer>
    </Dialog>
  );
};

export default ProjectRunsDialog;
