import { ProjectRunAnalysisQuestion } from '../../Types/dealGPT';

export const getLatestUniqueQuestions = (
  questions: ProjectRunAnalysisQuestion[]
): ProjectRunAnalysisQuestion[] => {
  const latestQuestionsMap: Map<string, ProjectRunAnalysisQuestion> = new Map();
  questions.forEach((question) => {
    const key = `${question.sequence}-${question.displayOrder}`;
    const existingQuestion = latestQuestionsMap.get(key);

    if (
      !existingQuestion ||
      (question.createdAt &&
        existingQuestion &&
        existingQuestion.createdAt &&
        new Date(question.createdAt) > new Date(existingQuestion.createdAt))
    ) {
      latestQuestionsMap.set(key, question);
    }
  });

  return Array.from(latestQuestionsMap.values());
};

export const sortQuestions = (
  questions: ProjectRunAnalysisQuestion[]
): ProjectRunAnalysisQuestion[] => {
  return questions.sort((a, b) => {
    // First compare by sequence
    if (a.sequence !== b.sequence) {
      return a.sequence - b.sequence;
    }
    // Project level questions should always come first
    if (a.scope === 'PROJECT' && b.scope !== 'PROJECT') {
      return -1;
    } else if (a.scope !== 'PROJECT' && b.scope === 'PROJECT') {
      return 1;
    }
    // If sequence is the same, compare by displayOrder (handling undefined cases)
    if (a.displayOrder && b.displayOrder) {
      return a.displayOrder - b.displayOrder;
    }
    // If one of the displayOrders is undefined, place the defined one first
    if (!a.displayOrder) {
      return 1;
    }
    if (!b.displayOrder) {
      return -1;
    }
    // If both are undefined, they are considered equal
    return 0;
  });
};
