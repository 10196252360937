import { Attachment, Send, Stop } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import { PayloadAction } from '@reduxjs/toolkit';
import { Extension } from '@tiptap/core';
import { Color } from '@tiptap/extension-color';
import { Highlight } from '@tiptap/extension-highlight';
import { TextStyle } from '@tiptap/extension-text-style';
import { useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import { closeSnackbar, useSnackbar } from 'notistack';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import ChatBubbleComponent from '../components/Chat/ChatBubble';
import ChatInfo from '../components/Chat/ChatInfo/ChatInfo';
import FileCard from '../components/Chat/FileCard';
import LimitWarning from '../components/Chat/LimitWarning';
import OutputWarning from '../components/Chat/OutputWarning';
import ReusePromptBanner from '../components/Chat/ReusePromptBanner';
import ToolSettings from '../components/Chat/ToolSettings';
import NavDrawer from '../components/Drawer/NavDrawer';
import Feedback from '../components/Feedback';
import NavBar, { NavBarMode } from '../components/NavBar';
import { AnalyticsEvent, useAnalytics } from '../components/Providers/AnalyticsProvider';
import { useAuth } from '../components/Providers/AuthProvider';
import { useFeatures } from '../components/Providers/FeatureProvider';
import { FeedbackContext } from '../constants/feedbackConstants';
import {
  getConversation,
  getConversations,
  getMessages,
  getStreamingResponse,
  postConversation,
  postFile,
  postMessage,
  setCurrentMessagesAsync,
  updateConversation,
  updateFeedback,
} from '../redux/actions/conversationActions';
import { findPrompt } from '../redux/actions/promptActions';
import { selectAppStatus, setCurrentApp } from '../redux/reducers/appStatusReducer';
import {
  selectConversations,
  setCurrentConversation,
  setCurrentNumTokens,
  setIsLoading,
  setIsScrolling,
} from '../redux/reducers/conversationReducer';
import { AppDispatch } from '../redux/store';
import {
  ChatContainer,
  ChatRow,
  drawerWidth,
  MainContainer,
  PageContainer,
  SendButton,
  StyledChatContainer,
  TiptapLabel,
  TiptapStyled,
  TiptapWrapper,
} from '../theme/CustomComponents';
import { Conversation, Message, MessageFile } from '../Types/conversation';
import { AppEnum, Llm, MessageFrom, ToolName } from '../Types/enums';
import { FeedbackInterface } from '../Types/feedback';
import { Prompt } from '../Types/prompt';

export type ChatProps = {
  gleanApplicationId?: string;
};

const Chat = ({ gleanApplicationId }: ChatProps): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const features = useFeatures();
  const analytics = useAnalytics();
  const { currentUser } = useAuth();
  const { conversationId } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const { currentConversation, currentMessages, isLoading, isScrolling } =
    useSelector(selectConversations);
  const { selectedApp, llm } = useSelector(selectAppStatus);

  const messageContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>();
  const [promptId, setPromptId] = useState<string>();
  const [prompt, setPrompt] = useState<Prompt>();
  const [showErrorMessage, setShowErrorMessage] = useState('');
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState<boolean>(
    window.matchMedia('(min-width: 960px)').matches
  );
  const [tools, setTools] = useState<ToolName[]>([]);
  const [showBanners, setshowBanners] = useState<boolean>(true);
  const [showChatInfo, setShowChatInfo] = useState<boolean>(false);

  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<MessageFile[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(true);
  const snackbar30Messages = useRef<string | number>();
  const currentMessagesRef = useRef(currentMessages);
  const shouldCancelRef = useRef(false);
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const [showNormalTextField, setShowNormalTextField] = useState(true);
  const isProgrammaticScroll = useRef(false);

  const EnterKeyExtension = Extension.create({
    name: 'enterKey',

    addKeyboardShortcuts() {
      return {
        'Mod-Enter': () => this.editor.commands.enter(),
        Enter: () => {
          // Prevent default Enter behavior
          return true;
        },
      };
    },
  });

  const editor = useEditor({
    extensions: [
      StarterKit,
      TextStyle,
      Color,
      EnterKeyExtension,
      Highlight.configure({ multicolor: true }),
    ],
    shouldRerenderOnTransaction: false,
  });

  const handleFileDelete = (index: number) => {
    setUploadedFiles((uploadedFiles) => {
      return [...uploadedFiles.slice(0, index), ...uploadedFiles.slice(index + 1)];
    });
  };

  const handleTextChange = (index: number, newText: string) => {
    setUploadedFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = { ...newFiles[index], contents: newText };
      return newFiles;
    });
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      enqueueSnackbar('No files selected.', { variant: 'error', autoHideDuration: 3000 });
      return;
    }

    const files = Array.from(e.target.files);

    // Clear target value to allow the same filename
    // to be uploaded in succession.
    e.target.value = '';

    files.forEach((file) => {
      const fileUploadAnalyticsMetadata = {
        value: file.name,
        size: file.size.toString(),
        type: file.type,
      };
      // Validate file size before uploading to server.
      const fileSizeMB = file.size / 1024 ** 2;
      if (fileSizeMB > 100) {
        analytics.event(
          AnalyticsEvent.WM_NIGEL_FILE_UPLOAD_EXCEED_SIZE_LIMIT,
          fileUploadAnalyticsMetadata
        );
        enqueueSnackbar(
          `Upload Failed. File size too large for file: ${file.name}. Max size: 100MB.`,
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        );
        return;
      }

      const currentIndex = filesToUpload.length;
      setFilesToUpload((filesToUpload) => [...filesToUpload, file]);

      (() => {
        const formData = new FormData();
        formData.append('file', file);
        try {
          dispatch(postFile(formData)).then((action: PayloadAction<MessageFile | unknown>) => {
            const messageFile = action.payload as MessageFile;

            analytics.event(AnalyticsEvent.WM_NIGEL_FILE_UPLOAD, fileUploadAnalyticsMetadata);

            if (messageFile.statusText) {
              setFilesToUpload((filesToUpload) => [
                ...filesToUpload.slice(0, currentIndex),
                ...filesToUpload.slice(currentIndex + 1),
              ]);
              enqueueSnackbar('Network timeout error or file is empty', {
                variant: 'error',
                autoHideDuration: null,
              });
              throw Error(messageFile.statusText);
            }

            if (messageFile.contents === '') {
              handleFileDelete(currentIndex);
            }

            setUploadedFiles((uploadedFiles) => [...uploadedFiles, messageFile]);

            setFilesToUpload((filesToUpload) => [
              ...filesToUpload.slice(0, currentIndex),
              ...filesToUpload.slice(currentIndex + 1),
            ]);
          });
        } catch (error) {
          handleFileDelete(currentIndex);
        }
      })();
    });
  };

  const handleCancel = () => {
    analytics.event(AnalyticsEvent.WM_NIGEL_REGENERATE_RESPONSE);
    shouldCancelRef.current = true;
  };

  const handleStreamingResponse = useCallback(
    (response: Response) => {
      if (response && response.body) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        const initBotMessage = {
          conversationId: currentConversation?.id ?? '',
          from: MessageFrom.AI,
          message: '',
        } as Message;

        dispatch(setCurrentMessagesAsync([...currentMessagesRef.current, initBotMessage])).then(
          async () => {
            setUploadedFiles([]);

            let done, value;
            let result = '';
            let convoId = '';

            do {
              ({ done, value } = await reader.read());

              const navigateToConvo = () => {
                setIsStreaming(false);
                dispatch(setIsLoading(false));
                if (convoId) {
                  dispatch(getConversations());
                  navigate(`/chat/${convoId}`);
                }
              };

              if (shouldCancelRef.current) {
                await reader.cancel();
                shouldCancelRef.current = false;
                setIsStreaming(false);
                navigateToConvo();
              }

              if (done) {
                break;
              }

              let chunk = decoder.decode(value, { stream: true });
              const convoIdIndex = chunk.indexOf('NIGEL CONVERSATION ID:');
              if (convoIdIndex >= 0) {
                const uuidLength = 36;
                convoId = chunk.slice(convoIdIndex + 23, convoIdIndex + 23 + uuidLength);
                chunk = chunk.slice(convoIdIndex + 23 + uuidLength);
              }

              const doneIndex = chunk.indexOf('NEW CONVERSATION ID:');
              if (doneIndex >= 0) {
                convoId = chunk.slice(doneIndex + 21);

                dispatch(setCurrentConversation(undefined));
                navigateToConvo();
              } else {
                result += chunk;
                const newMessages = [...currentMessagesRef.current];
                newMessages[newMessages.length - 1] = {
                  ...newMessages[newMessages.length - 1],
                  message: result,
                };
                dispatch(setCurrentMessagesAsync(newMessages));
              }
            } while (!done);
          }
        );
      }
    },
    [currentConversation?.id, navigate, dispatch]
  );

  const setChatPrompt = (prompt: string) => {
    if (inputRef.current) {
      inputRef.current.value = prompt;
    }
  };

  const getChatPrompt = useCallback(() => {
    if (inputRef.current) {
      return inputRef.current.value;
    }
    if (editor) {
      return editor
        .getText()
        .trim()
        .replace(/\s+([,.!?;:])/g, '$1')
        .replace(/\s+/g, ' '); // Remove double spaces
    }
    return '';
  }, [inputRef, editor]);

  const highlightWords = (text: string, wordsToHighlight: string[]) => {
    // Escape asterisks for regex
    const escapeAsterisks = (str: string) => str.replace(/\*/g, '\\*');
    let highlightedText = text;
    wordsToHighlight.forEach((word) => {
      const regex = new RegExp(`\\b${escapeAsterisks(word)}\\b`, 'gi');
      highlightedText = highlightedText.replace(regex, (matched) => {
        return `<mark style="background-color: thistle;"><span style="color: blue; font-weight: bold;">${
          '&nbsp;' + matched + '&nbsp'
        }</span></mark>`;
      });
    });

    return highlightedText + '&nbsp;';
  };

  const submitMessage = useCallback(
    (message: string, files: MessageFile[], conversationId: string, prompt?: Prompt) => {
      // This function is here so that we can set the currentMessages if needed, but we can avoid it for an autosend prompt
      // we need to use the dispatch().then() pattern if we're doing both, but there's not an easy way to conditionalize that without a lot of duplication
      let messageId = '';

      const actuallySubmitMessage = () => {
        if (
          features.streaming &&
          selectedApp !== AppEnum.BIA &&
          selectedApp !== AppEnum.DALLE &&
          selectedApp !== AppEnum.WM_EMPLOYEE_HANDBOOK &&
          ![Llm.O1, Llm.O1_MINI].includes(llm as Llm)
        ) {
          dispatch(setIsScrolling(false));
          dispatch(
            getStreamingResponse({
              message: { conversationId, message, files },
              userId: currentUser?.id ?? '',
              llm,
            })
          ).then((action: PayloadAction<Response | unknown>) => {
            setIsStreaming(true);
            handleStreamingResponse(action.payload as Response);
          });
        } else {
          dispatch(
            postMessage({
              message: { conversationId, message, files },
              llm,
              gleanApplicationId,
            })
          ).then((action: PayloadAction<Message | unknown>) => {
            const message = action.payload as Message;
            messageId = message.id;

            if (window.location.pathname === '/chat') {
              dispatch(getConversations());
            }

            dispatch(getMessages(conversationId)).then(
              (action: PayloadAction<Message[] | unknown>) => {
                dispatch(setCurrentMessagesAsync(action.payload as Message[])).then(() => {
                  dispatch(setIsLoading(false));
                  navigate(`/chat/${conversationId}`);
                });
              }
            );
          });
        }

        analytics.event(AnalyticsEvent.WM_NIGEL_MESSAGE, {
          id: messageId,
        });
      };
      if (prompt && !prompt.userEditRequired) {
        actuallySubmitMessage();
      } else {
        const newMessages = [
          ...currentMessagesRef.current,
          {
            conversationId,
            message,
            from: MessageFrom.USER,
            createdAt: '',
            id: '',
            deleted: false,
            tokens: 0,
            files,
          },
        ];
        currentMessagesRef.current = newMessages;
        dispatch(setCurrentMessagesAsync(newMessages)).then(actuallySubmitMessage);
      }
    },
    [
      dispatch,
      features.streaming,
      selectedApp,
      currentUser?.id,
      llm,
      handleStreamingResponse,
      gleanApplicationId,
      navigate,
      analytics,
    ]
  );

  const createNewConversation = useCallback(
    (
      message: string,
      tempUploadedFiles: MessageFile[],
      newConversationTitle: string,
      prompt?: Prompt
    ) => {
      dispatch(
        postConversation({
          title: newConversationTitle,
          promptId: searchParams.get('prompt') ?? undefined,
          tools: [...tools, ...(prompt?.tools ?? [])],
          appName: prompt ? prompt.appName : selectedApp,
        })
      ).then((action: PayloadAction<Conversation | unknown>) => {
        const newConversation = action.payload as Conversation;

        submitMessage(message, tempUploadedFiles, newConversation.id, prompt);
      });
    },
    [dispatch, searchParams, tools, selectedApp, submitMessage]
  );

  const handleSubmit = useCallback(
    (prompt?: Prompt, input?: string) => {
      const editorContentText = editor?.getText().trim().replace(/\s+/g, ' ');
      if (input && conversationId) {
        dispatch(setIsLoading(true));
        submitMessage(input, uploadedFiles, conversationId, prompt);
      } else if (inputRef.current || editorContentText) {
        const ref = inputRef.current;
        const text = ref?.value || editorContentText;

        if (text === '' && uploadedFiles.length === 0) {
          setShowErrorMessage('Please enter your message!');
          setTimeout(() => {
            setShowErrorMessage('');
          }, 5000);
          return;
        }

        dispatch(setIsLoading(true));
        setShowChatInfo(false);
        const message = getChatPrompt();

        const tempUploadedFiles = [...uploadedFiles];
        setUploadedFiles([]);
        if (ref) {
          ref.value = '';
        } else {
          editor?.commands.setContent('');
        }

        if (!conversationId) {
          let newConversationTitle;
          if (prompt?.title) {
            newConversationTitle = prompt.title;
          } else if (tempUploadedFiles.length > 0) {
            const fileName = tempUploadedFiles[0].name;
            if (fileName.length > 20) {
              const extension = fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);
              newConversationTitle = `${fileName.substring(0, 20)}...${extension}`;
            } else {
              newConversationTitle = fileName;
            }
          } else {
            newConversationTitle = message;
          }

          createNewConversation(message, tempUploadedFiles, newConversationTitle, prompt);
        } else {
          submitMessage(message, tempUploadedFiles, conversationId, prompt);
        }
      }
      if (inputRef.current) {
        inputRef.current.focus();
      }
      setShowNormalTextField(true);
    },
    [
      dispatch,
      uploadedFiles,
      conversationId,
      createNewConversation,
      submitMessage,
      editor,
      getChatPrompt,
    ]
  );

  const programmaticScroll = useCallback(() => {
    if (isLoading) {
      if (messageContainerRef.current && !isScrolling) {
        isProgrammaticScroll.current = true;
        messageContainerRef.current.scrollTo({
          top: messageContainerRef.current.scrollHeight,
        });
        setTimeout(() => {
          isProgrammaticScroll.current = false;
        }, 100);
      }
    } else {
      if (!isScrolling) {
        setTimeout(() => {
          if (messageContainerRef.current) {
            isProgrammaticScroll.current = true;
            messageContainerRef.current.scrollTo({
              top: messageContainerRef.current.scrollHeight,
              behavior: 'smooth',
            });
            setTimeout(() => {
              isProgrammaticScroll.current = false;
            }, 100);
          }
        }, 100);
      }
    }
  }, [messageContainerRef, isLoading, isScrolling]);

  const updateTools = useCallback(
    (toolName: ToolName[]) => {
      setTools(toolName);
      if (conversationId) {
        dispatch(updateConversation({ updatedFields: { tools: toolName }, conversationId })).then(
          () => dispatch(getConversations())
        );
      }
    },
    [conversationId, dispatch]
  );

  const handleFeedbackSubmit = (feedback: FeedbackInterface, messageId: string) => {
    dispatch(updateFeedback({ messageId, messageFeedback: feedback }));
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  //triggers when the prompt id changes in URL
  useEffect(() => {
    const newPromptId = searchParams.get('prompt');
    if (newPromptId) {
      dispatch(setIsLoading(true));
      setPromptId(newPromptId);
    } else {
      setPromptId(undefined);
      setPrompt(undefined);
    }
  }, [searchParams, dispatch]);

  //fetches the prompt had been found in URL
  useEffect(() => {
    if (promptId && !prompt) {
      const fetchPrompt = () => {
        dispatch(findPrompt(promptId)).then((action: PayloadAction<Prompt | unknown>) => {
          const newPrompt = action.payload as Prompt;
          setPrompt(newPrompt);
          setPromptId(undefined);
          setChatPrompt(newPrompt.prompt);
          setTools(newPrompt.tools ?? []);
          dispatch(setIsLoading(false));

          if (!newPrompt.userEditRequired) {
            handleSubmit(newPrompt);
          } else {
            dispatch(setIsLoading(false));
            editor?.commands.setContent(highlightWords(newPrompt.prompt, newPrompt.variables));
            setShowNormalTextField(false);
          }
        });
      };
      fetchPrompt();
    }
  }, [handleSubmit, navigate, prompt, promptId, dispatch, editor]);

  //triggers when a different conversation is selected
  useEffect(() => {
    if (currentConversation?.id !== conversationId) {
      if (conversationId) {
        dispatch(setIsLoading(true));
        dispatch(getConversation(conversationId))
          .then((action: PayloadAction<Conversation | unknown>) => {
            const newConversation = action.payload as Conversation;

            dispatch(setCurrentConversation(newConversation));

            let newTools = newConversation.tools ?? [];
            setTools(newTools);
            newTools = Array.from(new Set(newTools.concat(newConversation?.prompt?.tools ?? [])));

            setUploadedFiles([]);
            dispatch(setCurrentApp(newConversation.appName));
            if (inputRef.current) {
              programmaticScroll();
            }

            dispatch(getMessages(conversationId)).then(
              (action: PayloadAction<Message[] | unknown>) => {
                const newMessages = (
                  Array.isArray(action.payload) ? action.payload : []
                ) as Message[];
                if (newConversation.prompt && newConversation.prompt.userEditRequired === false) {
                  if (newMessages.length > 0) {
                    newMessages.shift();
                  }
                }

                if (newMessages.length >= 30 && snackbarOpen) {
                  snackbar30Messages.current = enqueueSnackbar(
                    'Warning: This conversation has more than 30 messages. Please consider starting a new conversation.',
                    {
                      variant: 'warning',
                      autoHideDuration: null,
                      preventDuplicate: true,
                      onClose: () => setSnackbarOpen(false),
                    }
                  );
                }

                dispatch(setCurrentMessagesAsync(newMessages)).then(() => {
                  setSnackbarOpen(true);
                  const mostRecentAIMessages = newMessages.filter(
                    (message) => message.from === MessageFrom.AI
                  );
                  dispatch(
                    setCurrentNumTokens(
                      mostRecentAIMessages.length > 0
                        ? mostRecentAIMessages[mostRecentAIMessages.length - 1].tokens
                        : 0
                    )
                  );
                  dispatch(setIsLoading(false));
                });
              }
            );
          })
          .catch((e) => {
            console.log(e);
            enqueueSnackbar('Error fetching conversation', { variant: 'error' });
            dispatch(setCurrentConversation(undefined));
          });
      } else {
        dispatch(setCurrentConversation(undefined));
        setTools([]);
        setUploadedFiles([]);
      }
    }
  }, [
    conversationId,
    currentConversation,
    enqueueSnackbar,
    snackbarOpen,
    dispatch,
    navigate,
    isLoading,
    programmaticScroll,
  ]);

  // clear the current conversation when there isn't a conversationId
  useEffect(() => {
    if (currentConversation?.id && !conversationId) {
      dispatch(setCurrentMessagesAsync([]));
      setChatPrompt('');
      setPrompt(undefined);
      dispatch(setCurrentNumTokens(0));
      dispatch(setCurrentConversation(undefined));
    }
  }, [conversationId, currentConversation, dispatch]);

  useEffect(() => {
    const handleUserScroll = () => {
      if (!isProgrammaticScroll.current && !isScrolling) {
        dispatch(setIsScrolling(true));
      }
    };

    const container = messageContainerRef.current;

    if (container) {
      container.addEventListener('scroll', handleUserScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleUserScroll);
      }
    };
  }, [dispatch, messageContainerRef, isScrolling]);

  // scroll to the bottom of the messages when anything updates
  useEffect(() => {
    programmaticScroll();
  }, [dispatch, currentMessages, isLoading, isScrolling, programmaticScroll]);

  // updates the currentMessagesRef when currentMessages updates
  useEffect(() => {
    currentMessagesRef.current = currentMessages;
  }, [currentMessages]);

  // triggers when a different app is selected
  useEffect(() => {
    if (selectedApp === AppEnum.DALLE) {
      setShowChatInfo(true);
      setshowBanners(false);
    } else if (selectedApp === AppEnum.ENERGY_UTILITIES_DEMO) {
      setShowChatInfo(false);
      setshowBanners(false);
    } else {
      const shouldShow = window.location.pathname === '/chat';
      setShowChatInfo(shouldShow);
      setshowBanners(shouldShow);
    }
  }, [navigate, selectedApp]);

  useEffect(() => {
    return () => {
      if (snackbar30Messages.current) {
        closeSnackbar(snackbar30Messages.current);
      }
    };
  }, [snackbar30Messages]);

  useEffect(() => {
    const handleKeyDown = (e: { key: string; shiftKey: boolean; preventDefault: () => void }) => {
      const isTextFieldFocused = inputRef.current === document.activeElement;
      const isEditorFocused = editor?.isFocused;

      if (
        e.key === 'Enter' &&
        !e.shiftKey &&
        filesToUpload.length === 0 &&
        !isLoading &&
        !editor?.isEmpty
      ) {
        if (isTextFieldFocused || isEditorFocused) {
          e.preventDefault();
          handleSubmit(prompt);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [prompt, filesToUpload, isLoading, handleSubmit, editor?.isFocused, editor?.isEmpty]);

  return (
    <PageContainer>
      <NavDrawer
        drawerOpen={historyDrawerOpen}
        setDrawerOpen={setHistoryDrawerOpen}
        drawerWidth={drawerWidth}
        setShowNormalTextField={setShowNormalTextField}
      />
      <MainContainer open={historyDrawerOpen}>
        <NavBar mode={NavBarMode.NIGEL} />
        <div
          style={{ overflow: 'auto', width: '100%', minHeight: 'calc(100% - 64px)' }}
          ref={messageContainerRef}
        >
          <StyledChatContainer maxWidth="md">
            <div
              style={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '20px',
              }}
            >
              <ChatInfo
                currentConversation={currentConversation}
                currentPrompt={prompt}
                shouldShowInfo={showChatInfo}
                showBanners={showBanners}
              />
            </div>
            <ChatContainer>
              {currentMessagesRef.current.length === 0 && !prompt?.id && (
                <ChatBubbleComponent
                  llm={llm}
                  key={'placeholder'}
                  message={{
                    from: MessageFrom.AI,
                    message:
                      'Start a new conversation with me by typing in the box or select an old conversation from the left! 😊',
                  }}
                  handleSubmit={handleSubmit}
                />
              )}
              {currentMessagesRef.current.length === 0 && prompt && prompt.userEditRequired && (
                <>
                  <ChatBubbleComponent
                    llm={llm}
                    key={'placeholder'}
                    message={{
                      from: MessageFrom.AI,
                      message: `Looks like you're using a prompt, make sure to edit it if necessary before sending.`,
                    }}
                    handleSubmit={handleSubmit}
                  />
                </>
              )}
              {currentMessagesRef.current.map((message, index) => (
                <ChatBubbleComponent
                  llm={llm}
                  key={index}
                  message={message}
                  handleSubmit={handleSubmit}
                />
              ))}
              {currentMessagesRef.current.length > 0 &&
                currentMessagesRef.current[currentMessagesRef.current.length - 1].from ===
                  MessageFrom.AI &&
                !isLoading && (
                  <Feedback
                    initialFeedback={
                      currentMessagesRef.current[currentMessagesRef.current.length - 1]
                        .messageFeedback as FeedbackInterface | undefined
                    }
                    context={FeedbackContext.MESSAGE}
                    onFeedbackSubmit={(feedback) =>
                      handleFeedbackSubmit(
                        feedback,
                        currentMessagesRef.current[currentMessagesRef.current.length - 1].id
                      )
                    }
                  />
                )}
              {isLoading &&
                ((currentMessagesRef.current.length > 0 &&
                  currentMessagesRef.current[currentMessagesRef.current.length - 1].from ===
                    MessageFrom.USER) ||
                  currentMessagesRef.current.length === 0) && (
                  <ChatBubbleComponent
                    llm={llm}
                    key={'loading'}
                    message={{ from: MessageFrom.AI }}
                    handleSubmit={handleSubmit}
                  />
                )}
              <ChatRow>
                <div style={{ paddingBottom: '20px', flexGrow: 1 }}>
                  {showNormalTextField ? (
                    <TextField
                      inputRef={(ref) => (inputRef.current = ref)}
                      variant="outlined"
                      label="Please enter a message"
                      InputProps={{
                        inputProps: {
                          style: { fontSize: '15px' },
                        },
                        endAdornment: (
                          <>
                            {selectedApp !== AppEnum.BIA && selectedApp !== AppEnum.DALLE && (
                              <InputAdornment
                                position="start"
                                sx={{ alignSelf: 'end', marginBottom: '12px' }}
                              >
                                <ToolSettings
                                  tools={tools}
                                  disabledOnTools={currentConversation?.prompt?.tools ?? []}
                                  handleToolsUpdate={updateTools}
                                />
                              </InputAdornment>
                            )}
                            <InputAdornment
                              position="end"
                              sx={{ alignSelf: 'end', marginBottom: '12px' }}
                            >
                              {features.file_upload_button && selectedApp !== AppEnum.DALLE && (
                                <Tooltip
                                  PopperProps={{ keepMounted: true }}
                                  id={'chat-upload-document-label'}
                                  title={
                                    <Typography variant="caption">
                                      {' '}
                                      Upload a document. 100MB limit.{' '}
                                    </Typography>
                                  }
                                >
                                  <IconButton
                                    component="label"
                                    style={{ left: '8px' }}
                                    aria-labelledby={'chat-upload-document-label'}
                                  >
                                    <input
                                      type="file"
                                      hidden
                                      multiple
                                      onChange={handleFileUpload}
                                    />
                                    <Attachment />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </InputAdornment>
                          </>
                        ),
                      }}
                      multiline
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      error={!!showErrorMessage}
                      helperText={showErrorMessage}
                      onKeyDown={(e) => {
                        if (
                          e.key === 'Enter' &&
                          !e.shiftKey &&
                          filesToUpload.length === 0 &&
                          !isLoading
                        ) {
                          e.preventDefault();
                          handleSubmit(prompt);
                        }
                      }}
                      autoComplete="off"
                      id="chat-box"
                    />
                  ) : (
                    <TiptapWrapper>
                      <TiptapLabel sx={{ fontFamily: 'Noto Sans' }}>
                        Please enter a message
                      </TiptapLabel>
                      <TiptapStyled editor={editor} />
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ position: 'absolute', right: '10px', bottom: '5px' }}
                      >
                        {selectedApp !== AppEnum.BIA && selectedApp !== AppEnum.DALLE && (
                          <InputAdornment
                            position="start"
                            sx={{ alignSelf: 'end', marginBottom: '20px', pr: 2 }}
                          >
                            <ToolSettings
                              tools={tools}
                              disabledOnTools={currentConversation?.prompt?.tools ?? []}
                              handleToolsUpdate={updateTools}
                            />
                          </InputAdornment>
                        )}
                        {features.file_upload_button && selectedApp !== AppEnum.DALLE && (
                          <Tooltip
                            title={
                              <Typography variant="caption">
                                {' '}
                                Upload a document. 100MB limit.{' '}
                              </Typography>
                            }
                          >
                            <IconButton component="label">
                              <input type="file" multiple hidden onChange={handleFileUpload} />
                              <Attachment />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </TiptapWrapper>
                  )}
                  <div>
                    {filesToUpload.map((fileToUpload: File, index: number) => (
                      <div key={index} style={{ padding: '5px' }}>
                        <FileCard
                          fileToUpload={fileToUpload}
                          handleFileDelete={() => {
                            handleFileDelete(index);
                          }}
                          llm={llm}
                        />
                      </div>
                    ))}
                    {uploadedFiles.map((uploadedFile: MessageFile, index: number) => (
                      <div key={index} style={{ padding: '5px' }}>
                        <FileCard
                          uploadedFile={uploadedFile}
                          handleFileDelete={() => {
                            handleFileDelete(index);
                          }}
                          handleTextChange={(newText) => {
                            handleTextChange(index, newText);
                          }}
                          llm={llm}
                        />
                      </div>
                    ))}
                  </div>
                  {features.advanced_token_tracking_display ? (
                    <>
                      <LimitWarning />
                      {currentConversation?.promptId && currentMessagesRef.current.length > 1 && (
                        <ReusePromptBanner promptId={currentConversation.promptId} />
                      )}
                    </>
                  ) : (
                    <>
                      <OutputWarning />
                      {currentConversation?.promptId && currentMessagesRef.current.length > 1 && (
                        <ReusePromptBanner promptId={currentConversation.promptId} />
                      )}
                    </>
                  )}
                </div>
                <div
                  style={{
                    // Roughly centered on the text field
                    marginTop: '7px',
                  }}
                >
                  <Tooltip
                    id={'send-button-label'}
                    PopperProps={{ keepMounted: true }}
                    title={
                      <Typography variant="caption">{isLoading ? 'Cancel' : 'Send'}</Typography>
                    }
                  >
                    <span>
                      <SendButton
                        aria-labelledby={'send-button-label'}
                        onClick={() => {
                          !isStreaming ? handleSubmit(prompt) : handleCancel();
                        }}
                        sx={{ color: isStreaming ? '#f52c00' : '#070154' }}
                        disabled={filesToUpload.length > 0 || (isLoading && !isStreaming)}
                      >
                        {selectedApp !== AppEnum.BIA &&
                        selectedApp !== AppEnum.DALLE &&
                        isStreaming ? (
                          <Stop />
                        ) : (
                          <Send />
                        )}
                      </SendButton>
                    </span>
                  </Tooltip>
                </div>
              </ChatRow>
            </ChatContainer>
          </StyledChatContainer>
        </div>
      </MainContainer>
    </PageContainer>
  );
};

export default Chat;
