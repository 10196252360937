import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { TextField } from 'mui-rff';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import type {
  ProjectNotes,
  ProjectRunAnalysisQuestion,
  ProjectType,
  StepDependenciesOptions,
} from 'Types/dealGPT';
import type { Result } from 'Types/result';

import { checkDependencies, getNotes, saveNotes } from '../../../services/dealGPT';
import { AnalyticsEvent, useAnalytics } from '../../Providers/AnalyticsProvider';
import LoadingMask from '../LoadingMask';

const NotesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

interface NotesProps {
  project: ProjectType;
  scope: string;
  latestRunId?: string;
  question?: ProjectRunAnalysisQuestion;
  reprocessingTriggered: () => void;
  firstRun: boolean;
  isParentLoading: boolean;
}

const Notes: React.FC<NotesProps> = ({
  project,
  scope,
  question,
  reprocessingTriggered,
  firstRun,
  isParentLoading,
}) => {
  const [projectNotes, setProjectNotes] = useState<ProjectNotes>();
  const [notesQuestion, setNotesQuestion] = useState<ProjectRunAnalysisQuestion | undefined>(
    question
  );
  const [dependencyMap, setDependencyMap] = useState<Map<string, string[]>>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const analytics = useAnalytics();

  useEffect(() => {
    // TODO load existing notes from the database IF they exist
    const fetchNotes = async () => {
      setIsLoading(true);
      const notes = await getNotes(project.id, scope);
      if (notes._data) {
        delete notes._data.previousVersionId;
        setProjectNotes(notes._data);
      } else {
        setProjectNotes({
          projectId: project.id,
          notes: '',
          scope: scope,
        });
      }
      setIsLoading(false);
    };

    fetchNotes();
  }, [project.id, scope]);

  useEffect(() => {
    if (question) {
      setNotesQuestion(question);
    }
  }, [question]);

  const handleCloseDialog = (sendAnalyticEvent = true) => {
    if (sendAnalyticEvent) {
      analytics.event(AnalyticsEvent.WM_IA_A_SITE_NOTES_DIALOG_CLOSE, {
        id: project.id,
        value: scope,
      });
    }
    setOpenDialog(false);
  };

  const onSubmit = async (values: ProjectNotes) => {
    // validation?
    setIsLoading(true);
    handleCloseDialog(false);
    analytics.event(AnalyticsEvent.WM_IA_A_SITE_NOTES_DIALOG_CONTINUE, {
      id: project.id,
      value: scope,
    });
    await saveNotes(values, true, notesQuestion?.runId);
    reprocessingTriggered();
    setIsLoading(false);
  };

  const handleSave = async (values: ProjectNotes) => {
    // Save notes to the database
    analytics.event(AnalyticsEvent.WM_IA_A_SITE_NOTES_SAVE, {
      id: project.id,
      value: scope,
    });
    const notes = await saveNotes(values, false, notesQuestion?.runId);

    console.log(notes);

    enqueueSnackbar('Notes saved successfully', { variant: 'success' });
  };

  const handleOpenDialog = async () => {
    analytics.event(AnalyticsEvent.WM_IA_A_SITE_NOTES_PROCESS_PROJECT, {
      id: project.id,
      value: scope,
    });
    let dependencies: Result<StepDependenciesOptions> | undefined = undefined;

    if (notesQuestion) {
      dependencies = await checkDependencies(notesQuestion);
    }

    if (dependencies?._data) {
      const uniqueDependencyMap: Map<string, Set<string>> = new Map();
      dependencies._data.dependencies.forEach((dependency) => {
        const key = dependency.pageName;
        if (!uniqueDependencyMap.has(key)) {
          uniqueDependencyMap.set(key, new Set());
        }

        const promptTypes = uniqueDependencyMap.get(key);
        if (promptTypes && dependency.promptType) {
          promptTypes.add(dependency.promptType);
        }
      });

      const result = new Map();
      uniqueDependencyMap.forEach((value, key) => {
        result.set(key, Array.from(value));
      });

      setDependencyMap(result);
    }

    setOpenDialog(true);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <Form
        onSubmit={onSubmit}
        initialValues={projectNotes}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <NotesContainer>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Typography variant="h2" sx={{ fontSize: '24px' }}>
                    Notes
                  </Typography>
                </div>
              </div>
              <div style={{ display: 'flex', gap: '16px', width: '100%', justifyContent: 'end' }}>
                <Tooltip
                  title={
                    <div style={{ whiteSpace: 'pre-line' }}>
                      {
                        "Save what you have. Use this to keep working on these notes later.\n\nWhen you're ready, apply the changes to downstream dependencies so the LLM can update its responses with the right information."
                      }
                    </div>
                  }
                >
                  <span>
                    <Button
                      variant="outlined"
                      onClick={() => handleSave(values)}
                      disabled={!notesQuestion}
                    >
                      Save
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip
                  title={
                    <div style={{ whiteSpace: 'pre-line' }}>
                      {!firstRun
                        ? 'Have this how you want it? Save these changes and immediately "apply" them to downstream dependencies. This will regenerate LLM responses on all pages.\n\nDon\'t worry! You can switch between versions if you liked what you had, but give it a try!'
                        : 'Have this how you want it? This will generate LLM responses on all pages.'}
                    </div>
                  }
                >
                  <span>
                    <Button
                      variant="contained"
                      onClick={handleOpenDialog}
                      disabled={!notesQuestion || isLoading}
                    >
                      {firstRun ? 'Process Project' : 'Save and Apply Changes'}
                    </Button>
                  </span>
                </Tooltip>
              </div>
              <Dialog
                open={openDialog}
                onClose={() => {
                  handleCloseDialog();
                }}
              >
                <DialogTitle>Are you sure you want to apply changes?</DialogTitle>
                <IconButton
                  onClick={() => {
                    handleCloseDialog();
                  }}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: '#757575',
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContent style={{ paddingTop: '0px' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="caption" sx={{ color: 'red', paddingBottom: '16px' }}>
                      Please be aware this process should take 5-10 minutes.
                    </Typography>
                    <Typography>
                      This will generate or regenerate the following dependencies:
                    </Typography>
                    <Typography>
                      <ul>
                        {dependencyMap &&
                          Array.from(dependencyMap.entries()).map(([key, value], index) => (
                            <li key={`dependency-${index}`}>
                              <Typography>{`${
                                value.length > 0 ? value.join(', ') + ' on page ' : ''
                              }${key}`}</Typography>
                            </li>
                          ))}
                      </ul>
                    </Typography>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => onSubmit(values)}>Continue</Button>
                </DialogActions>
              </Dialog>
              <div style={{ display: 'flex', gap: '16px', marginTop: '24px' }}>
                <Typography>
                  Please enter any notes relevant to analysis you want Intellio® Advantage to
                  prioritize for answers and content generation
                </Typography>
              </div>
              <TextField name="notes" multiline />
              <LoadingMask isLoading={isLoading && !isParentLoading} />
            </NotesContainer>
          </form>
        )}
      />
    </div>
  );
};

export default Notes;
