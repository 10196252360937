import { Container, styled, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AssistantCard from '../../components/AIAccountCoach/AssistantCard';
import { getAssistants } from '../../redux/actions/assistantsActions';
import { selectAssistants, setPage } from '../../redux/reducers/assistantsReducer';
import { AppDispatch } from '../../redux/store';
import { StyledPagination } from '../../theme/CustomComponents';

const MainDiv = styled('div')({
  display: 'flex',
  height: '100vh',
  overflowY: 'auto',
  div: {
    position: 'relative',
  },
});

const AssistantsContainer = styled(Container)({
  width: `100%`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  zIndex: 1,
  alignItems: 'flex-start',
  height: 'inherit',
  background: 'white',
  marginBottom: '100px',
});

const CardContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
  gap: '30px',
  flexWrap: 'wrap',
  paddingBottom: '20px',
});

const AssistantsLibrary = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { assistants, page, totalPages } = useSelector(selectAssistants);

  const handlePageChange = (_e: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setPage(value));
  };

  useEffect(() => {
    dispatch(getAssistants());
  }, [page, dispatch]);

  const paginator = (
    <div style={{ width: '100%' }}>
      <StyledPagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        size={'small'}
        sx={{ justifyContent: 'center', display: 'flex' }}
      />
    </div>
  );

  return (
    <MainDiv>
      <div
        style={{
          overflow: 'auto',
          width: '100%',
          minHeight: 'calc(100% - 64px)',
          background: 'white',
        }}
      >
        <AssistantsContainer>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '50px',
              width: '100%',
              textAlign: 'center',
            }}
          >
            ExpertGPTs
          </Typography>
          <Typography
            variant="h6"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '30px',
              marginTop: '30px',
              width: '100%',
              textAlign: 'center',
            }}
          >
            These assistants leverage the powerful capabilities of GPT architectures with human
            expertise resulting in more relevant and contextualized Al outputs.
          </Typography>

          {paginator}

          <CardContainer>
            {assistants.map((assistant, index) => (
              <AssistantCard key={index} assistant={assistant} />
            ))}
          </CardContainer>
          {paginator}
        </AssistantsContainer>
      </div>
    </MainDiv>
  );
};

export default AssistantsLibrary;
