import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Select,
  styled,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import { TextField } from 'mui-rff';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import supersub from 'remark-supersub';

import { FeedbackContext } from '../../../../constants/feedbackConstants';
import { processQuestion, userEditSave } from '../../../../services/dealGPT';
import { DynamicText, StatusResponseCircle } from '../../../../theme/CustomComponents';
import type { ProjectRunAnalysisQuestion } from '../../../../Types/dealGPT';
import { ProcessReason, UpdatedReason } from '../../../../Types/enums';
import { FeedbackInterface } from '../../../../Types/feedback';
import formatDate from '../../../../util/dateUtils';
import Feedback from '../../../Feedback';
import { AnalyticsEvent, useAnalytics } from '../../../Providers/AnalyticsProvider';
import { useFeatures } from '../../../Providers/FeatureProvider';
import QuestionStatus from '../../QuestionStatus';
import MoreOptionsMenu from '../MoreOptionsMenu';
import ReadMoreText from '../ReadMoreText';
import ReferenceDocuments from '../ReferenceDocuments';
import SectionButtons from '../SectionButtons';
import UnprocessedChangesDot from '../UnprocessedChangesDot';

const KPIAccordionSummary = styled(AccordionSummary)({
  flexDirection: 'row-reverse',
  paddingLeft: 0,
  paddingRight: 0,

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },

  '& .MuiAccordionSummary-content': {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: '8px',
  },
});

const KPIAccordionDetails = styled(AccordionDetails)({
  paddingRight: 0,
  display: 'flex',
  flexDirection: 'row',
});

const ReactMarkdownWrapper = styled('div')({
  fontSize: '14px',
  lineHeight: '1.5rem',
  letterSpacing: '0.25px',
  '& > *': {
    fontFamily: 'Noto Sans',
  },

  'a, a:visited, a:hover, a:focus': {
    color: 'inherit',
  },
  whiteSpace: 'normal',
  wordWrap: 'break-word',
  overflowWrap: 'anywhere',
});

const StyledSelect = styled(Select)({
  '& .MuiSelect-select': {
    padding: '10px',
    color: '#092B49',
  },
  width: '100%',
});

interface KPIProps {
  versions: ProjectRunAnalysisQuestion[];
  textfieldName: string;
  setIsLoadingThis: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoadingApplyChanges: React.Dispatch<React.SetStateAction<boolean>>;
  reprocessingTriggered: () => void;
  expandAll: boolean;
  handleFeedbackSubmit: (feedback: FeedbackInterface, projectAnalysisQuestionId: string) => void;
  handleValidateFindingsClick: (questionId: string) => void;
}

const KPI: React.FC<KPIProps> = ({
  versions,
  textfieldName,
  setIsLoadingThis,
  setIsLoadingApplyChanges,
  reprocessingTriggered,
  expandAll,
  handleFeedbackSubmit,
  handleValidateFindingsClick,
}) => {
  const [analysisQuestionData, setAnalysisQuestionData] = useState<ProjectRunAnalysisQuestion>(
    versions[0]
  );
  const [questionVersions, setQuestionVersions] = useState<ProjectRunAnalysisQuestion[]>(versions);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [expandAccordion, setExpandAccordion] = useState<boolean>(expandAll);
  const analytics = useAnalytics();

  const questionStatuses: string[] = [];

  if (analysisQuestionData.validationResponse) {
    questionStatuses.push('VALIDATED');
  }

  // This is used when the user clicks the save button
  const handleSave = async (
    values: Record<string, string>,
    reason?: UpdatedReason,
    processReason?: ProcessReason
  ) => {
    const tempAnalysisQuestion: ProjectRunAnalysisQuestion = { ...analysisQuestionData };
    tempAnalysisQuestion.response = values[textfieldName];
    tempAnalysisQuestion.value = values['value'];

    if (reason) {
      tempAnalysisQuestion.updatedReason = reason;
    }

    let updatedQuestion = tempAnalysisQuestion;
    if (reason === UpdatedReason.USER_EDIT) {
      const updatedResult = await userEditSave(tempAnalysisQuestion);

      if (updatedResult._data) {
        updatedQuestion = updatedResult._data;
        setAnalysisQuestionData(updatedResult._data);
        enqueueSnackbar('Content saved successfully', { variant: 'success' });
        reprocessingTriggered();
      }
    }

    if (
      tempAnalysisQuestion.updatedReason === UpdatedReason.REPROCESS ||
      processReason === ProcessReason.APPLY_CHANGES
    ) {
      enqueueSnackbar('Your content is being processed', { variant: 'success' });
      setIsLoadingThis(true);
      if (processReason === ProcessReason.APPLY_CHANGES) {
        setIsLoadingApplyChanges(true);
      }
      await processQuestion(updatedQuestion, processReason);
      reprocessingTriggered();
    }
  };

  const onSubmit = async () => {
    // setIsLoading(true);
    // enqueueSnackbar('Your content is being processed', { variant: 'success' });
    // const tempAnalysisQuestion = await handleSave(
    //   analysisQuestionData as unknown as Record<string, string>,
    //   UpdatedReason.REPROCESS
    // );
    // if (tempAnalysisQuestion) {
    //   await processQuestion(tempAnalysisQuestion);
    //   reprocessingTriggered();
    // }
  };

  const handleVersionChange = useCallback(
    (value: string) => {
      const version = versions.find((v) => v.id === value);
      analytics.event(AnalyticsEvent.WM_IA_A_KPI_QUESTION_VERSION_CHANGE, {
        id: value,
        value: versions[0].pageName,
      });
      if (version) {
        setAnalysisQuestionData(version);
      }
    },
    [versions, analytics]
  );

  const toggleAccordion = useCallback(
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      analytics.event(AnalyticsEvent.WM_IA_A_KPI_QUESTION_ACCORDION, {
        id: analysisQuestionData.id,
        value: isExpanded.toString(),
      });
      setExpandAccordion(isExpanded);
    },
    [analysisQuestionData, analytics]
  );

  useEffect(() => {
    setExpandAccordion(expandAll);
  }, [expandAll]);

  useEffect(() => {
    setQuestionVersions(versions);
    handleVersionChange(versions[0].id);
  }, [handleVersionChange, versions]);

  const features = useFeatures();
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        [textfieldName]: analysisQuestionData.response,
        ['value']: analysisQuestionData.value,
      }}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Accordion
            square
            disableGutters
            sx={{ boxShadow: 'none' }}
            expanded={expandAccordion}
            onChange={toggleAccordion}
          >
            <KPIAccordionSummary>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: '35px',
                  width: '3%',
                  alignItems: 'flex-start',
                }}
              >
                <StatusResponseCircle value={analysisQuestionData?.value ? '#FFC700' : '#f52c00'} />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '40%',
                  paddingRight: '30px',
                }}
              >
                <Typography sx={{ fontWeight: '550', color: '#092B49' }}>
                  {analysisQuestionData.prettyQuestion}
                </Typography>
                <Typography sx={{ color: 'inherit', fontSize: '13px' }}>
                  {analysisQuestionData.area} - {analysisQuestionData.dimension}
                </Typography>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', width: '52%' }}>
                {!expandAccordion ? (
                  <>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
                      <Typography
                        component="p"
                        sx={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          WebkitLineClamp: 2,
                          fontSize: '14px',
                          fontWeight: '400',
                          whiteSpace: 'normal',
                          wordWrap: 'break-word',
                          overflowWrap: 'anywhere',
                        }}
                      >
                        <DynamicText
                          fontWeight={'700'}
                          color={analysisQuestionData?.value ? '#FFC700' : '#f52c00'}
                        >
                          {analysisQuestionData?.value ? 'Partial' : 'Missing'}
                        </DynamicText>{' '}
                        -{' '}
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm, supersub]}
                          components={{
                            p: ({ ...props }) => <span {...props} />,
                          }}
                        >
                          {analysisQuestionData.response || ''}
                        </ReactMarkdown>
                      </Typography>
                    </div>
                    <div style={{ margin: 'auto' }}>
                      <QuestionStatus statuses={questionStatuses} />
                    </div>
                  </>
                ) : (
                  // Expanded view
                  <>
                    <div style={{ marginLeft: '-10px' }}>
                      <QuestionStatus statuses={questionStatuses} />
                    </div>
                    <div
                      onClick={(event) => event.stopPropagation()}
                      style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                        gap: '16px',
                        paddingRight: '10px',
                      }}
                    >
                      <SectionButtons
                        handleSave={handleSave}
                        values={values}
                        hasGenerated={true}
                        handleEdit={setIsEditing}
                        isEditing={isEditing}
                        question={analysisQuestionData}
                        showGenerateButton={false}
                      />
                    </div>
                  </>
                )}
              </div>
              <MoreOptionsMenu
                analysisQuestionData={analysisQuestionData}
                handleSave={handleSave}
                values={values}
                isEditing={isEditing}
                handleValidateFindingsClick={handleValidateFindingsClick}
              />
            </KPIAccordionSummary>
            <KPIAccordionDetails>
              <div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
                <div
                  style={{ display: 'flex', gap: '10px', alignItems: 'center', paddingTop: '1em' }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: !analysisQuestionData.value ? '#f52c00' : '#FFC700',
                      fontSize: '13px',
                      fontWeight: '500',
                    }}
                  >
                    {!analysisQuestionData.value
                      ? 'Missing. No Answer Found'
                      : 'Answer Found in Content, Must Validate'}
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    paddingTop: '1em',
                    width: '90%',
                  }}
                >
                  <StyledSelect
                    value={analysisQuestionData.id}
                    onChange={(event) => handleVersionChange(event.target.value as string)}
                  >
                    {questionVersions.map((version) => (
                      <MenuItem
                        key={version.id}
                        value={version.id}
                        selected={version.id === analysisQuestionData.id}
                      >
                        Generated on {version.createdAt && formatDate(version.createdAt)} |{' '}
                        {version.updatedReason}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                  {questionVersions.length > 0 &&
                    questionVersions[0].unprocessedDownstreamChanges && <UnprocessedChangesDot />}
                </div>
                <div style={{ marginTop: '1.3em', width: '90%' }}>
                  <ReferenceDocuments
                    questionId={analysisQuestionData.id}
                    documents={analysisQuestionData.documents}
                    showAnalysisText={true}
                  />
                  {analysisQuestionData.validationSources &&
                    analysisQuestionData.validationSources.length > 0 && (
                      <div style={{ marginTop: '1.3em' }}>
                        <ReferenceDocuments
                          questionId={analysisQuestionData.id}
                          documents={analysisQuestionData.validationSources}
                          showReferenceTitle={false}
                          showAnalysisText={true}
                          showValidationTitle={true}
                        />
                      </div>
                    )}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '55%',
                  paddingRight: '10px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'flex-start',
                    marginBottom: !isEditing ? '0' : '15px',
                    marginRight: !isEditing ? '10px' : '0',
                  }}
                >
                  {!isEditing ? (
                    <Typography sx={!analysisQuestionData.value ? { color: 'grey' } : {}}>
                      {analysisQuestionData.value ? analysisQuestionData.value : 'N/A'}
                    </Typography>
                  ) : analysisQuestionData.lookupList ? (
                    <StyledSelect
                      value={analysisQuestionData.value}
                      onChange={(event) => {
                        setAnalysisQuestionData({
                          ...analysisQuestionData,
                          value: event.target.value as string,
                        });
                      }}
                      onClick={(event) => event.stopPropagation()}
                      sx={{ minWidth: '160px' }}
                    >
                      {(JSON.parse(analysisQuestionData.lookupList ?? '') as string[]).map(
                        (value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        )
                      )}
                    </StyledSelect>
                  ) : (
                    <MuiTextField
                      InputProps={{
                        inputProps: {
                          style: { textAlign: 'right' },
                        },
                      }}
                      size="small"
                      value={analysisQuestionData.value}
                      onChange={(event) => {
                        setAnalysisQuestionData({
                          ...analysisQuestionData,
                          value: event.target.value as string,
                        });
                      }}
                      onClick={(event) => event.stopPropagation()}
                      sx={{ maxWidth: '10rem', minWidth: '160px' }}
                    />
                  )}
                </div>
                {isEditing ? (
                  <TextField name={textfieldName} multiline />
                ) : (
                  <ReactMarkdownWrapper>
                    <ReactMarkdown remarkPlugins={[remarkGfm, supersub]}>
                      {analysisQuestionData.response || ''}
                    </ReactMarkdown>
                    {analysisQuestionData?.validationResponse ? (
                      <>
                        <hr />
                        <Typography>Validation</Typography>
                        <ReactMarkdown remarkPlugins={[remarkGfm, supersub]}>
                          {analysisQuestionData.validationResponse || ''}
                        </ReactMarkdown>
                      </>
                    ) : (
                      <></>
                    )}
                  </ReactMarkdownWrapper>
                )}
                {features.deal_gpt_enriched_prompt_display && (
                  <>
                    <Typography sx={{ fontWeight: '700', color: '#092B49', marginTop: '10px' }}>
                      Enriched Prompt:
                    </Typography>
                    <div style={{ marginBottom: '15px' }}>
                      <ReadMoreText
                        text={analysisQuestionData.enrichedPrompt || ''}
                        maxLength={55}
                      />
                    </div>
                  </>
                )}
                <Feedback
                  initialFeedback={
                    analysisQuestionData.analysisQuestionFeedback as FeedbackInterface | undefined
                  }
                  context={FeedbackContext.ANALYSIS_QUESTION}
                  onFeedbackSubmit={(feedback) =>
                    handleFeedbackSubmit(feedback, analysisQuestionData.id)
                  }
                />
              </div>
            </KPIAccordionDetails>
          </Accordion>
        </form>
      )}
    />
  );
};

export default KPI;
