import React, { createContext, useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import type { ArgumentType } from '../../Types/gtag.d';
import { useAuth } from './AuthProvider';

declare global {
  interface Window {
    gtag: (...args: ArgumentType[]) => void;
  }
}

// Note: you MUST prefix any AnalyticsEvents with WM_ so they show up in
// reports and maximum length for new event names is 40 characters.
export enum AnalyticsEvent {
  //NIGEL
  WM_NIGEL_MESSAGE = 'WM_NIGEL_MESSAGE',
  WM_NIGEL_FILE_UPLOAD = 'WM_NIGEL_FILE_UPLOAD',
  WM_NIGEL_FILE_UPLOAD_EXCEED_SIZE_LIMIT = 'WM_NIGEL_FILE_UPLOAD_EXCEED_SIZE_LIMIT',
  WM_NIGEL_CHAT_DOWNLOAD = 'WM_NIGEL_CHAT_DOWNLOAD',
  WM_NIGEL_REGENERATE_RESPONSE = 'WM_NIGEL_REGENERATE_RESPONSE',
  WM_NIGEL_CHAT_START_FROM_HERE = 'WM_NIGEL_START_FROM_HERE',
  WM_NIGEL_LLM_CHANGE = 'WM_NIGEL_LLM_CHANGE',
  WM_NIGEL_FEEDBACK_BUTTON_CLICK = 'WM_NIGEL_FEEDBACK_BUTTON_CLICK',
  WM_NIGEL_PAGE_LOAD = 'WM_NIGEL_PAGE_LOAD',
  WM_NIGEL_LOAD_MORE_CONVERSATIONS = 'WM_NIGEL_LOAD_MORE_CONVERSATIONS',
  WM_NIGEL_OPEN_CLOSE_LEFT_PANEL = 'WM_NIGEL_OPEN_CLOSE_LEFT_PANEL',
  WM_NIGEL_CAROUSEL_NAVIGATION_NEXT = 'WM_NIGEL_CAROUSEL_NAVIGATION_NEXT',
  WM_NIGEL_CAROUSEL_NAVIGATION_PREV = 'WM_NIGEL_CAROUSEL_NAVIGATION_PREV',
  WM_NIGEL_NEW_CHAT = 'WM_NIGEL_NEW_CHAT',
  WM_NIGEL_NEW_PRIVATE_CHAT = 'WM_NIGEL_NEW_PRIVATE_CHAT',
  WM_NIGEL_CONVERSATION_CLICK = 'WM_NIGEL_NEW_PRIVATE_CHAT',
  WM_NIGEL_CONVERSATION_RENAME = 'WM_NIGEL_CONVERSATION_RENAME',
  WM_NIGEL_CONVERSATION_RENAME_CONFIRM = 'WM_NIGEL_CONVERSATION_RENAME_CONFIRM',
  WM_NIGEL_CONVERSATION_SHARED_LINK = 'WM_NIGEL_CONVERSATION_SHARED_LINK',
  WM_NIGEL_CONVERSATION_SHARED_LINK_CHECK = 'WM_NIGEL_CONVERSATION_SHARED_LINK_CHECK',
  WM_NIGEL_CONVERSATION_SHARED_LINK_CLOSE = 'WM_NIGEL_CONVERSATION_SHARED_LINK_CLOSE',
  WM_NIGEL_CONVERSATION_DOWNLOAD = 'WM_NIGEL_CONVERSATION_DOWNLOAD',
  WM_NIGEL_CONVERSATION_REUSE_PROMPT = 'WM_NIGEL_CONVERSATION_REUSE_PROMPT',
  WM_NIGEL_CONVERSATION_DELETE = 'WM_NIGEL_CONVERSATION_DELETE',
  WM_NIGEL_CONVERSATION_DELETE_CONFIRM = 'WM_NIGEL_CONVERSATION_DELETE_CONFIRM',
  WM_NIGEL_NAVBAR_ROUTE_LINKS = 'WM_NIGEL_NAVBAR_ROUTE_LINKS',
  WM_NIGEL_NAVBAR_PROFILE_SETTINGS = 'WM_NIGEL_NAVBAR_PROFILE_SETTINGS',
  WM_NIGEL_NAVBAR_ADMIN = 'WM_NIGEL_NAVBAR_ADMIN',
  WM_NIGEL_NAVBAR_RELEASE_NOTES = 'WM_NIGEL_NAVBAR_RELEASE_NOTES',
  WM_NIGEL_NAVBAR_LOGOUT = 'WM_NIGEL_NAVBAR_LOGOUT',
  WM_NIGEL_HOME_TRENDING_PROMPTS = 'WM_NIGEL_HOME_TRENDING_PROMPTS',
  // Intellio Advantage
  WM_IA_TAB_VIEW = 'WM_IA_TAB_VIEW',
  // Home
  WM_IA_HOME_TEXT_GLEAN_INFO = 'WM_IA_HOME_TEXT_GLEAN_INFO',
  WM_IA_HOME_TEXT_FAQ = 'WM_IA_HOME_TEXT_FAQ',
  WM_IA_HOME_TEXT_USER_GUIDE = 'WM_IA_HOME_TEXT_USER_GUIDE',
  WM_IA_HOME_SEARCH = 'WM_IA_HOME_SEARCH',
  WM_IA_HOME_CREATE_PROJECT = 'WM_IA_HOME_CREATE_PROJECT',
  WM_IA_HOME_PROJECT_CLICK = 'WM_IA_HOME_PROJECT_CLICK',
  WM_IA_HOME_ARCHIVE = 'WM_IA_HOME_ARCHIVE',
  WM_IA_HOME_SHOW_ARCHIVE = 'WM_IA_HOME_SHOW_ARCHIVE',
  WM_IA_HOME_SHOW_ALL_PROJECTS = 'WM_IA_HOME_SHOW_ALL_PROJECTS',
  // Discovery
  WM_IA_D_PAGE_LOAD_TIME = 'WM_IA_D_PAGE_LOAD_TIME',
  WM_IA_D_CATEGORY_ACCORDION = 'WM_IA_D_CATEGORY_ACCORDION',
  WM_IA_D_QUESTION_CLICK = 'WM_IA_D_QUESTION_CLICK',
  WM_IA_D_VALIDATE = 'WM_IA_D_VALIDATE',
  WM_IA_D_SOURCE_CLICK = 'WM_IA_D_SOURCE_CLICK',
  WM_IA_D_SHOW_ALL_SOURCES = 'WM_IA_D_SHOW_ALL_SOURCES',
  WM_IA_D_EXPAND_ALL_CATEGORIES = 'WM_IA_D_EXPAND_ALL_CATEGORIES',
  WM_IA_D_QUESTION_FILTER = 'WM_IA_D_QUESTION_FILTER',
  WM_IA_D_RUN_PROJECT = 'WM_IA_D_RUN_PROJECT',
  WM_IA_D_SEARCH_ENTERED = 'WM_IA_D_SEARCH_ENTERED',
  WM_IA_D_RESCAN_QUESTIONS = 'WM_IA_D_RESCAN_QUESTIONS',
  WM_IA_D_TAB_VIEW = 'WM_IA_D_TAB_VIEW',
  WM_IA_D_GENERATE_REPORT = 'WM_IA_D_GENERATE_REPORT',
  WM_IA_D_OPEN_RUN_DIALOG = 'WM_IA_D_OPEN_RUN_DIALOG',
  WM_IA_D_RUN_SELECTED = 'WM_IA_D_RUN_SELECTED',
  WM_IA_D_QUESTION_FEEDBACK = 'WM_IA_D_QUESTION_FEEDBACK',
  WM_IA_D_QUESTION_EXPAND = 'WM_IA_D_QUESTION_EXPAND',
  // Analysis Events
  WM_IA_A_TAB_STEP = 'WM_IA_A_TAB_STEP',
  WM_IA_A_VALIDATE = 'WM_IA_A_VALIDATE',
  WM_IA_A_PREVIOUS_CLICK = 'WM_IA_A_PREVIOUS_CLICK',
  WM_IA_A_NEXT_CLICK = 'WM_IA_A_NEXT_CLICK',
  WM_IA_A_FINISH_CLICK = 'WM_IA_A_FINISH_CLICK',
  WM_IA_A_FEEDBACK_CLICK = 'WM_IA_A_FEEDBACK_CLICK',
  WM_IA_A_QUESTION_EDIT = 'WM_IA_A_QUESTION_EDIT',
  WM_IA_A_QUESTION_SAVE = 'WM_IA_A_QUESTION_SAVE',
  WM_IA_A_APPLY_CHANGES = 'WM_IA_A_APPLY_CHANGES',
  WM_IA_A_APPLY_CHANGES_CONFIRM = 'WM_IA_A_APPLY_CHANGES_CONFIRM',
  WM_IA_A_APPLY_CHANGES_DIALOG_CLOSE = 'WM_IA_A_APPLY_CHANGES_DIALOG_CLOSE',
  WM_IA_A_QUESTION_GENERATE = 'WM_IA_A_QUESTION_GENERATE',
  WM_IA_A_GENERATE_PAGE = 'WM_IA_A_GENERATE_PAGE',
  WM_IA_A_MODIFY_TONE = 'WM_IA_A_MODIFY_TONE',
  WM_IA_A_SOURCE_CLICK = 'WM_IA_A_SOURCE_CLICK',
  WM_IA_A_SHOW_ALL_SOURCES = 'WM_IA_A_SHOW_ALL_SOURCES',
  // Scope Area
  WM_IA_A_SCOPE_AREA_NEW_RUN = 'WM_IA_A_SCOPE_AREA_NEW_RUN',
  WM_IA_A_SCOPE_AREA_VIEW = 'WM_IA_A_SCOPE_AREA_VIEW',
  // Site Visit Notes
  WM_IA_A_SITE_NOTES_SAVE = 'WM_IA_A_SITE_NOTES_SAVE',
  WM_IA_A_SITE_NOTES_PROCESS_PROJECT = 'WM_IA_A_SITE_NOTES_PROCESS_PROJECT',
  WM_IA_A_SITE_NOTES_DIALOG_CONTINUE = 'WM_IA_A_SITE_NOTES_DIALOG_CONTINUE',
  WM_IA_A_SITE_NOTES_DIALOG_CLOSE = 'WM_IA_A_SITE_NOTES_DIALOG_CLOSE',
  // KPI
  WM_IA_A_KPI_QUESTION_ACCORDION = 'WM_IA_A_KPI_QUESTION_ACCORDION',
  WM_IA_A_KPI_QUESTION_VERSION_CHANGE = 'WM_IA_A_KPI_QUESTION_VERSION_CHANGE',
  WM_IA_A_KPI_EXPAND_ALL = 'WM_IA_A_KPI_EXPAND_ALL',
  // RUBRIC
  WM_IA_A_RUBRIC_QUESTION_ACCORDION = 'WM_IA_A_RUBRIC_QUESTION_ACCORDION',
  WM_IA_A_RUBRIC_QUESTION_VERSION_CHANGE = 'WM_IA_A_RUBRIC_QUESTION_VERSION_CHANGE',
  WM_IA_A_RUBRIC_EXPAND_ALL = 'WM_IA_A_RUBRIC_EXPAND_ALL',
  // RUBRIC SUMMARY
  // FLASH_REPORT
  WM_IA_A_FR_ADD_QUESTION = 'WM_IA_A_FR_ADD_QUESTION',
  WM_IA_A_FR_DELETE_QUESTION = 'WM_IA_A_FR_DELETE_QUESTION',
  // Question ANSWER
  WM_IA_A_QA_QUESTION_VERSION_CHANGE = 'WM_IA_A_QA_QUESTION_VERSION_CHANGE',
  // PROMPT ANSWER
  WM_IA_A_PA_QUESTION_VERSION_CHANGE = 'WM_IA_A_PA_QUESTION_VERSION_CHANGE',
  //CHAT
  WM_IA_CHAT_NEW = 'WM_IA_CHAT_NEW',
  WM_IA_CHAT_SEND_MESSAGE = 'WM_IA_CHAT_SEND_MESSAGE',
  WM_IA_CHAT_SITE_NOTES_FILTER = 'WM_IA_CHAT_SITE_NOTES_FILTER',
  WM_IA_CHAT_SITE_NOTES_EDIT = 'WM_IA_CHAT_SITE_NOTES_EDIT',
  WM_IA_CHAT_SITE_NOTES_EDIT_SAVE = 'WM_IA_CHAT_SITE_NOTES_EDIT_SAVE',
  WM_IA_CHAT_REGENERATE_RESPONSE = 'WM_IA_CHAT_REGENERATE_RESPONSE',
  WM_IA_CHAT_START_FROM_HERE = 'WM_IA_CHAT_START_FROM_HERE',
}

// HEY!! Are you adding a new property here? Make sure to add it as a Google Analytics Custom Dimension and
// to the report pulling python script in databricks
type AnalyticsEventData = {
  // An ID associated with the event to trace back to our database, if applicable
  id?: string;
  // The value, if applicable
  value?: string;
  // The type related to the event, if applicable
  type?: string;
  // The size related to the event, if applicable
  size?: string;
};

type SessionOptions = {
  appUserId: string;
  appSessionId: string;
};

type SessionStorage = {
  value: SessionOptions;
};

interface AnalyticsContextType {
  event: (eventType: AnalyticsEvent, data?: AnalyticsEventData) => void;
}

interface Props {
  children: React.ReactNode;
}

function googleAnalyticsEvent(
  event: AnalyticsEvent,
  data?: AnalyticsEventData,
  session?: SessionOptions
): void {
  window.gtag('event', event.toString(), {
    ...data,
    ...session,
    timestamp: new Date().toISOString(),
  });
}

export const AnalyticsHelper = (): AnalyticsContextType => {
  function getStorage(): SessionStorage | null {
    const value: string | null = sessionStorage.getItem('_session');
    const result: SessionStorage | null = value ? (JSON.parse(value) as SessionStorage) : null;

    return result;
  }

  function setStorage(userId: string, sessionId: string): SessionStorage {
    const storage: SessionStorage = {
      value: {
        appUserId: userId,
        appSessionId: sessionId,
      },
    };
    sessionStorage.setItem('_session', JSON.stringify(storage));

    googleAnalyticsEvent(AnalyticsEvent.WM_NIGEL_PAGE_LOAD, undefined, storage.value);

    return storage;
  }

  const [session, setSession] = useState<SessionOptions>();
  const { initialized, currentUser } = useAuth();

  useEffect(() => {
    if (!initialized) {
      return;
    }

    const userId: string = currentUser?.id || '';

    let storageData = getStorage();
    let sessionId = '';

    if (!storageData || storageData.value.appSessionId === '') {
      sessionId = uuidv4();
    } else {
      sessionId = storageData.value.appSessionId;
    }
    storageData = setStorage(userId, sessionId);
    setSession(storageData.value);
  }, [initialized, currentUser?.id]);

  const contextType: AnalyticsContextType = {
    event: (event: AnalyticsEvent, data?: AnalyticsEventData): void => {
      googleAnalyticsEvent(event, data, session);
    },
  };

  return contextType;
};

const AnalyticsContext: React.Context<AnalyticsContextType | null> =
  createContext<AnalyticsContextType | null>(null);

export const AnalyticsProvider = ({ children }: Props): React.ReactElement => {
  const analyticsContextType = AnalyticsHelper();

  return (
    <AnalyticsContext.Provider value={analyticsContextType}>{children}</AnalyticsContext.Provider>
  );
};

export const useAnalytics = (): AnalyticsContextType => {
  const analyticsContext = useContext(AnalyticsContext);

  if (!analyticsContext) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider');
  }

  return analyticsContext;
};
