import { DeleteOutline, Edit } from '@mui/icons-material';
import { Button, IconButton, styled, Typography } from '@mui/material';
import { TextField } from 'mui-rff';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { ProjectConfigQuestion } from 'Types/dealGPT';

const FlexDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

const HeaderLabel = styled(Typography)({
  marginBottom: '10px',
  color: '#092B49',
  fontWeight: '700',
});

export type KeyQuestionProps = {
  index: number;
  question: ProjectConfigQuestion;
  handleDelete: (index: number) => void;
  handleUpdate: (index: number, question: ProjectConfigQuestion) => void;
};

const KeyQuestion = ({
  index,
  question,
  handleDelete,
  handleUpdate,
}: KeyQuestionProps): JSX.Element => {
  const [isEditing, setIsEditing] = useState<boolean>(question.question != '' ? false : true);
  const [formInitialValues, setFormInitialValues] = useState<ProjectConfigQuestion>(question);

  useEffect(() => {
    setIsEditing(question.question != '' ? false : true);
    setFormInitialValues(question);
  }, [question]);

  return (
    <FlexDiv>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '60px',
          }}
        >
          <HeaderLabel>Enter in Key Question:</HeaderLabel>
          <IconButton
            onClick={() => handleDelete(index)}
            sx={{
              float: 'right',
              borderRadius: '0px',
              padding: '0px',
            }}
          >
            <DeleteOutline
              sx={{
                border: '1px solid #B8C1C7',
                borderRadius: '4px',
                padding: '12px',
                fontSize: '20px',
                height: '100%',
              }}
            />
          </IconButton>
        </div>
        <Form
          onSubmit={() => {}}
          initialValues={{
            keyQuestion: formInitialValues.question,
          }}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <Typography sx={{ fontWeight: '700', color: '#092B49' }}>
                  {`${index + 1})`}
                </Typography>
                <div style={{ width: '80%' }}>
                  {isEditing ? (
                    <TextField sx={{ width: '100%' }} name="keyQuestion" multiline />
                  ) : (
                    <Typography sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                      {question.question}
                    </Typography>
                  )}
                </div>

                {isEditing ? (
                  <Button
                    disabled={!isEditing || values.keyQuestion === undefined}
                    variant="outlined"
                    onClick={() => {
                      {
                        setIsEditing(false);
                        handleUpdate(index, { ...question, question: values.keyQuestion });
                      }
                    }}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    startIcon={<Edit />}
                    onClick={() => setIsEditing(true)}
                  >
                    Edit
                  </Button>
                )}
              </div>
            </form>
          )}
        />
      </div>
    </FlexDiv>
  );
};

export default KeyQuestion;
