import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';

import type { ProjectRunAnalysisQuestion } from '../../../Types/dealGPT';
import { ProcessReason, UpdatedReason } from '../../../Types/enums';
import SectionButtons from './SectionButtons';
interface MoreOptionsMenuProps {
  handleSave: (
    values: Record<string, string>,
    reason?: UpdatedReason,
    processReason?: ProcessReason
  ) => Promise<void>;
  values: Record<string, string>;
  isEditing?: boolean;
  analysisQuestionData: ProjectRunAnalysisQuestion;
  handleValidateFindingsClick: (questionId: string) => void;
}

const MoreOptionsMenu: React.FC<MoreOptionsMenuProps> = ({
  handleSave,
  values,
  isEditing = true,
  analysisQuestionData,
  handleValidateFindingsClick,
}) => {
  const [anchorContextMenu, setAnchorContextMenu] = useState<null | HTMLElement>(null);
  const handleOpenContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorContextMenu(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorContextMenu(null);
  };

  return (
    <div
      onClick={(event) => event.stopPropagation()}
      style={{ display: 'flex', flexDirection: 'column', width: '3%' }}
    >
      <Tooltip
        id={`open-settings-label-${analysisQuestionData?.id}`}
        title={<Typography variant="caption"> More Options </Typography>}
        PopperProps={{ keepMounted: true }}
      >
        <IconButton
          onClick={handleOpenContextMenu}
          aria-labelledby={`open-settings-label-${analysisQuestionData?.id}`}
        >
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '32px' }}
        anchorEl={anchorContextMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorContextMenu)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem>
          <SectionButtons
            handleSave={handleSave}
            values={values}
            hasGenerated={true}
            isEditing={isEditing}
            question={analysisQuestionData}
            showGenerateButton={true}
            showEditButton={false}
            showSaveButton={false}
            isMountedOnMenu={true}
            handleCloseMenu={handleCloseUserMenu}
            showValidateButton={false}
          />
        </MenuItem>
        <MenuItem>
          <SectionButtons
            handleSave={handleSave}
            values={values}
            hasGenerated={true}
            isEditing={false}
            question={analysisQuestionData}
            showGenerateButton={false}
            showEditButton={false}
            showSaveButton={false}
            isMountedOnMenu={true}
            handleCloseMenu={handleCloseUserMenu}
            showValidateButton={true}
            handleValidate={handleValidateFindingsClick}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default MoreOptionsMenu;
