import { HelpOutline, OpenInNew } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  IconButton,
  styled,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../components/Providers/AuthProvider';
import { useJoyride } from '../../components/Providers/JoyrideProvider';
import { StyledPagination } from '../../theme/CustomComponents';
import { UserRole } from '../../Types/enums';
import { GuidedSteps } from '../../Types/guides';
import DebouncedSearchInput from '../DebouncedSearchInput';
import { AnalyticsEvent, useAnalytics } from '../Providers/AnalyticsProvider';
import { useProjects } from '../Providers/ProjectsProvider';
import ProjectCard from './ProjectCard';

enum LinkType {
  FAQ = 'FAQ',
  USER_GUIDE = 'USER_GUIDE',
  GLEAN_API = 'GLEAN_API',
}

const HomePageContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  textAlign: 'left',
  marginTop: '60px',
  marginBottom: '100px',
  gap: '24px',
});

const ProjectsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '20px',
  justifyContent: 'center',
});

const StyledButton = styled(Button)({
  width: '150px',
  height: '35px',
});

const ExternalLink = styled('a')({
  color: '#070154',
  textDecoration: 'none',
});

const HomePageInfo = (): JSX.Element => {
  const {
    projects,
    setPage,
    page,
    totalPages,
    setShouldFetch,
    isLoading,
    protectedSetAdminShowAll,
    protectedSetShowArchived,
    adminShowAll,
    showArchived,
    searchQuery,
    setSearchQuery,
  } = useProjects();

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [newVisit, setNewVisit] = useState<boolean>(true);
  const { tourState, setTourState } = useJoyride();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handlePageChange = useCallback(
    (_e: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
      setShouldFetch(true);
    },
    [setPage, setShouldFetch]
  );

  // Make sure we fetch the projects if the user starts on the dealGPT page
  useEffect(() => {
    if (newVisit) {
      setShouldFetch(true);
      setNewVisit(false);
    }
  }, [newVisit, setShouldFetch]);
  const analytics = useAnalytics();

  const pagination = useMemo(
    () => (
      <StyledPagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        size={'small'}
        sx={{ justifyContent: 'center', display: 'flex' }}
        disabled={isLoading}
      />
    ),
    [isLoading, handlePageChange, page, totalPages]
  );

  const OpenInNewIcon = (
    <OpenInNew sx={{ fontSize: '20px', marginRight: '2px', verticalAlign: 'text-top' }} />
  );

  const handleCreateProjectClick = async () => {
    analytics.event(AnalyticsEvent.WM_IA_HOME_CREATE_PROJECT);

    navigate('/intellio-advantage/create-project');
  };

  const sendAnalyticsEvent = async (linkName: LinkType) => {
    let event = AnalyticsEvent.WM_IA_HOME_TEXT_USER_GUIDE;

    if (linkName == LinkType.FAQ) {
      event = AnalyticsEvent.WM_IA_HOME_TEXT_FAQ;
    }

    if (linkName == LinkType.GLEAN_API) {
      event = AnalyticsEvent.WM_IA_HOME_TEXT_GLEAN_INFO;
    }

    analytics.event(event);
  };

  return (
    <HomePageContainer maxWidth="md">
      <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
        <Typography id="dealgpt-welcome" variant="h4" sx={{ fontWeight: '700', fontSize: '36px' }}>
          Welcome to Intellio® Advantage
        </Typography>
        <Tooltip title="Launch tour">
          <IconButton
            sx={{ p: 0, width: '30px', height: '30px' }}
            onClick={() => setTourState({ ...tourState, ...{ steps: GuidedSteps, run: true } })}
          >
            <HelpOutline sx={{ width: '20px', height: '20px', color: 'rgb(9, 43, 73)' }} />
          </IconButton>
        </Tooltip>
      </div>
      <Typography variant="subtitle1" sx={{ fontSize: '16px' }}>
        Welcome to Intellio® Advantage, your advanced solution powered by Nigel. This powerful tool
        excels at scanning and analyzing large volumes of documents. It can automatically identify,
        extract, and analyze text in contracts and other documents, saving significant manual review
        time. To learn how to add a project or use Intellio® Advantage, choose from the following:
        <ol>
          <li>
            <Typography>
              <span style={{ fontWeight: 'bold' }}>In-App Tour:</span> Click the information icon{' '}
              <HelpOutline sx={{ fontSize: '16px', verticalAlign: 'middle' }} /> next to the
              homepage title.
            </Typography>
          </li>
          <li>
            <Typography>
              <span style={{ fontWeight: 'bold' }}>User Guide:</span> Refer to our{' '}
              <ExternalLink
                onClick={() => sendAnalyticsEvent(LinkType.USER_GUIDE)}
                href="https://howto.westmonroe.com/a/1805084-how-to-add-a-project-and-use-intellio-advantage"
                target="_blank"
              >
                {OpenInNewIcon}
                User Guide
              </ExternalLink>{' '}
              for more detailed instructions.
            </Typography>
          </li>
        </ol>
        If you have further questions, we have a comprehensive list of{' '}
        <ExternalLink
          onClick={() => sendAnalyticsEvent(LinkType.FAQ)}
          href="https://howto.westmonroe.com/a/1805083-intellio-advantage-faqs"
          target="_blank"
        >
          {OpenInNewIcon}
          FAQs
        </ExternalLink>{' '}
        to assist you. For more technical information on the Glean API, click{' '}
        <ExternalLink
          onClick={() => sendAnalyticsEvent(LinkType.GLEAN_API)}
          href="https://howto.westmonroe.com/a/1806125-glean-api"
          target="_blank"
        >
          {OpenInNewIcon}
          here
        </ExternalLink>
        . We strive to make your Intellio® Advantage experience seamless and productive.
      </Typography>
      {currentUser?.roles?.includes(UserRole.DEALGPT_ADMIN) && (
        <StyledButton variant="contained" onClick={handleCreateProjectClick}>
          Create Project
        </StyledButton>
      )}
      <Typography sx={{ fontSize: '24px' }}>My Projects</Typography>
      <DebouncedSearchInput
        value={searchQuery}
        valueUpdated={(value) => {
          analytics.event(AnalyticsEvent.WM_IA_HOME_SEARCH, {
            value: value,
          });
          setSearchQuery(value);
          setShouldFetch(true);
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        shouldFocus={isFocused}
      />
      {totalPages > 1 && projects.length > 0 && pagination}
      <ProjectsContainer>
        {isLoading && (
          <div style={{ width: '100%', height: '100%', position: 'absolute' }}>
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: 5,
                background: 'white',
                opacity: 0.5,
              }}
            />
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 6,
              }}
            >
              <CircularProgress sx={{ marginTop: '20px' }} />
            </div>
          </div>
        )}
        {projects.length > 0 &&
          projects.map((project) => (
            <ProjectCard key={project.id} project={project} setShouldFetch={setShouldFetch} />
          ))}

        {!isLoading && projects.length === 0 && (
          <Typography>No projects found for current user</Typography>
        )}
      </ProjectsContainer>
      {totalPages > 1 && projects.length > 0 && pagination}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          control={
            <Switch
              checked={showArchived}
              disabled={isLoading}
              onChange={(e) => {
                analytics.event(AnalyticsEvent.WM_IA_HOME_SHOW_ARCHIVE, {
                  value: e.target.checked.toString(),
                });
                protectedSetShowArchived(e.target.checked);
              }}
              color="primary"
            />
          }
          label="Show archived projects"
        />
      </div>
      {currentUser?.roles?.includes(UserRole.DEALGPT_ADMIN) && (
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '50px' }}>
          <Typography style={{ color: 'grey ' }}>Admin options:</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={adminShowAll}
                disabled={isLoading}
                onChange={(e) => {
                  analytics.event(AnalyticsEvent.WM_IA_HOME_SHOW_ALL_PROJECTS, {
                    value: e.target.checked.toString(),
                  });
                  protectedSetAdminShowAll(e.target.checked);
                }}
                color="primary"
              />
            }
            label="Show all projects"
          />
        </div>
      )}
    </HomePageContainer>
  );
};

export default HomePageInfo;
