import { Close, Edit, PlayArrowOutlined, VerifiedOutlined } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import { checkDependencies } from '../../../services/dealGPT';
import type { ProjectRunAnalysisQuestion, StepDependenciesOptions } from '../../../Types/dealGPT';
import { ProcessReason, UpdatedReason } from '../../../Types/enums';
import type { Result } from '../../../Types/result';
import { AnalyticsEvent, useAnalytics } from '../../Providers/AnalyticsProvider';
interface SectionButtonsProps {
  handleSave: (
    values: Record<string, string>,
    reason?: UpdatedReason,
    processReason?: ProcessReason
  ) => Promise<void>;
  values: Record<string, string>;
  hasGenerated: boolean;
  showEditButton?: boolean;
  handleEdit?: (value: boolean) => void;
  isEditing?: boolean;
  showSaveButton?: boolean;
  allowGeneration?: boolean;
  question?: ProjectRunAnalysisQuestion;
  timeToProcess?: string;
  showGenerateButton?: boolean;
  isMountedOnMenu?: boolean;
  showValidateButton?: boolean;
  handleCloseMenu?: () => void;
  handleValidate?: (questionId: string) => void;
}

const SectionButtons: React.FC<SectionButtonsProps> = ({
  handleSave,
  values,
  hasGenerated,
  showEditButton = true,
  handleEdit = () => {},
  isEditing = true,
  showSaveButton = true,
  allowGeneration = true,
  question,
  timeToProcess = '5-10 minutes',
  showGenerateButton = true,
  isMountedOnMenu = false,
  showValidateButton = false,
  handleCloseMenu,
  handleValidate,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [dependencyMap, setDependencyMap] = useState<Map<string, string[]>>();
  const analytics = useAnalytics();

  const handleCloseDialog = (sendAnalyticEvent = true) => {
    if (sendAnalyticEvent) {
      analytics.event(AnalyticsEvent.WM_IA_A_APPLY_CHANGES_DIALOG_CLOSE, {
        id: question?.id,
        value: question?.pageName,
      });
    }
    setOpenDialog(false);
  };

  const handleOpenDialog = async () => {
    analytics.event(AnalyticsEvent.WM_IA_A_APPLY_CHANGES, {
      id: question?.id,
      value: question?.pageName,
    });
    let dependencies: Result<StepDependenciesOptions> | undefined = undefined;

    if (question) {
      dependencies = await checkDependencies(question);
    }

    if (dependencies?._data) {
      const uniqueDependencyMap: Map<string, Set<string>> = new Map();
      dependencies._data.dependencies.forEach((dependency) => {
        const key = dependency.pageName;
        if (!uniqueDependencyMap.has(key)) {
          uniqueDependencyMap.set(key, new Set());
        }

        const promptTypes = uniqueDependencyMap.get(key);
        if (promptTypes && dependency.promptType) {
          promptTypes.add(dependency.promptType);
        }
      });

      const result = new Map();
      uniqueDependencyMap.forEach((value, key) => {
        result.set(key, Array.from(value));
      });

      setDependencyMap(result);
    }

    setOpenDialog(true);
  };

  const handleConfirmApplyChanges = async () => {
    analytics.event(AnalyticsEvent.WM_IA_A_APPLY_CHANGES_CONFIRM, {
      id: question?.id,
      value: question?.pageName,
    });
    handleCloseDialog(false);
    await handleSave(
      values,
      isEditing ? UpdatedReason.USER_EDIT : undefined,
      ProcessReason.APPLY_CHANGES
    );
    handleEdit(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        gap: '16px',
        width: '100%',
        justifyContent: showGenerateButton || showValidateButton ? 'space-between' : 'flex-end',
      }}
    >
      {showGenerateButton && (
        <Tooltip
          title={
            <div style={{ whiteSpace: 'pre-line' }}>
              {hasGenerated
                ? "Not happy with the LLM output or did you change things on previous pages? Try regenerating this output!\n\nDon't worry, you can go back to this version"
                : 'Generate a response from the LLM'}
            </div>
          }
          placement="left"
        >
          <span>
            <Button
              variant={isMountedOnMenu ? 'text' : 'contained'}
              color={isMountedOnMenu ? 'inherit' : 'primary'}
              startIcon={isMountedOnMenu ? <PlayArrowOutlined /> : undefined}
              onClick={() => {
                analytics.event(AnalyticsEvent.WM_IA_A_QUESTION_GENERATE, {
                  id: question?.id,
                  value: question?.pageName,
                });
                handleSave(values, UpdatedReason.REPROCESS, ProcessReason.PROCESS_THIS);
                handleEdit(false);
                if (handleCloseMenu) {
                  handleCloseMenu();
                }
              }}
              disabled={!allowGeneration}
              sx={{
                '&:hover': isMountedOnMenu ? { backgroundColor: 'inherit' } : {},
              }}
            >
              {hasGenerated ? 'Regenerate This' : 'Generate This'}
            </Button>
          </span>
        </Tooltip>
      )}
      {showValidateButton && (
        <div>
          <Tooltip
            title={
              <div style={{ whiteSpace: 'pre-line' }}>Have the LLM validate its own findings</div>
            }
            placement="left"
          >
            <Button
              variant={isMountedOnMenu ? 'text' : 'contained'}
              color={isMountedOnMenu ? 'inherit' : 'primary'}
              startIcon={isMountedOnMenu ? <VerifiedOutlined /> : undefined}
              onClick={() => {
                analytics.event(AnalyticsEvent.WM_IA_D_VALIDATE, {
                  id: question?.id,
                  value: question?.pageName,
                });
                if (handleValidate) {
                  handleValidate(question?.id || '');
                }
                if (handleCloseMenu) {
                  handleCloseMenu();
                }
              }}
              disabled={!allowGeneration}
              sx={{
                '&:hover': isMountedOnMenu ? { backgroundColor: 'inherit' } : {},
              }}
            >
              Validate
            </Button>
          </Tooltip>
        </div>
      )}
      <div style={{ display: 'flex', gap: '16px' }}>
        {showEditButton &&
          (isEditing ? (
            <Button
              variant="outlined"
              onClick={() => {
                analytics.event(AnalyticsEvent.WM_IA_A_QUESTION_EDIT, {
                  id: question?.id,
                  value: `Page=${question?.pageName},IsEditing=${true}`,
                });
                handleEdit(false);
              }}
            >
              Discard Changes
            </Button>
          ) : (
            <Tooltip
              title={
                <div style={{ whiteSpace: 'pre-line' }}>
                  {
                    'Edit this response to tweak what the LLM generated.\n\nYou should then apply the changes to downstream dependencies so the LLM can update its responses with the right information.'
                  }
                </div>
              }
            >
              <span>
                <Button
                  variant="outlined"
                  startIcon={<Edit />}
                  onClick={() => {
                    analytics.event(AnalyticsEvent.WM_IA_A_QUESTION_EDIT, {
                      id: question?.id,
                      value: `Page=${question?.pageName},IsEditing=${false}`,
                    });
                    handleEdit(true);
                  }}
                  sx={{ height: '100%' }}
                >
                  Edit
                </Button>
              </span>
            </Tooltip>
          ))}
        {showSaveButton && (
          <Tooltip
            title={
              <div style={{ whiteSpace: 'pre-line' }}>
                {
                  "Save what you have. Use this to keep working on this response later.\n\nWhen you're ready, apply the changes to downstream dependencies so the LLM can update its responses with the right information."
                }
              </div>
            }
          >
            <span>
              <Button
                disabled={!isEditing}
                variant="outlined"
                onClick={() => {
                  analytics.event(AnalyticsEvent.WM_IA_A_QUESTION_SAVE, {
                    id: question?.id,
                    value: question?.pageName,
                  });
                  handleSave(values, UpdatedReason.USER_EDIT);
                  handleEdit(false);
                }}
                sx={{ height: '100%' }}
              >
                Save
              </Button>
            </span>
          </Tooltip>
        )}
        {showEditButton && (
          <Tooltip
            title={
              <div style={{ whiteSpace: 'pre-line' }}>
                {isEditing
                  ? 'Have this how you want it? Save these changes and immediately "apply" them to downstream dependencies. This will regenerate LLM responses on later pages that depend on this one.\n\nDon\'t worry! You can switch between versions if you liked what you had, but give it a try!'
                  : "Have this how you want it? Apply it to downstream dependencies. This will regenerate LLM responses on later pages that depend on this one.\n\nDon't worry! You can switch between versions if you liked what you had, but give it a try!"}
              </div>
            }
          >
            <span>
              <Button variant="contained" onClick={handleOpenDialog}>
                {isEditing ? 'Save and Apply Changes' : 'Apply Changes'}
              </Button>
            </span>
          </Tooltip>
        )}
        <Dialog
          open={openDialog}
          onClose={() => {
            handleCloseDialog();
          }}
        >
          <DialogTitle>Are you sure you want to apply changes?</DialogTitle>
          <IconButton
            onClick={() => {
              handleCloseDialog();
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#757575',
            }}
          >
            <Close />
          </IconButton>
          <DialogContent style={{ paddingTop: '0px' }}>
            <DialogContentText>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="caption" sx={{ color: 'red', paddingBottom: '16px' }}>
                  {`Please be aware this process should take ${timeToProcess}.`}
                </Typography>
                This will generate or regenerate the following dependencies:
                <Typography>
                  <ul>
                    {dependencyMap &&
                      Array.from(dependencyMap.entries()).map(([key, value], index) => (
                        <li key={`dependency-${index}`}>{`${
                          value.length > 0 ? value.join(', ') + ' on page ' : ''
                        }${key}`}</li>
                      ))}
                  </ul>
                </Typography>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmApplyChanges}>Continue</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default SectionButtons;
