import { ArchiveOutlined, MoreHoriz, UnarchiveOutlined } from '@mui/icons-material';
import { Chip, IconButton, Menu, MenuItem, styled, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { archiveProject } from '../../services/dealGPT';
import type { ProjectType } from '../../Types/dealGPT';
import { ProjectStatus } from '../../Types/enums';
import { AnalyticsEvent, useAnalytics } from '../Providers/AnalyticsProvider';
import StatusProgressBar from './StatusProgressBar';

const ProjectCardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '220px',
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
  padding: '16px 24px',
  cursor: 'pointer',
  minHeight: '230px',
});

const IconContainer = styled('span')({
  color: 'gray',
  transition: 'all 0.2s ease-in-out',
  display: 'flex',

  span: {
    color: 'black',
    marginLeft: '5px',
  },
});

const StyledChip = styled(Chip)({
  width: 'fit-content',
  borderRadius: '4px',
  color: 'black',
  fontSize: '14px',
  fontWeight: '700',
});

export type ProjectCardProps = {
  project: ProjectType;
  setShouldFetch: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProjectCard = ({ project, setShouldFetch }: ProjectCardProps): JSX.Element => {
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const [cardContextMenu, setCardContextMenu] = useState<null | HTMLElement>(null);

  if (!project) {
    return <></>;
  }

  const handleProjectClick = async (id: string) => {
    analytics.event(AnalyticsEvent.WM_IA_HOME_PROJECT_CLICK, {
      id: id,
    });
    navigate(`/intellio-advantage/${id}`);
  };

  const handleOpenProjectCardContextMenu = (event: React.MouseEvent<HTMLElement>) => {
    setCardContextMenu(event.currentTarget);
  };

  const handleCloseProjectMenu = () => {
    setCardContextMenu(null);
  };

  const handleArchiveClick = async (id: string, status: ProjectStatus) => {
    handleCloseProjectMenu();
    const shouldArchive = !(status == ProjectStatus.ARCHIVED);
    analytics.event(AnalyticsEvent.WM_IA_HOME_ARCHIVE, {
      id: id,
      value: `IsArchived=${shouldArchive}`,
    });

    try {
      const archiveResponse = await archiveProject(project.id, { archive: shouldArchive });
      if (archiveResponse._isSuccess) {
        if (archiveResponse._data) {
          const message = shouldArchive ? 'archived' : 'unarchived';
          enqueueSnackbar(`Project ${message} successfully`, { variant: 'success' });
          setShouldFetch(true);
        }
      }
    } catch (error) {
      enqueueSnackbar(`Failed to archive project: ${error}`, { variant: 'error' });
    }
  };

  return (
    <ProjectCardContainer id={`projectid${project.id}`}>
      <IconButton
        onClick={handleOpenProjectCardContextMenu}
        aria-label="More Options"
        sx={{ position: 'absolute', top: '16px', right: '24px', zIndex: 1, padding: '0' }}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        sx={{ mt: '25px' }}
        id="menu-project"
        anchorEl={cardContextMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(cardContextMenu)}
        onClose={handleCloseProjectMenu}
      >
        <MenuItem onClick={() => handleArchiveClick(project.id, project.status)}>
          <Typography textAlign="center">
            <IconContainer>
              {project.status == ProjectStatus.ARCHIVED ? (
                <>
                  <UnarchiveOutlined />
                  <span>Unarchive</span>
                </>
              ) : (
                <>
                  <ArchiveOutlined />
                  <span>Archive</span>
                </>
              )}
            </IconContainer>
          </Typography>
        </MenuItem>
      </Menu>
      <div style={{ flexGrow: '1' }} onClick={() => handleProjectClick(project.id)}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {project.lastAnalysisRunTime && (
            <StyledChip
              label={'ANALYSIS'}
              sx={{ backgroundColor: '#1dd566', color: '#070154', border: '1.5px solid #070154' }}
            />
          )}
          {project.lastDiscoveryRunTime && !project.lastAnalysisRunTime && (
            <StyledChip
              label={'DISCOVERY'}
              sx={{ backgroundColor: '#00a3ff', color: '#070154', border: '1.5px solid #070154' }}
            />
          )}
          {!project.lastDiscoveryRunTime && !project.lastAnalysisRunTime && (
            <StyledChip
              label={'NOT STARTED'}
              sx={{ backgroundColor: '#E8EEF8', color: '#070154', border: '1.5px solid #070154' }}
            />
          )}
          <Typography sx={{ fontSize: '16px', fontWeight: '700', marginTop: '8px' }}>
            {project.name}
          </Typography>
          {project.clientName ? (
            <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
              <span style={{ fontStyle: 'italic' }}>Client:</span> {project.clientName}
            </Typography>
          ) : (
            <br />
          )}
        </div>
        {project.lastAnalysisRunTime && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <div>
              <Typography sx={{ fontSize: '16px', fontWeight: '400', fontStyle: 'italic' }}>
                Last Analysis:
              </Typography>
              <Typography sx={{ fontSize: '16px', fontWeight: '400', fontStyle: 'italic' }}>
                {new Date(project.lastAnalysisRunTime).toLocaleString()}
              </Typography>
            </div>
          </div>
        )}
        {project.lastDiscoveryRunTime && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <div>
              <Typography sx={{ fontSize: '16px', fontWeight: '400', fontStyle: 'italic' }}>
                Last Discovery:
              </Typography>
              <Typography sx={{ fontSize: '16px', fontWeight: '400', fontStyle: 'italic' }}>
                {new Date(project.lastDiscoveryRunTime).toLocaleString()}
              </Typography>
            </div>
            <div style={{ height: '16px', paddingBottom: '20px' }}>
              <StatusProgressBar
                quantity={project.quantityOfQuestions}
                quantityAnswered={project.quantityOfAnsweredQuestions}
                quantityMoreInfoNeeded={project.quantityOfMoreInfoNeededQuestions}
                quantityNotFound={project.quantityOfNotFoundQuestions}
                quantitySkipped={project.quantityOfSkippedQuestions}
              />
            </div>
          </div>
        )}
      </div>
    </ProjectCardContainer>
  );
};

export default ProjectCard;
