import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import React from 'react';

import { DealGPTQuestionStatus } from '../../Types/enums';

const ProgressRoot = styled('span')({
  backgroundColor: '#E8EEF8',
  display: 'flex',
  position: 'relative',
  zIndex: '0',
  height: '100%',
  borderRadius: '8px',
});

type ProgressBarProps = {
  answeredProgress: number;
  moreInfoProgress: number;
  notFoundProgress: number;
  skippedProgress: number;
};

const AnsweredProgressBar = styled('span')<ProgressBarProps>(
  ({ answeredProgress, moreInfoProgress, notFoundProgress, skippedProgress }) => ({
    border: answeredProgress ? '1.5px solid #070154' : 0,
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    // If there are no more info or not found questions
    borderTopRightRadius: moreInfoProgress || notFoundProgress || skippedProgress ? 0 : '8px',
    borderBottomRightRadius: moreInfoProgress || notFoundProgress || skippedProgress ? 0 : '8px',
    backgroundColor: '#1dd566',
    width: `${answeredProgress}%`,
    position: 'absolute',
    left: 0,
    bottom: 0,
    top: 0,
    transition: 'transform .4s linear',
    transformOrigin: 'left',
  })
);

const MoreInfoProgressBar = styled('span')<ProgressBarProps>(
  ({ answeredProgress, moreInfoProgress, notFoundProgress, skippedProgress }) => ({
    border: moreInfoProgress ? '1.5px solid #070154' : 0,
    // If there are no answered questions
    borderTopLeftRadius: !answeredProgress ? '8px' : 0,
    borderBottomLeftRadius: !answeredProgress ? '8px' : 0,

    // If there are no not found questions
    borderTopRightRadius: notFoundProgress || skippedProgress ? 0 : '8px',
    borderBottomRightRadius: notFoundProgress || skippedProgress ? 0 : '8px',
    backgroundColor: '#ffc700',
    width: `${moreInfoProgress}%`,
    position: 'absolute',
    left: `${answeredProgress}%`,
    bottom: 0,
    top: 0,
    transition: 'transform .4s linear',
    transformOrigin: 'left',
  })
);

const NotFoundProgressBar = styled('span')<ProgressBarProps>(
  ({ answeredProgress, moreInfoProgress, notFoundProgress, skippedProgress }) => ({
    border: notFoundProgress ? '1.5px solid #070154' : 0,
    // If there are no answered or more info questions
    borderTopLeftRadius: answeredProgress || moreInfoProgress ? 0 : '8px',
    borderBottomLeftRadius: answeredProgress || moreInfoProgress ? 0 : '8px',
    borderTopRightRadius: !skippedProgress ? '8px' : 0,
    borderBottomRightRadius: !skippedProgress ? '8px' : 0,
    backgroundColor: '#f52c00',
    width: `${notFoundProgress}%`,
    position: 'absolute',
    left: `${answeredProgress + moreInfoProgress}%`,
    bottom: 0,
    top: 0,
    transition: 'transform .4s linear',
    transformOrigin: 'left',
  })
);

const SkippedProgressBar = styled('span')<ProgressBarProps>(
  ({ answeredProgress, moreInfoProgress, notFoundProgress, skippedProgress }) => ({
    border: skippedProgress ? '1.5px solid #070154' : 0,
    // If there are no answered or more info questions
    borderTopLeftRadius: answeredProgress || moreInfoProgress || notFoundProgress ? 0 : '8px',
    borderBottomLeftRadius: answeredProgress || moreInfoProgress || notFoundProgress ? 0 : '8px',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    backgroundColor: '#8da3ba',
    width: `${skippedProgress}%`,
    position: 'absolute',
    left: `${answeredProgress + moreInfoProgress + notFoundProgress}%`,
    bottom: 0,
    top: 0,
    transition: 'transform .4s linear',
    transformOrigin: 'left',
  })
);

type ProgressTooltipProps = {
  bgColor?: string;
  fontColor: string;
};

const ProgressTooltip = styled(({ className, ...props }: TooltipProps & ProgressTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ bgColor, fontColor }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: bgColor ? bgColor : 'transparent',
    color: fontColor,
    padding: 0,
    fontSize: '14px',
  },
}));

const tooltipPosition = {
  popper: {
    sx: {
      [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]: {
        marginTop: '0px',
      },
    },
  },
};

const statusNameMap = {
  [DealGPTQuestionStatus.ANSWERED]: 'Completed',
  [DealGPTQuestionStatus.MORE_INFO_NEEDED]: 'Partial',
  [DealGPTQuestionStatus.NOT_FOUND]: 'Missing',
  [DealGPTQuestionStatus.SKIPPED]: 'Skipped',
};

type StatusProgressBarProps = {
  quantity: number;
  quantityAnswered?: number;
  quantityMoreInfoNeeded?: number;
  quantityNotFound?: number;
  quantitySkipped?: number;
};

const StatusProgressBar = ({
  quantity,
  quantityAnswered,
  quantityMoreInfoNeeded,
  quantityNotFound,
  quantitySkipped,
}: StatusProgressBarProps): JSX.Element => {
  let progress = {
    answered: 0,
    moreInfoNeeded: 0,
    notFound: 0,
    skipped: 0,
  };

  if (quantity !== 0) {
    const calculateProgress = (questionQuantity?: number) => {
      return ((questionQuantity || 0) / quantity) * 100;
    };

    progress = {
      answered: calculateProgress(quantityAnswered),
      moreInfoNeeded: calculateProgress(quantityMoreInfoNeeded),
      notFound: calculateProgress(quantityNotFound),
      skipped: calculateProgress(quantitySkipped),
    };
  }

  return (
    <ProgressRoot>
      <ProgressTooltip
        fontColor="#070154"
        title={`${Math.round(progress.answered)}% ${statusNameMap[DealGPTQuestionStatus.ANSWERED]}`}
        placement="bottom-start"
        slotProps={tooltipPosition}
      >
        <AnsweredProgressBar
          answeredProgress={progress.answered}
          moreInfoProgress={progress.moreInfoNeeded}
          notFoundProgress={progress.notFound}
          skippedProgress={progress.skipped}
        />
      </ProgressTooltip>
      <ProgressTooltip
        fontColor="#070154"
        title={`${Math.round(progress.moreInfoNeeded)}% ${
          statusNameMap[DealGPTQuestionStatus.MORE_INFO_NEEDED]
        }`}
        placement="bottom-start"
        slotProps={tooltipPosition}
      >
        <MoreInfoProgressBar
          answeredProgress={progress.answered}
          moreInfoProgress={progress.moreInfoNeeded}
          notFoundProgress={progress.notFound}
          skippedProgress={progress.skipped}
        />
      </ProgressTooltip>
      <ProgressTooltip
        fontColor="#070154"
        title={`${Math.round(progress.notFound)}% ${
          statusNameMap[DealGPTQuestionStatus.NOT_FOUND]
        }`}
        placement="bottom-end"
        slotProps={tooltipPosition}
      >
        <NotFoundProgressBar
          answeredProgress={progress.answered}
          moreInfoProgress={progress.moreInfoNeeded}
          notFoundProgress={progress.notFound}
          skippedProgress={progress.skipped}
        />
      </ProgressTooltip>
      <ProgressTooltip
        fontColor="#070154"
        title={`${Math.round(progress.skipped)}% ${statusNameMap[DealGPTQuestionStatus.SKIPPED]}`}
        placement="bottom-end"
        slotProps={tooltipPosition}
      >
        <SkippedProgressBar
          answeredProgress={progress.answered}
          moreInfoProgress={progress.moreInfoNeeded}
          notFoundProgress={progress.notFound}
          skippedProgress={progress.skipped}
        />
      </ProgressTooltip>
    </ProgressRoot>
  );
};

export default StatusProgressBar;
