import { CheckCircle, LinkOutlined } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Link,
  Switch,
  Typography,
} from '@mui/material';
import React from 'react';

import useCopyToClipboard from '../../hooks/useCopyToClipboard';
import environment from '../../util/environment';
import { AnalyticsEvent, useAnalytics } from '../Providers/AnalyticsProvider';

const ShareDialog = ({
  onSharedUpdate,
  isOpen,
  setIsOpen,
  shared,
  conversationId,
}: {
  onSharedUpdate: (newValue: boolean) => void;
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  shared: boolean;
  conversationId: string;
}) => {
  const { copySuccess, copyToClipboard } = useCopyToClipboard();
  const analytics = useAnalytics();
  const handleShareChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    analytics.event(AnalyticsEvent.WM_NIGEL_CONVERSATION_SHARED_LINK_CHECK, {
      id: conversationId,
      value: newValue.toString(),
    });
    onSharedUpdate(newValue);
  };

  const handleCancel = () => {
    analytics.event(AnalyticsEvent.WM_NIGEL_CONVERSATION_SHARED_LINK_CLOSE, {
      id: conversationId,
    });
    setIsOpen(false);
  };

  const getHost = () => {
    let host = window.location.host;

    if (environment.VITE_ENVIRONMENT === 'local') {
      host = 'http://' + host;
    } else {
      host = 'https://' + host;
    }

    return host;
  };

  const handleCopy = async () => {
    await copyToClipboard(getHost() + `/chat/shared/${conversationId}`);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Shared Link Settings</DialogTitle>
        <DialogContent style={{ display: 'flex', paddingTop: '10px' }}>
          <FormControlLabel
            control={<Switch checked={shared} onChange={handleShareChange} />}
            label={`Shared Link ${shared ? 'On' : 'Off'}`}
          />
          {shared && (
            <>
              {copySuccess ? (
                <Typography
                  sx={{ color: 'green', display: 'flex', alignItems: 'center', gap: '5px' }}
                >
                  <CheckCircle sx={{ marginLeft: '5px' }} /> Link Copied to Clipboard
                </Typography>
              ) : (
                <Link
                  sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '5px' }}
                  onClick={handleCopy}
                >
                  <LinkOutlined /> Copy Link
                </Link>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ShareDialog;
