import {
  CheckCircleOutlined,
  Info,
  ManageHistory,
  NotInterestedOutlined,
  ReportProblemOutlined,
} from '@mui/icons-material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { StyledTab, StyledTabs } from '../../../theme/CustomComponents';
import type { CategoryType, ProjectRun, ProjectType } from '../../../Types/dealGPT';
import { DealGPTDiscoveryView, DealGPTQuestionStatus } from '../../../Types/enums';
import DebouncedSearchInput from '../../DebouncedSearchInput';
import { AnalyticsEvent, useAnalytics } from '../../Providers/AnalyticsProvider';
import { type Flags } from '../../Providers/FeatureProvider';
import CategoryAccordion from '../CategoryAccordion';
import LoadingMask from '../LoadingMask';
import ArrayFilteredDialog from '../PhaseTwo/ArrayFilteredDialog';
import ProjectRunsDialog from '../PhaseTwo/ProjectRunsDialog';
import StatusProgressBar from '../StatusProgressBar';

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
});

const ProjectRunSelectorContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '16px',
  marginTop: '8px',

  [theme.breakpoints.up('sm')]: {
    padding: '0px',
  },
}));

const StyledLoadingButton = styled(LoadingButton)({
  padding: '8px 22px',
  width: '224px',
  height: '36px',
  boxShadow:
    '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
});

const FilterContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '16px',
  marginTop: '8px',

  [theme.breakpoints.up('sm')]: {
    padding: '0px',
  },
}));

type ScopeFilterProps = {
  selected?: boolean;
};

const ScopeFilter = styled(Typography)<ScopeFilterProps>(({ selected = false }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '125%',
  color: '#092B49',
  backgroundColor: selected ? '#CED7E6' : '#E8EEF8',
  borderRadius: '100px',
  padding: '8px 16px',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
}));

const CategoriesContainer = styled(List)({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '100px',
});

export type DiscoveryViewProps = {
  features: Flags;
  project: ProjectType;
  scopes: string[];
  statuses: DealGPTQuestionStatus[];
  selectedStatusFilters: DealGPTQuestionStatus[];
  distinctScopes: string[];
  numScopeAreasSelected: number;
  searchQuery: string;
  isFiltering: boolean;
  isLoadingScopesDialog: boolean;
  isLoadingRescan: boolean;
  setIsLoadingRescan: (isLoading: boolean) => void;
  isLoadingReport: boolean;
  openFilterDialog: boolean;
  isSelectAllFilter: boolean;
  openRescanDialog: boolean;
  lastScanned: string;
  setShouldFetchRunProgress: (isRescan: boolean) => void;
  timer: string | null;
  expandAllCategories: boolean;
  projectRuns: ProjectRun[] | undefined;
  selectedRun: ProjectRun | undefined;
  runProgressData: CategoryType[];
  aggregateProgressData: CategoryType;
  projectRunStatus: string;
  isScopePreferenceSelected: (scope: string) => boolean;
  handleOpenFilterDialog: () => void;
  handleCloseFilterDialog: () => void;
  handleConfirmFilterDialog: () => void;
  handleOpenRescanDialog: () => void;
  handleCloseRescanDialog: () => void;
  handleGenerateReport: () => void;
  setSelectedRun: (run: ProjectRun) => void;
  isScopeSelected: (scope: string) => boolean;
  isStatusSelected: (status: DealGPTQuestionStatus) => boolean;
  toggleScopeFilters: (scope: string) => void;
  toggleStatusFilters: (status: DealGPTQuestionStatus) => void;
  toggleFilter: (scope: string) => void;
  handleClearScopeFilters: () => void;
  handleClearStatusFilters: () => void;
  toggleSelectAllScopeFilter: () => void;
  toggleCollapseExpandAllCategories: () => void;
  setSearchQuery: (query: string) => void;
  discoveryView: DealGPTDiscoveryView;
  setDiscoveryView: (view: DealGPTDiscoveryView) => void;
};

const DiscoveryView = ({
  features,
  project,
  scopes,
  statuses,
  selectedStatusFilters,
  distinctScopes,
  numScopeAreasSelected,
  searchQuery,
  isFiltering,
  isLoadingRescan,
  setIsLoadingRescan,
  isLoadingReport,
  isLoadingScopesDialog,
  openRescanDialog,
  openFilterDialog,
  isSelectAllFilter,
  lastScanned,
  setShouldFetchRunProgress,
  timer,
  expandAllCategories,
  projectRuns,
  selectedRun,
  runProgressData,
  aggregateProgressData,
  projectRunStatus,
  isScopePreferenceSelected,
  handleOpenFilterDialog,
  handleCloseFilterDialog,
  handleConfirmFilterDialog,
  handleOpenRescanDialog,
  handleCloseRescanDialog,
  handleGenerateReport,
  setSelectedRun,
  isScopeSelected,
  isStatusSelected,
  toggleScopeFilters,
  toggleStatusFilters,
  handleClearScopeFilters,
  handleClearStatusFilters,
  toggleCollapseExpandAllCategories,
  toggleFilter,
  toggleSelectAllScopeFilter,
  setSearchQuery,
  discoveryView,
  setDiscoveryView,
}: DiscoveryViewProps): JSX.Element => {
  const [isLoadingRunData, setIsLoadingRunData] = useState<boolean>(true);
  const [hasRun, setHasRun] = useState<boolean>(true);
  const [discoverySearchSelected, setDiscoverySearchSelected] = useState<boolean>(false);
  const analytics = useAnalytics();
  const [selectedRunIndex, setSelectedRunIndex] = useState<number>(
    projectRuns
      ? projectRuns.length - projectRuns.map((run) => run.id).indexOf(selectedRun?.id ?? '')
      : -1
  );
  const [openProjectRunsDialog, setOpenProjectRunsDialog] = useState<boolean>(false);

  useEffect(() => {
    const handleSetDefaultRun = () => {
      if (projectRuns) {
        if (!selectedRun && projectRuns.length != 0) {
          const runToSet = projectRuns[0];
          setSelectedRun(runToSet);
        }
      }
    };

    handleSetDefaultRun();
  });

  useEffect(() => {
    if (projectRuns) {
      if (projectRuns.length === 0) {
        setHasRun(false);
      } else {
        setHasRun(true);
      }
    }
  }, [project.lastDiscoveryRunId, projectRuns]);

  useEffect(() => {
    setIsLoadingRunData(true);
    setSelectedRunIndex(
      projectRuns
        ? projectRuns.length - projectRuns.map((run) => run.id).indexOf(selectedRun?.id ?? '')
        : -1
    );
  }, [selectedRun, projectRuns]);

  useEffect(() => {
    if (projectRunStatus === 'Completed Successfully') {
      setIsLoadingRunData(true);
    }
  }, [projectRunStatus]);

  useEffect(() => {
    if (runProgressData.length > 0) {
      setIsLoadingRunData(false);
    }
  }, [runProgressData]);

  const formatDateTime = (isoString: string): string => {
    const date = new Date(isoString);

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };

    return date.toLocaleString('en-US', options);
  };

  return (
    <div>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <ButtonContainer>
              {features.discovery_rescan && (
                <StyledLoadingButton
                  variant="contained"
                  sx={{ bgcolor: '#092b49' }}
                  loading={isLoadingRescan}
                  loadingPosition="end"
                  endIcon={<></>}
                  onClick={timer ? handleOpenRescanDialog : handleOpenFilterDialog}
                >
                  Rescan Documents
                </StyledLoadingButton>
              )}
            </ButtonContainer>
            <ArrayFilteredDialog
              isLoading={isLoadingScopesDialog}
              filters={distinctScopes}
              selectedLength={numScopeAreasSelected}
              title="Scope Preferences"
              buttonConfirmText="Run"
              handleCloseDialog={handleCloseFilterDialog}
              handleConfirmDialog={handleConfirmFilterDialog}
              isFilterSelected={isScopePreferenceSelected}
              openDialog={openFilterDialog}
              toggleFilter={toggleFilter}
              toggleSelectAllFilter={toggleSelectAllScopeFilter}
              isSelectAllFilter={isSelectAllFilter}
            />
            <Dialog open={openRescanDialog} onClose={handleCloseRescanDialog}>
              <DialogTitle>Are you sure you want to rescan?</DialogTitle>
              <DialogContent>
                <DialogContentText>A scan is currently in progress.</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseRescanDialog}>Cancel</Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleCloseRescanDialog();
                    handleOpenFilterDialog();
                  }}
                >
                  Confirm rescan
                </Button>
              </DialogActions>
            </Dialog>
            <div style={{ width: '150px', marginTop: '8px' }}>
              <Tooltip title="Ensure all users (Nigel (Glean), Nigel (Glean2), Nigel (Glean3), Nigel (Glean4)) are added to the teams channel">
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Info style={{ color: '#f900d3', width: '20px', height: '20px' }} />
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '300',
                      color: '#f900d3',
                      padding: '2px',
                    }}
                  >
                    Scans all red?
                  </Typography>
                </div>
              </Tooltip>
            </div>
            <Typography
              sx={{ fontSize: '14px', fontWeight: '300', fontStyle: 'italic', marginTop: '8px' }}
            >
              Last Scanned: {lastScanned}
            </Typography>
            {hasRun && (
              <Typography
                sx={{ fontSize: '14px', fontWeight: '300', fontStyle: 'italic', marginTop: '8px' }}
              >
                Status: {project.projectRunStatus}
              </Typography>
            )}
          </div>
          <div id="project-runs">
            {projectRuns && selectedRun && !isLoadingRunData && (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography sx={{ fontSize: '14px', fontWeight: '400', color: '#757575' }}>
                    Project Run
                  </Typography>
                  <ManageHistory
                    onClick={() => {
                      analytics.event(AnalyticsEvent.WM_IA_D_OPEN_RUN_DIALOG, {
                        id: project.id,
                      });
                      setOpenProjectRunsDialog(true);
                    }}
                    sx={{ color: '#092B49', cursor: 'pointer' }}
                  />
                </div>
                {projectRuns[projectRuns.length - selectedRunIndex] && (
                  <ProjectRunSelectorContainer>
                    <div
                      style={{
                        border: '1px solid black',
                        padding: '10px 24px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        width: '250px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        analytics.event(AnalyticsEvent.WM_IA_D_OPEN_RUN_DIALOG, {
                          id: project.id,
                        });
                        setOpenProjectRunsDialog(true);
                      }}
                    >
                      <Typography variant="subtitle2">
                        Run {selectedRunIndex}:{' '}
                        {projectRuns[projectRuns.length - selectedRunIndex].endedAt
                          ? 'Complete'
                          : 'Incomplete'}
                      </Typography>
                      <Typography variant="body1" fontStyle="italic">
                        Date: {formatDateTime(selectedRun.createdAt ?? '')}
                      </Typography>
                    </div>
                  </ProjectRunSelectorContainer>
                )}
                <Typography
                  variant="body1"
                  sx={{ display: 'flex', justifyContent: 'flex-end', fontStyle: 'italic' }}
                >
                  run {selectedRunIndex} of {projectRuns.length}
                </Typography>
                <ProjectRunsDialog
                  selectedRun={selectedRun}
                  projectRuns={projectRuns}
                  openDialog={openProjectRunsDialog}
                  handleCloseDialog={() => setOpenProjectRunsDialog(false)}
                  selectRun={setSelectedRun}
                />
              </>
            )}
          </div>
        </div>
        {hasRun && (
          <div>
            <div id="cover-while-loading" style={{ position: 'relative', minHeight: '100%' }}>
              {isLoadingRunData && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255,255,255,0.8)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    zIndex: 9999,
                  }}
                >
                  <div
                    style={{
                      marginTop: '20%',
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <CircularProgress />
                  </div>
                </div>
              )}

              <div id="scopes-and-categories" style={{ marginTop: '16px' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: '400', color: '#757575' }}>
                  Scope area:
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <FilterContainer maxWidth="lg" sx={{ marginBottom: '24px' }}>
                    {scopes.map((scope) => (
                      <ScopeFilter
                        key={scope}
                        selected={isScopeSelected(scope)}
                        onClick={() => toggleScopeFilters(scope)}
                      >
                        {scope}
                        {isScopeSelected(scope) && <CloseOutlinedIcon sx={{ fontSize: '16px' }} />}
                      </ScopeFilter>
                    ))}
                    <Typography
                      sx={{
                        fontSize: '14px',
                        alignSelf: 'center',
                        color: '#00a3ff',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleClearScopeFilters();
                        handleClearStatusFilters();
                      }}
                    >
                      Clear Filters
                    </Typography>
                  </FilterContainer>
                </div>
              </div>
              <StyledTabs
                value={discoveryView}
                onChange={(event: React.SyntheticEvent, newView: DealGPTDiscoveryView) => {
                  analytics.event(AnalyticsEvent.WM_IA_D_TAB_VIEW, {
                    id: project.id,
                    value: newView,
                  });
                  setDiscoveryView(newView);
                }}
                sx={{ borderBottom: '1px solid #C4C4C4', marginBottom: '16px' }}
              >
                <StyledTab value={DealGPTDiscoveryView.ALL_QUESTIONS} label="All Questions" />
                <StyledTab value={DealGPTDiscoveryView.SITE_VISIT_PREP} label="Site Visit Prep" />
              </StyledTabs>

              <StyledLoadingButton
                variant="outlined"
                loading={isLoadingReport}
                loadingPosition="end"
                endIcon={<></>}
                onClick={() => handleGenerateReport()}
              >
                Generate Report
              </StyledLoadingButton>
              <div
                id="results-and-statuses"
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <div style={{ width: '20%', marginTop: '10px', marginBottom: '24px' }}>
                  <Typography sx={{ fontSize: '14px', fontWeight: '300', fontStyle: 'italic' }}>
                    Results:
                  </Typography>
                  <div style={{ height: '16px' }}>
                    <StatusProgressBar
                      quantity={aggregateProgressData.quantity}
                      quantityAnswered={aggregateProgressData.quantityAnswered}
                      quantityMoreInfoNeeded={aggregateProgressData.quantityMoreInfoNeeded}
                      quantityNotFound={aggregateProgressData.quantityNotFound}
                      quantitySkipped={aggregateProgressData.quantitySkipped}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                    margin: '10px 0px',
                    width: 'fit-content',
                  }}
                >
                  {statuses.includes(DealGPTQuestionStatus.ANSWERED) && (
                    <IconButton
                      disableRipple
                      disabled={discoveryView !== DealGPTDiscoveryView.ALL_QUESTIONS}
                      onClick={() => toggleStatusFilters(DealGPTQuestionStatus.ANSWERED)}
                      sx={{
                        borderRadius: '20%',
                        height: '32px',
                        width: '32px',
                        opacity: discoveryView !== DealGPTDiscoveryView.ALL_QUESTIONS ? 0.5 : 1,
                        ...(isStatusSelected(DealGPTQuestionStatus.ANSWERED)
                          ? { bgcolor: '#CFEAD2' }
                          : { bgcolor: '#E8EEF8' }),
                        '&:hover': {
                          ...(isStatusSelected(DealGPTQuestionStatus.ANSWERED)
                            ? { bgcolor: '#CFEAD2' }
                            : { bgcolor: '#E8EEF8' }),
                        },
                      }}
                    >
                      <CheckCircleOutlined
                        sx={{ height: '16px', width: '16px', color: '#1dd566' }}
                      />
                    </IconButton>
                  )}
                  {/* The use of !important is to override the diabled behavior */}
                  {statuses.includes(DealGPTQuestionStatus.MORE_INFO_NEEDED) && (
                    <IconButton
                      disableRipple
                      disabled={discoveryView !== DealGPTDiscoveryView.ALL_QUESTIONS}
                      onClick={() => toggleStatusFilters(DealGPTQuestionStatus.MORE_INFO_NEEDED)}
                      sx={{
                        borderRadius: '20%',
                        height: '32px',
                        width: '32px',
                        opacity: discoveryView !== DealGPTDiscoveryView.ALL_QUESTIONS ? 0.5 : 1,
                        ...(isStatusSelected(DealGPTQuestionStatus.MORE_INFO_NEEDED) ||
                        discoveryView === DealGPTDiscoveryView.SITE_VISIT_PREP
                          ? { bgcolor: '#FFE9CA !important' }
                          : { bgcolor: '#E8EEF8' }),
                        '&:hover': {
                          ...(isStatusSelected(DealGPTQuestionStatus.MORE_INFO_NEEDED)
                            ? { bgcolor: '#FFE9CA' }
                            : { bgcolor: '#E8EEF8' }),
                        },
                      }}
                    >
                      <ReportProblemOutlined
                        sx={{
                          color: '#ffc700',
                          height: '16px',
                          width: '16px',
                        }}
                      />
                    </IconButton>
                  )}
                  {statuses.includes(DealGPTQuestionStatus.NOT_FOUND) && (
                    <IconButton
                      disableRipple
                      disabled={discoveryView !== DealGPTDiscoveryView.ALL_QUESTIONS}
                      onClick={() => toggleStatusFilters(DealGPTQuestionStatus.NOT_FOUND)}
                      sx={{
                        borderRadius: '20%',
                        height: '32px',
                        width: '32px',
                        opacity: discoveryView !== DealGPTDiscoveryView.ALL_QUESTIONS ? 0.5 : 1,
                        ...(isStatusSelected(DealGPTQuestionStatus.NOT_FOUND) ||
                        discoveryView === DealGPTDiscoveryView.SITE_VISIT_PREP
                          ? { bgcolor: '#FFD4CA !important' }
                          : { bgcolor: '#E8EEF8' }),
                        '&:hover': {
                          ...(isStatusSelected(DealGPTQuestionStatus.NOT_FOUND)
                            ? { bgcolor: '#FFD4CA' }
                            : { bgcolor: '#E8EEF8' }),
                        },
                      }}
                    >
                      <NotInterestedOutlined
                        sx={{ color: '#f52c00', height: '16px', width: '16px' }}
                      />
                    </IconButton>
                  )}
                </div>
              </div>

              <div id="search">
                <FilterContainer maxWidth="lg" sx={{ marginBottom: '24px' }}>
                  <DebouncedSearchInput
                    value={searchQuery}
                    valueUpdated={(value) => {
                      analytics.event(AnalyticsEvent.WM_IA_D_SEARCH_ENTERED, {
                        id: project.id,
                        value: value,
                      });
                      setSearchQuery(value);
                    }}
                    onFocus={() => setDiscoverySearchSelected(true)}
                    onBlur={() => setDiscoverySearchSelected(false)}
                    shouldFocus={discoverySearchSelected}
                  />
                </FilterContainer>
              </div>
              {runProgressData.filter((category) => category.quantity !== 0).length === 0 ? (
                <Typography sx={{ marginBottom: '100px' }}>
                  No results found. Please adjust your filters or search query.
                </Typography>
              ) : (
                <CategoriesContainer className="categories-root" style={{ position: 'relative' }}>
                  <LoadingMask isLoading={isFiltering} />
                  <Typography
                    sx={{
                      fontSize: '14px',
                      color: '#1979CD',
                      cursor: 'pointer',
                      marginBottom: '16px',
                      alignSelf: 'flex-end',
                    }}
                    onClick={toggleCollapseExpandAllCategories}
                  >
                    {expandAllCategories ? 'Collapse all categories' : 'Expand all categories'}
                  </Typography>
                  {runProgressData.map(
                    (category) =>
                      category.quantity > 0 && (
                        <CategoryAccordion
                          projectId={project.id}
                          category={category}
                          runId={selectedRun?.id ?? ''}
                          setIsLoadingProjectRescan={setIsLoadingRescan}
                          latestRunId={projectRuns ? projectRuns[0].id : ''}
                          projectRunStatus={projectRunStatus}
                          key={`${category.scope}-${category.name}`}
                          expandAll={expandAllCategories}
                          gleanAppId={project.gleanAppId}
                          discoveryView={discoveryView}
                          selectedStatusFilters={selectedStatusFilters}
                          searchQuery={searchQuery}
                          setShouldFetchRunProgress={setShouldFetchRunProgress}
                        />
                      )
                  )}
                </CategoriesContainer>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DiscoveryView;
