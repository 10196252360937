import { Add, ArrowBack } from '@mui/icons-material';
import { Button, Container, IconButton, styled, Tooltip, Typography } from '@mui/material';
import { StatusCodes } from 'http-status-codes';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  deleteProjectConfigQuestion,
  getProject,
  getProjectConfig,
  insertProjectKpiQuestion,
  processQuestion,
  saveProjectConfigQuestion,
  updateAnalysisQuestionFeedback,
  updateProjectInvestmentThesis,
} from '../../../services/dealGPT';
import { StyledTab, StyledTabs } from '../../../theme/CustomComponents';
import type { ProjectConfigQuestion, ProjectRunAnalysisQuestion } from '../../../Types/dealGPT';
import {
  AnalysisKPIQuestionType,
  AnalysisQuestionType,
  ProcessReason,
  ProjectConfigView,
  UserRole,
} from '../../../Types/enums';
import { FeedbackInterface } from '../../../Types/feedback';
import DealGPTContainer from '../../DealGPT/ViewModels/DealGPTContainer';
import { useAuth } from '../../Providers/AuthProvider';
import LoadingMask from '../LoadingMask';
import KPI from '../PhaseTwo/KPIs/KPIs';
import { getLatestUniqueQuestions, sortQuestions } from '../utils';
import InvestmentThesis from '../Views/ConfigureProject.tsx/InvestmentThesis';
import KeyQuestion from '../Views/ConfigureProject.tsx/KeyQuestion';
import KpiQuestion from '../Views/ConfigureProject.tsx/KpiQuestion';

const ProjectContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  marginBottom: '100px',
  gap: '24px',
  height: '100%',
});

const initialValues = {
  investmentThesis: '',
  keyQuestions: [
    {
      projectId: '',
      question: '',
      deleted: false,
    },
  ],
  kpiQuestions: [
    {
      projectId: '',
      question: '',
      deleted: false,
      kpiType: AnalysisKPIQuestionType.TEXT,
    },
  ],
  projectRunId: '',
};

const Section = styled('div')({
  '&:not(:first-of-type)': {
    borderTop: '1px solid #E9E9E9',
    paddingTop: '20px',
  },
});

const DealGPTConfigureProjectContainer = (): JSX.Element => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { currentUser } = useAuth();
  const [projectName, setProjectName] = useState<string>('');
  const [fetchConfig, setFetchConfig] = useState<boolean>(true);
  const [view, setView] = useState<ProjectConfigView>(ProjectConfigView.KEY_QUESTIONS);
  const [keyQuestions, setKeyQuestions] = useState<ProjectConfigQuestion[]>(
    initialValues.keyQuestions
  );
  const [investmentThesis, setInvestmentThesis] = useState<string>(initialValues.investmentThesis);
  const [projectRunId, setProjectRunId] = useState<string>('');
  const [questionToAdd, setQuestionToAdd] = useState<ProjectConfigQuestion>();
  const [allKpiQuestionVersions, setAllKpiQuestionVersions] = useState<
    ProjectRunAnalysisQuestion[]
  >([]);
  const [filteredKpiQuestions, setFilteredKpiQuestions] = useState<ProjectRunAnalysisQuestion[]>(
    []
  );
  const [expandAllKpis, setExpandAllKpis] = useState<boolean>(false);
  const [isLoadingThis, setIsLoadingThis] = useState<boolean>(true);

  useEffect(() => {
    const fetchProjectConfig = async () => {
      if (projectId && fetchConfig) {
        const projectResponse = await getProject(
          projectId,
          currentUser?.roles?.includes(UserRole.DEALGPT_ADMIN)
        );

        if (projectResponse._isSuccess) {
          if (projectResponse._data) {
            setProjectName(projectResponse._data.name);
          }
        } else {
          enqueueSnackbar(`Failed to fetch project: ${projectResponse._error}`, {
            variant: 'error',
          });

          if (projectResponse._statusCode === StatusCodes.UNAUTHORIZED) {
            navigate('/intellio-advantage', { replace: true });
          }
        }

        const config = await getProjectConfig(projectId);
        setKeyQuestions(
          config.keyQuestions.length === 0 ? initialValues.keyQuestions : config.keyQuestions
        );
        setInvestmentThesis(config.investmentThesis ?? '');
        setAllKpiQuestionVersions(config.kpiQuestions);
        setProjectRunId(config.projectRunId);
        setFetchConfig(false);
        setIsLoadingThis(false);

        if (config.kpiQuestions.length === 0) {
          setQuestionToAdd({ ...initialValues.kpiQuestions[0] });
        }
      }
    };

    fetchProjectConfig();
  }, [projectId, fetchConfig, navigate, currentUser?.roles]);

  useEffect(() => {
    const sortedUniqueQuestions = sortQuestions(getLatestUniqueQuestions(allKpiQuestionVersions));
    setFilteredKpiQuestions(sortedUniqueQuestions);
  }, [allKpiQuestionVersions]);

  const handleDeleteKeyQuestion = async (index: number) => {
    const newQuestions = [...keyQuestions];
    const deleted = newQuestions.splice(index, 1);

    if (deleted[0].id) {
      await deleteProjectConfigQuestion(deleted[0].id);
    }

    if (newQuestions.length === 0) {
      setKeyQuestions(initialValues.keyQuestions);
    } else {
      setKeyQuestions(newQuestions);
    }
  };

  const updateKeyQuestion = async (index: number, question: ProjectConfigQuestion) => {
    if (projectId) {
      const newQuestions = [...keyQuestions];

      newQuestions[index] = question;

      setKeyQuestions(newQuestions);

      const updatedQuestion = await saveProjectConfigQuestion({ ...question, projectId });

      newQuestions[index] = updatedQuestion as ProjectConfigQuestion;

      setKeyQuestions(newQuestions);
    }
  };

  const updateInvestmentThesis = async (newInvestmentThesis: string) => {
    if (projectId) {
      setInvestmentThesis(newInvestmentThesis);
      await updateProjectInvestmentThesis(projectId, newInvestmentThesis);
    }
  };

  const handleKpiSubmitted = async (question: ProjectConfigQuestion) => {
    if (projectId) {
      setIsLoadingThis(true);
      const inserted = await insertProjectKpiQuestion(
        { ...question, projectId },
        projectRunId,
        filteredKpiQuestions.length + 1
      );

      if (inserted) {
        await processQuestion(inserted, ProcessReason.PROCESS_THIS);
        setQuestionToAdd(undefined);
        setFetchConfig(true);
      }
    }
  };

  const handleKPIFeedbackSubmit = async (
    feedback: FeedbackInterface,
    projectAnalysisQuestionId: string
  ) => {
    await updateAnalysisQuestionFeedback(projectAnalysisQuestionId, feedback);

    setAllKpiQuestionVersions(
      (prevQuestions) =>
        prevQuestions?.map((question) =>
          question.id === projectAnalysisQuestionId
            ? {
                ...question,
                analysisQuestionFeedback: feedback,
              }
            : question
        ) || []
    );
  };

  return (
    <DealGPTContainer>
      <div style={{ overflow: 'auto', width: '100%', height: '100%' }}>
        <Container maxWidth="lg" sx={{ paddingTop: '100px', height: '100%' }}>
          <ProjectContainer>
            <LoadingMask isLoading={isLoadingThis} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ height: '24px', width: '24px', marginRight: '10px' }}
                onClick={() => {
                  navigate(`/intellio-advantage/${projectId}`);
                }}
              >
                <ArrowBack sx={{ color: '#092B49' }} />
              </IconButton>
              <Typography sx={{ fontSize: '36px', fontWeight: '700' }}>{projectName}</Typography>
            </div>
            <Typography variant="h5" sx={{ fontWeight: '400' }}>
              Key Questions / Investment Thesis / KPI Questions
            </Typography>

            <StyledTabs
              value={view}
              onChange={(_, newView: ProjectConfigView) => {
                setView(newView);
              }}
              sx={{ borderBottom: '1px solid #C4C4C4' }}
            >
              <StyledTab value={ProjectConfigView.KEY_QUESTIONS} label="Key Questions" />
              <StyledTab value={ProjectConfigView.INVESTMENT_THESIS} label={'Investment Thesis'} />
              <StyledTab value={ProjectConfigView.KPI} label={'KPI Questions'} />
            </StyledTabs>
            {view === ProjectConfigView.KEY_QUESTIONS && (
              <div style={{ paddingBottom: '100px' }}>
                {keyQuestions.map((question, index) => {
                  return (
                    <Section key={index} sx={{ pb: '16px' }}>
                      <KeyQuestion
                        index={index}
                        question={question}
                        handleDelete={handleDeleteKeyQuestion}
                        handleUpdate={updateKeyQuestion}
                      />
                    </Section>
                  );
                })}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '16px',
                  }}
                >
                  <Tooltip
                    title={<div style={{ whiteSpace: 'pre-line' }}>{'Add another question'}</div>}
                  >
                    <Button
                      variant="outlined"
                      onClick={() =>
                        setKeyQuestions([...keyQuestions, initialValues.keyQuestions[0]])
                      }
                      sx={{ width: '100px' }}
                    >
                      <Add /> Add
                    </Button>
                  </Tooltip>
                </div>
              </div>
            )}
            {view === ProjectConfigView.INVESTMENT_THESIS && (
              <InvestmentThesis
                investmentThesis={investmentThesis}
                submitInvestmentThesis={updateInvestmentThesis}
              />
            )}
            {view === ProjectConfigView.KPI && (
              <div style={{ paddingBottom: '100px' }}>
                <div style={{ display: 'flex', marginBottom: '16px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: '16px',
                    }}
                  >
                    <Tooltip
                      title={<div style={{ whiteSpace: 'pre-line' }}>{'Add another question'}</div>}
                    >
                      <span>
                        <Button
                          variant="outlined"
                          disabled={questionToAdd !== undefined}
                          onClick={() => setQuestionToAdd({ ...initialValues.kpiQuestions[0] })}
                          sx={{ width: '100px' }}
                        >
                          <Add /> Add
                        </Button>
                      </span>
                    </Tooltip>
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                    {allKpiQuestionVersions &&
                      allKpiQuestionVersions.some(
                        (question) => question.type === AnalysisQuestionType.KPI
                      ) && (
                        <Typography
                          sx={{
                            fontSize: '14px',
                            color: '#1979CD',
                            cursor: 'pointer',
                            marginBottom: '16px',
                            alignSelf: 'flex-end',
                          }}
                          onClick={() => {
                            setExpandAllKpis(!expandAllKpis);
                          }}
                        >
                          {expandAllKpis ? 'Collapse all' : 'Expand all'}
                        </Typography>
                      )}
                  </div>
                </div>
                {questionToAdd && (
                  <KpiQuestion
                    question={questionToAdd}
                    handleDelete={() => {
                      setQuestionToAdd(undefined);
                    }}
                    handleUpdate={handleKpiSubmitted}
                  />
                )}
                {filteredKpiQuestions.map((response, index) => (
                  <Section key={index} sx={{ marginTop: '16px' }}>
                    <KPI
                      versions={allKpiQuestionVersions.filter(
                        (v) =>
                          v.promptType === response.promptType &&
                          v.sequence === response.sequence &&
                          v.displayOrder === response.displayOrder
                      )}
                      textfieldName={`KPI-response-${index}`}
                      setIsLoadingThis={setIsLoadingThis}
                      setIsLoadingApplyChanges={setIsLoadingThis}
                      reprocessingTriggered={() => {
                        setIsLoadingThis(true);
                        setFetchConfig(true);
                      }}
                      expandAll={expandAllKpis}
                      handleFeedbackSubmit={handleKPIFeedbackSubmit}
                      handleValidateFindingsClick={() => {}}
                    />
                  </Section>
                ))}
              </div>
            )}
          </ProjectContainer>
        </Container>
      </div>
    </DealGPTContainer>
  );
};

export default DealGPTConfigureProjectContainer;
