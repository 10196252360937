import { MenuItem, Select, styled, Typography } from '@mui/material';
import { TextField } from 'mui-rff';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import supersub from 'remark-supersub';

import { FeedbackContext } from '../../../constants/feedbackConstants';
import { processQuestion, userEditSave } from '../../../services/dealGPT';
import type { ProjectRunAnalysisQuestion } from '../../../Types/dealGPT';
import { ProcessReason, UpdatedReason } from '../../../Types/enums';
import { FeedbackInterface } from '../../../Types/feedback';
import formatDate from '../../../util/dateUtils';
import Feedback from '../../Feedback';
import { AnalyticsEvent, useAnalytics } from '../../Providers/AnalyticsProvider';
import { useFeatures } from '../../Providers/FeatureProvider';
import ModifyTone from './ModifyTone';
import ReadMoreText from './ReadMoreText';
import ReferenceDocuments from './ReferenceDocuments';
import SectionButtons from './SectionButtons';
import UnprocessedChangesDot from './UnprocessedChangesDot';

interface PromptAnswerProps {
  versions: ProjectRunAnalysisQuestion[];
  textfieldName: string;
  setIsLoadingThis: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoadingApplyChanges: React.Dispatch<React.SetStateAction<boolean>>;
  reprocessingTriggered: () => void;
  timeToProcess: string;
  handleFeedbackSubmit: (feedback: FeedbackInterface, projectAnalysisQuestionId: string) => void;
}

const ReactMarkdownWrapper = styled('div')({
  fontSize: '14px',
  lineHeight: '1.5rem',
  letterSpacing: '0.25px',
  '& > *': {
    fontFamily: 'Noto Sans',
  },

  'a, a:visited, a:hover, a:focus': {
    color: 'inherit',
  },
});

const PromptAnswer: React.FC<PromptAnswerProps> = ({
  versions,
  textfieldName,
  setIsLoadingThis,
  setIsLoadingApplyChanges,
  reprocessingTriggered,
  timeToProcess,
  handleFeedbackSubmit,
}) => {
  const [analysisQuestionData, setAnalysisQuestionData] = useState<ProjectRunAnalysisQuestion>(
    versions[0]
  );
  const [questionVersions, setQuestionVersions] = useState<ProjectRunAnalysisQuestion[]>(versions);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const analytics = useAnalytics();

  // This is used when the user clicks the save button
  const handleSave = async (
    values: Record<string, string>,
    reason?: UpdatedReason,
    processReason?: ProcessReason
  ) => {
    const tempAnalysisQuestion: ProjectRunAnalysisQuestion = { ...analysisQuestionData };
    tempAnalysisQuestion.response = values[textfieldName];

    if (reason) {
      tempAnalysisQuestion.updatedReason = reason;
    }

    // console.log('tempAnalysisQuestion', tempAnalysisQuestion);

    let updatedQuestion = tempAnalysisQuestion;
    if (reason === UpdatedReason.USER_EDIT) {
      const updatedResult = await userEditSave(tempAnalysisQuestion);

      if (updatedResult._data) {
        updatedQuestion = updatedResult._data;
        console.log('updatedResult in db', updatedResult._data);
        setAnalysisQuestionData(updatedResult._data);
        enqueueSnackbar('Content saved successfully', { variant: 'success' });
        reprocessingTriggered();
      }
    }

    if (
      tempAnalysisQuestion.updatedReason === UpdatedReason.REPROCESS ||
      processReason === ProcessReason.APPLY_CHANGES
    ) {
      enqueueSnackbar('Your content is being processed', { variant: 'success' });
      setIsLoadingThis(true);
      if (processReason === ProcessReason.APPLY_CHANGES) {
        setIsLoadingApplyChanges(true);
      }
      await processQuestion(updatedQuestion, processReason);
      reprocessingTriggered();
    }
  };

  const handleVersionChange = useCallback(
    async (value: string) => {
      const version = versions.find((v) => v.id === value);
      // console.log('version', version);
      analytics.event(AnalyticsEvent.WM_IA_A_PA_QUESTION_VERSION_CHANGE, {
        id: value,
        value: versions[0].pageName,
      });
      if (version) {
        setAnalysisQuestionData(version);
      }
    },
    [versions, analytics]
  );

  const onSubmit = async () => {
    // setIsLoading(true);
    // enqueueSnackbar('Your content is being processed', { variant: 'success' });
    // const tempAnalysisQuestion = await handleSave(
    //   analysisQuestionData as unknown as Record<string, string>,
    //   UpdatedReason.REPROCESS
    // );
    // if (tempAnalysisQuestion) {
    //   await processQuestion(tempAnalysisQuestion, false);
    //   reprocessingTriggered();
    // }
  };

  const features = useFeatures();

  useEffect(() => {
    // console.log('versions updated:', versions[0].promptType, formatDate(versions[0].createdAt));
    setQuestionVersions(versions);
    handleVersionChange(versions[0].id);
  }, [handleVersionChange, versions]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ [textfieldName]: analysisQuestionData.response }}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '24px', paddingBottom: '24px' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <Typography sx={{ fontWeight: '700', color: '#092B49' }}>
                {analysisQuestionData.promptType}
              </Typography>
              <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <Select
                  value={analysisQuestionData.id}
                  onChange={(event) => handleVersionChange(event.target.value)}
                  size="small"
                >
                  {questionVersions.map((version) => (
                    <MenuItem
                      value={version.id}
                      key={version.id}
                      selected={version.id === analysisQuestionData.id}
                    >
                      Generated on {version.createdAt && formatDate(version.createdAt)} |{' '}
                      {version.updatedReason}
                    </MenuItem>
                  ))}
                </Select>
                {questionVersions[0].unprocessedDownstreamChanges && <UnprocessedChangesDot />}
              </div>
            </div>
            <ModifyTone
              question={analysisQuestionData}
              setIsLoading={setIsLoadingThis}
              reprocessingTriggered={reprocessingTriggered}
            />
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <SectionButtons
                handleSave={handleSave}
                values={values}
                hasGenerated={true}
                handleEdit={setIsEditing}
                isEditing={isEditing}
                question={analysisQuestionData}
                timeToProcess={timeToProcess}
              />
              {/* <LoadingMask isLoading={isLoading} /> */}
              {isEditing ? (
                <TextField name={textfieldName} multiline />
              ) : (
                <ReactMarkdownWrapper>
                  <ReactMarkdown remarkPlugins={[remarkGfm, supersub]}>
                    {analysisQuestionData.response || ''}
                  </ReactMarkdown>
                </ReactMarkdownWrapper>
              )}
            </div>
            <ReferenceDocuments
              questionId={analysisQuestionData.id}
              documents={analysisQuestionData.documents}
              showAnalysisText={true}
            />
            {features.deal_gpt_enriched_prompt_display && (
              <>
                <Typography sx={{ fontWeight: '700', color: '#092B49', marginTop: '20px' }}>
                  Enriched Prompt:
                </Typography>
                <ReadMoreText text={analysisQuestionData.enrichedPrompt || ''} maxLength={120} />
              </>
            )}
            <Feedback
              initialFeedback={
                analysisQuestionData.analysisQuestionFeedback as FeedbackInterface | undefined
              }
              context={FeedbackContext.ANALYSIS_QUESTION}
              onFeedbackSubmit={(feedback) =>
                handleFeedbackSubmit(feedback, analysisQuestionData.id)
              }
            />
          </div>
        </form>
      )}
    />
  );
};

export default PromptAnswer;
