import { LoadingButton } from '@mui/lab';
import { Workbook } from 'exceljs';
import React, { useCallback, useState } from 'react';
import { RubricCore } from 'Types/dealGPT';

import { downloadExcel } from '../../../../util/excel';
import { maturityLevelColors } from './RubricRating';

/**
 * Generates an Excel workbook from the rubric core data
 * @param {Record<string, Record<string, RubricCore[]>>} rubricCoreByArea - The rubric core data grouped by area and dimension
 * @returns {Workbook} The generated Excel workbook
 */
const generateExcel = async (
  rubricCoreByArea: Record<string, Record<string, RubricCore[]>>
): Promise<Workbook> => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet('Rubric Core');

  // Add the first row and  Merge C1 to G1 to create a title row
  worksheet.addRow(['', '', 'Maturity Level']);
  worksheet.mergeCells('C1:G1');

  // Add the second row, the actual header
  worksheet.addRow(['Area', 'Dimension', '1', '2', '3', '4', '5']);

  // Add the rubric core data
  Object.entries(rubricCoreByArea).forEach(([area, areaRubrics]) => {
    Object.entries(areaRubrics).forEach(([dimension, dimensionRubrics]) => {
      const rubricCoreByDimension = dimensionRubrics.sort(
        (a, b) => a.maturityLevel - b.maturityLevel
      );

      const row = worksheet.addRow([
        area,
        dimension,
        ...rubricCoreByDimension.map((core) => core.description),
      ]);

      row.alignment = { vertical: 'middle' };
    });
  });

  // Apply styles to the title row
  const titleRow = worksheet.getRow(1);
  titleRow.height = 30;
  titleRow.eachCell((cell, colNumber) => {
    if (colNumber <= 2) return; // Skip the first two cells

    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '4472C4' },
    };
    cell.alignment = { horizontal: 'center' };
    cell.font = { color: { argb: 'FFFFFF' }, bold: true, size: 18 };
  });

  // Apply styles and maturity level colors to the header row
  const headerRow = worksheet.getRow(2);
  headerRow.height = 30;
  headerRow.eachCell((cell, colNumber) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb:
          colNumber > 2
            ? maturityLevelColors[(colNumber - 2) as keyof typeof maturityLevelColors].replace(
                '#',
                ''
              )
            : '4472C4',
      },
    };
    cell.alignment = { horizontal: 'center' };
    cell.font = { color: { argb: 'FFFFFF' }, bold: true, size: 18 };
  });

  // Set minimum width for all columns
  worksheet.columns.forEach((column) => {
    column.width = 50;
  });

  // Apply styles to the data rows and calculate the required height
  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber <= 2) return;

    let maxHeight = 0;
    row.eachCell((cell, colNumber) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { wrapText: true };
      cell.font = { size: 14, color: { argb: colNumber === 1 ? 'FFFFFF' : '000000' } };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: colNumber === 1 ? '002060' : 'FFFFFF' },
      };

      if (typeof cell.value === 'string') {
        const lines = Math.ceil(cell.value.length / 50); // Estimate number of lines (assuming column width is 50 characters)
        const cellHeight = 15 + lines * 15; // Base height + extra lines
        maxHeight = Math.max(maxHeight, cellHeight); // Track the largest required height
      }
    });

    // Set the row height based on the largest content
    row.height = maxHeight || 50;
  });

  return workbook;
};

interface DownloadRubricCoreButtonProps {
  scope: string | undefined;
  rubricCore: Record<string, Record<string, RubricCore[]>>;
}

export const DownloadRubricCoreButton: React.FC<DownloadRubricCoreButtonProps> = ({
  scope,
  rubricCore,
}) => {
  const [loading, setLoading] = useState(false);

  const onClick = useCallback(async () => {
    setLoading(true);
    const excelContent = await generateExcel(rubricCore);
    downloadExcel(excelContent, `${scope} Rubric.xlsx`);
    setLoading(false);
  }, [scope, rubricCore]);

  if (Object.keys(rubricCore).length === 0) return null;

  return (
    <LoadingButton loading={loading} onClick={onClick}>{`Download ${scope} Rubric`}</LoadingButton>
  );
};
