const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
};

const formatDate = (dateString: string, passedOptions?: Intl.DateTimeFormatOptions) => {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', passedOptions ? passedOptions : options);
};

export default formatDate;
