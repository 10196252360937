import {
  AnalysisQuestionFeedback,
  ArchiveProjectOptions,
  CategoryPartialType,
  CategoryType,
  CreateProjectOptions,
  FetchLastRunOptions,
  GetScopeAreasOptions,
  GetScopeQuestionsOptions,
  PageInfo,
  Project,
  ProjectConfig,
  ProjectConfigQuestion,
  ProjectNotes,
  ProjectRun,
  ProjectRunAnalysisQuestion,
  ProjectScopeAreaType,
  ProjectScopesCategories,
  ProjectsPageOptions,
  ProjectType,
  QuestionType,
  QuestionTypeTreeNode,
  RescanProjectQuestionsOptions,
  RubricCore,
  RunProjectOptions,
  StepDependenciesOptions,
  UpdateProjectOptions,
  ValidateFindingsOptions,
} from '../Types/dealGPT';
import {
  DealGPTDiscoveryView,
  DealGPTQuestionStatus,
  DealGPTTone,
  ProcessReason,
} from '../Types/enums';
import { Result } from '../Types/result';
import { doubleEncodeUriComponent } from '../util/formatterUtils';
import { API_URL, enrichedFetch } from './api';

export const getProjects = (options: ProjectsPageOptions): Promise<Result<ProjectType[]>> => {
  const { page, pageSize, showArchived, adminShowAll, searchQuery } = options;
  const path = adminShowAll ? 'admin/project' : 'project';
  let url = `${API_URL}/dealgpt/${path}?page=${page}&pageSize=${pageSize}&showArchived=${showArchived}`;
  if ((searchQuery ?? '').trim().length > 0) {
    url += `&searchQuery=${searchQuery}`;
  }
  return enrichedFetch(url) as Promise<Result<ProjectType[]>>;
};

export const getProject = (projectId: string, isAdmin = false): Promise<Result<ProjectType>> => {
  return enrichedFetch(`${API_URL}/dealgpt/project/${projectId}?isAdmin=${isAdmin}`) as Promise<
    Result<ProjectType>
  >;
};

export const saveProject = (projectOptions: CreateProjectOptions): Promise<Result<Project>> => {
  return enrichedFetch(`${API_URL}/dealgpt/project`, {
    method: 'POST',
    body: JSON.stringify(projectOptions),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<Result<Project>>;
};

export const editProject = (projectOptions: UpdateProjectOptions): Promise<Result<Project>> => {
  return enrichedFetch(`${API_URL}/dealgpt/project`, {
    method: 'PUT',
    body: JSON.stringify(projectOptions),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<Result<Project>>;
};

export const getProjectConfig = (projectId: string): Promise<ProjectConfig> => {
  return enrichedFetch(`${API_URL}/dealgpt/project/${projectId}/config`) as Promise<ProjectConfig>;
};

export const saveProjectConfigQuestion = (question: ProjectConfigQuestion) => {
  return enrichedFetch(
    `${API_URL}/dealgpt/project/config_question/${question.id ? 'update' : 'insert'}`,
    {
      method: 'POST',
      body: JSON.stringify(question),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

export const deleteProjectConfigQuestion = (questionId: string) => {
  return enrichedFetch(`${API_URL}/dealgpt/project/config_question/${questionId}`, {
    method: 'DELETE',
  });
};

export const updateProjectInvestmentThesis = (projectId: string, investmentThesis: string) => {
  return enrichedFetch(`${API_URL}/dealgpt/project/${projectId}/config/investment_thesis`, {
    method: 'PUT',
    body: JSON.stringify({ investmentThesis }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const KPI_PROMPT = `Format the response like the following json:
{
    ""value"": string;
    ""findings"": string;
}
If you cannot find the answer or there is no mention of the answer in the documents, make sure your response is an empty string. Question is the question I asked you to answer, findings is your reasoning for the value you picked, and value must be text. If a value cannot be found, LEAVE IT EMPTY.

Always share the source in which you think the answer may be found or whether you find the answer in the site visit notes. Use all available documents and the site visit notes for this project. Answer the question: "`;

export const insertProjectKpiQuestion = (
  question: ProjectConfigQuestion,
  runId: string,
  displayOrder: number
): Promise<ProjectRunAnalysisQuestion> => {
  return enrichedFetch(`${API_URL}/dealgpt/project/kpi_question/insert`, {
    method: 'POST',
    body: JSON.stringify({
      runId: runId === undefined ? '' : runId,
      status: 'INITIAL',
      starred: false,
      prompt: KPI_PROMPT + question.question,
      prettyQuestion: question.question,
      scope: 'PROJECT',
      area: 'PROJECT',
      dimension: 'PROJECT',
      pageName: 'KPI',
      type: 'KPI',
      kpiType: question.kpiType,
      sequence: 0,
      displayOrder,
      projectId: question.projectId,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<ProjectRunAnalysisQuestion>;
};

export const archiveProject = (
  projectId: string,
  options: ArchiveProjectOptions
): Promise<Result<boolean>> => {
  return enrichedFetch(`${API_URL}/dealgpt/project/${projectId}/archive`, {
    method: 'PATCH',
    body: JSON.stringify(options),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<Result<boolean>>;
};

export const startRun = (projectId: string, options: RunProjectOptions): Promise<ProjectRun> => {
  return enrichedFetch(`${API_URL}/dealgpt/project/${projectId}/start-run`, {
    method: 'POST',
    body: JSON.stringify(options),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<ProjectRun>;
};

export const fetchProjectScopesAndCategories = (
  projectId: string,
  selectdRunId: string
): Promise<Result<ProjectScopesCategories[]>> => {
  return enrichedFetch(
    `${API_URL}/dealgpt/project/${projectId}/scope-categories/${selectdRunId}`
  ) as Promise<Result<ProjectScopesCategories[]>>;
};

export const fetchCategoryQuestions = (
  projectId: string,
  scopeName: string,
  categoryName: string,
  statuses: DealGPTQuestionStatus[],
  searchQuery: string,
  runId: string
): Promise<Result<QuestionTypeTreeNode[]>> => {
  return enrichedFetch(
    `${API_URL}/dealgpt/project/${projectId}/scope/${doubleEncodeUriComponent(
      scopeName
    )}/category/${doubleEncodeUriComponent(categoryName)}/${runId}/questions`,
    {
      method: 'POST',
      body: JSON.stringify({ statuses, searchQuery }),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  ) as Promise<Result<QuestionTypeTreeNode[]>>;
};

export const fetchCategoriesInfo = (
  projectId: string,
  categories: CategoryPartialType[],
  runId: string,
  statuses: DealGPTQuestionStatus[],
  searchQuery: string
): Promise<Result<CategoryType[]>> => {
  return enrichedFetch(`${API_URL}/dealgpt/project/${projectId}/categories/runId/${runId}`, {
    method: 'POST',
    body: JSON.stringify({ categories, statuses, searchQuery }),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<Result<CategoryType[]>>;
};

export const generateReport = (
  projectId: ProjectType['id'],
  reportType: DealGPTDiscoveryView,
  scopes: string[],
  statuses: DealGPTQuestionStatus[]
): Promise<Result<string>> => {
  return enrichedFetch(`${API_URL}/dealgpt/project/generate-report`, {
    method: 'POST',
    body: JSON.stringify({ projectId, reportType, scopes, statuses }),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<Result<string>>;
};

export const getAllProjectRuns = (
  projectId: ProjectType['id'],
  runType?: string
): Promise<Result<ProjectRun[]>> => {
  let url = `${API_URL}/dealgpt/project/${projectId}/runs`;
  if (runType) {
    url = url + `?runType=${runType}`;
  }
  return enrichedFetch(url) as Promise<Result<ProjectRun[]>>;
};

export const getAnalysisPages = (
  projectId: string,
  runId: string,
  scope: string
): Promise<Result<PageInfo[]>> => {
  return enrichedFetch(
    `${API_URL}/dealgpt/analysis/pages/${projectId}/${runId}/${scope}`
  ) as Promise<Result<PageInfo[]>>;
};

export const getLatestRun = (options: FetchLastRunOptions): Promise<Result<ProjectRun>> => {
  const { projectId, scope, pageName, runType } = options;

  return enrichedFetch(
    `${API_URL}/dealgpt/analysis/runs/${projectId}/${scope}/${pageName}/${runType}`
  ) as Promise<Result<ProjectRun>>;
};

export const getScopeQuestions = (
  options: GetScopeQuestionsOptions
): Promise<Result<ProjectRunAnalysisQuestion[]>> => {
  const { projectId, scope, pageName, runId } = options;

  return enrichedFetch(
    `${API_URL}/dealgpt/analysis/questions/${projectId}/${scope}/${pageName}/${runId}`
  ) as Promise<Result<ProjectRunAnalysisQuestion[]>>;
};

export const getRunStatus = (projectId: string, scope: string, runId: string): Promise<string> => {
  return enrichedFetch(
    `${API_URL}/dealgpt/analysis/run-status/${projectId}/${scope}/${runId}`
  ) as Promise<string>;
};

export const saveNotes = (
  notes: ProjectNotes,
  reprocess = false,
  runId?: string
): Promise<Result<ProjectNotes[]>> => {
  return enrichedFetch(`${API_URL}/dealgpt/analysis/notes?reprocess=${reprocess}`, {
    method: 'POST',
    body: JSON.stringify({ notes, runId }),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<Result<ProjectNotes[]>>;
};

export const getNotes = (projectId: string, scope: string): Promise<Result<ProjectNotes>> => {
  return enrichedFetch(`${API_URL}/dealgpt/analysis/notes/${projectId}/${scope}`) as Promise<
    Result<ProjectNotes>
  >;
};

export const checkDependencies = (
  options: ProjectRunAnalysisQuestion
): Promise<Result<StepDependenciesOptions>> => {
  return enrichedFetch(`${API_URL}/dealgpt/analysis/check-step-dependencies`, {
    method: 'POST',
    body: JSON.stringify(options),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<Result<StepDependenciesOptions>>;
};

export const modifyTone = (
  tone: DealGPTTone,
  question: ProjectRunAnalysisQuestion
): Promise<Result<boolean>> => {
  return enrichedFetch(`${API_URL}/dealgpt/analysis/modify-tone/${tone}`, {
    method: 'POST',
    body: JSON.stringify(question),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<Result<boolean>>;
};

export const softDeleteQuestion = (
  deletedQuestion: ProjectRunAnalysisQuestion
): Promise<Result<boolean>> => {
  return enrichedFetch(`${API_URL}/dealgpt/analysis/soft-delete-question`, {
    method: 'POST',
    body: JSON.stringify(deletedQuestion),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<Result<boolean>>;
};

export const processQuestion = (
  question: ProjectRunAnalysisQuestion,
  processReason?: ProcessReason
): Promise<Result<void>> => {
  let url = `${API_URL}/dealgpt/analysis/process-response`;

  if (processReason) {
    url = `${url}?processReason=${processReason}`;
  }

  return enrichedFetch(url, {
    method: 'POST',
    body: JSON.stringify(question),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<Result<void>>;
};

export const userEditSave = (
  question: ProjectRunAnalysisQuestion
): Promise<Result<ProjectRunAnalysisQuestion>> => {
  return enrichedFetch(`${API_URL}/dealgpt/analysis/user-edit-save`, {
    method: 'POST',
    body: JSON.stringify(question),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<Result<ProjectRunAnalysisQuestion>>;
};

export const getProjectTypes = (): Promise<string[]> => {
  return enrichedFetch(`${API_URL}/dealgpt/project/project-types`) as Promise<string[]>;
};

export const getScopeAreas = (options: GetScopeAreasOptions): Promise<ProjectScopeAreaType[]> => {
  const { projectType, projectPhase, projectId } = options;

  return enrichedFetch(
    `${API_URL}/dealgpt/scope/${projectType}/${projectPhase}/${projectId}`
  ) as Promise<ProjectScopeAreaType[]>;
};

export const processRubric = (questions: ProjectRunAnalysisQuestion[]): Promise<Result<void>> => {
  return enrichedFetch(`${API_URL}/dealgpt/analysis/process-rubric`, {
    method: 'POST',
    body: JSON.stringify(questions),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<Result<void>>;
};

export const validateFindings = (
  options: ValidateFindingsOptions,
  questionId: string,
  projectId: string
): Promise<Result<QuestionType>> => {
  return enrichedFetch(
    `${API_URL}/dealgpt/project/${projectId}/question/${questionId}/validation`,
    {
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  ) as Promise<Result<QuestionType>>;
};

export const validateFindingsAnalysis = (
  options: ValidateFindingsOptions,
  questionId: string,
  projectId: string
): Promise<Result<ProjectRunAnalysisQuestion>> => {
  return enrichedFetch(
    `${API_URL}/dealgpt/analysis/project/${projectId}/question/${questionId}/validation`,
    {
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  ) as Promise<Result<ProjectRunAnalysisQuestion>>;
};

export const rescanQuestions = (options: RescanProjectQuestionsOptions): Promise<Result<void>> => {
  return enrichedFetch(`${API_URL}/projectQuestion/rescan`, {
    method: 'POST',
    body: JSON.stringify(options),
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<Result<void>>;
};

export const updateAnalysisQuestionFeedback = (
  projectAnalysisQuestionId: string,
  analysisQuestionFeedback: AnalysisQuestionFeedback
): Promise<Result<void>> => {
  return enrichedFetch(
    `${API_URL}/dealgpt/analysis/questionAnalysisFeedback/${projectAnalysisQuestionId}`,
    {
      method: 'PUT',
      body: JSON.stringify({ analysisQuestionFeedback }),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  ) as Promise<Result<void>>;
};

export const getRubricCore = (scope: string): Promise<Result<RubricCore[]>> => {
  return enrichedFetch(`${API_URL}/dealgpt/analysis/rubric-core/${scope}`) as Promise<
    Result<RubricCore[]>
  >;
};
