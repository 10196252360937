import { styled, Typography } from '@mui/material';
import React from 'react';

import { ScopeAreasStatus } from '../../../Types/enums';

const ScopeAreaStatusContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '3px',
  padding: '3px 8px',
});

type ScopeAreaStatusProps = {
  status: ScopeAreasStatus;
};

const statusData = {
  [ScopeAreasStatus.NOT_STARTED]: {
    text: ScopeAreasStatus.NOT_STARTED,
    bgColor: '#E8EEF8',
    textColor: '#070154',
  },
  [ScopeAreasStatus.PENDING]: {
    text: ScopeAreasStatus.PENDING,
    bgColor: '#00a3ff',
    textColor: '#070154',
  },
  [ScopeAreasStatus.IN_PROGRESS]: {
    text: ScopeAreasStatus.IN_PROGRESS,
    bgColor: '#00a3ff',
    textColor: '#070154',
  },
  [ScopeAreasStatus.COMPLETE]: {
    text: ScopeAreasStatus.COMPLETE,
    bgColor: '#1dd566',
    textColor: '#070154',
  },
};

const ScopeAreaStatus = ({ status }: ScopeAreaStatusProps): JSX.Element => {
  const { text, bgColor, textColor } = statusData[status];

  return (
    <div style={{ display: 'inline-block' }}>
      <ScopeAreaStatusContainer
        style={{ backgroundColor: bgColor, color: textColor, border: '1.5px solid #070154' }}
      >
        <Typography sx={{ fontSize: '0.775rem', fontWeight: '700', whiteSpace: 'nowrap' }}>
          {text}
        </Typography>
      </ScopeAreaStatusContainer>
    </div>
  );
};

export default ScopeAreaStatus;
