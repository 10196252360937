import { Attachment, Send, Stop } from '@mui/icons-material';
import {
  Alert,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { PayloadAction } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import { v4 as uuidv4 } from 'uuid';
import ChatBubbleComponent from '../components/Chat/ChatBubble';
import FileCard from '../components/Chat/FileCard';
import LimitWarning from '../components/Chat/LimitWarning';
import NavDrawer from '../components/Drawer/NavDrawer';
import NavBar, { NavBarMode } from '../components/NavBar';
import { useAuth } from '../components/Providers/AuthProvider';
import { useFeatures } from '../components/Providers/FeatureProvider';
import {
  getStreamingResponsePrivate,
  postFile,
  postMessagePrivate,
} from '../redux/actions/conversationActions';
import { selectAppStatus } from '../redux/reducers/appStatusReducer';
import {
  selectConversations,
  setCurrentNumTokens,
  setIsLoading,
  setIsScrolling,
} from '../redux/reducers/conversationReducer';
import { AppDispatch } from '../redux/store';
import {
  ChatContainer,
  ChatRow,
  drawerWidth,
  MainContainer,
  PageContainer,
  SendButton,
  StyledChatContainer,
} from '../theme/CustomComponents';
import { Message, MessageFile } from '../Types/conversation';
import { AppEnum, Llm, MessageFrom } from '../Types/enums';
import { Prompt } from '../Types/prompt';

const PrivateStyledAlert = styled(Alert)({
  marginBottom: '10px',
  marginTop: '15px',
  backgroundColor: 'black',
  color: 'white',
  '.MuiAlert-icon': { display: 'flex', alignItems: 'center' },
});

export type ChatProps = {
  gleanApplicationId?: string;
};

const PrivateChat = ({ gleanApplicationId }: ChatProps): JSX.Element => {
  const messageContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>();
  const { enqueueSnackbar } = useSnackbar();
  const [showErrorMessage, setShowErrorMessage] = useState('');
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState<boolean>(
    window.matchMedia('(min-width:960px)').matches
  );
  const [shouldShowInfo, setShouldShowInfo] = useState<boolean>(true);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<MessageFile[]>([]);
  const features = useFeatures();
  const [privateMessagesArray, setPrivateMessagesArray] = useState<Message[]>([]);

  const { currentUser } = useAuth();
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, isScrolling } = useSelector(selectConversations);
  const { llm } = useSelector(selectAppStatus);
  const shouldCancelRef = useRef(false);
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const { selectedApp } = useSelector(selectAppStatus);
  const isProgrammaticScroll = useRef(false);

  const addNewMessage = (newMessage: Message) => {
    setPrivateMessagesArray((prevMessages) => [...prevMessages, newMessage]);
  };

  const handleFileDelete = (index: number) => {
    setUploadedFiles((uploadedFiles) => {
      return [...uploadedFiles.slice(0, index), ...uploadedFiles.slice(index + 1)];
    });
  };

  const handleTextChange = (index: number, newText: string) => {
    setUploadedFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = { ...newFiles[index], contents: newText };
      return newFiles;
    });
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      enqueueSnackbar('No files selected.', { variant: 'error', autoHideDuration: 3000 });
      return;
    }

    const files = Array.from(e.target.files);

    // Clear target value to allow the same filename
    // to be uploaded in succession.
    e.target.value = '';

    files.forEach((file) => {
      // Validate file size before uploading to server.
      const fileSizeMB = file.size / 1024 ** 2;
      if (fileSizeMB > 100) {
        enqueueSnackbar(
          `Upload Failed. File size too large for file: ${file.name}. Max size: 100MB.`,
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        );
        return;
      }

      const currentIndex = filesToUpload.length;
      setFilesToUpload((filesToUpload) => [...filesToUpload, file]);

      (() => {
        const formData = new FormData();
        formData.append('file', file);
        try {
          dispatch(postFile(formData)).then((action: PayloadAction<MessageFile | unknown>) => {
            const messageFile = action.payload as MessageFile;

            if (messageFile.statusText) {
              setFilesToUpload((filesToUpload) => [
                ...filesToUpload.slice(0, currentIndex),
                ...filesToUpload.slice(currentIndex + 1),
              ]);
              enqueueSnackbar('Unable to process file or file is empty', {
                variant: 'error',
                autoHideDuration: 3000,
              });
              throw Error(messageFile.statusText);
            }

            if (messageFile.contents === '') {
              handleFileDelete(currentIndex);
            }

            setUploadedFiles((uploadedFiles) => [...uploadedFiles, messageFile]);

            setFilesToUpload((filesToUpload) => [
              ...filesToUpload.slice(0, currentIndex),
              ...filesToUpload.slice(currentIndex + 1),
            ]);
          });
        } catch (error) {
          handleFileDelete(currentIndex);
        }
      })();
    });
  };

  const handleCancel = () => {
    shouldCancelRef.current = true;
  };

  const handleStreamingResponse = useCallback(
    async (response: Response) => {
      let aiTokens = 0;
      if (response && response.body) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        const initBotMessage = {
          conversationId: '',
          from: MessageFrom.AI,
          message: '',
        } as Message;

        addNewMessage(initBotMessage);
        setUploadedFiles([]);

        let done, value;

        let result = '';

        do {
          ({ done, value } = await reader.read());

          if (shouldCancelRef.current) {
            await reader.cancel();
            dispatch(setIsScrolling(false));
            shouldCancelRef.current = false;
            setIsStreaming(false);
          }

          if (done) {
            setIsStreaming(false);
            break;
          }

          const chunk = decoder.decode(value, { stream: true });
          const convoIdIndex = chunk.indexOf('NIGEL CONVERSATION ID:');
          if (convoIdIndex >= 0) {
            chunk.slice(convoIdIndex + 23, convoIdIndex + 23 + 'private'.length);
            dispatch(setIsScrolling(false));
            continue;
          }
          const doneIndex = chunk.indexOf('NEW CONVERSATION ID:');
          const aiTokenIndex = chunk.indexOf('AI TOKENS:');

          if (aiTokenIndex !== -1) {
            const restOfString = chunk.slice(aiTokenIndex + 'AI TOKENS:'.length);
            aiTokens = parseInt(restOfString);
          }

          if (doneIndex < 0 && aiTokenIndex < 0) {
            result += chunk;
            setPrivateMessagesArray((messages) => {
              const newMessages = [...messages];
              newMessages[newMessages.length - 1] = {
                ...newMessages[newMessages.length - 1],
                message: result,
                tokens: 0, //needed to add this because otherwise it displays NaN for the token tracker while streaming
              };
              return newMessages;
            });
          }
        } while (!done);
        dispatch(setIsLoading(false));
        //add tokens to last AI message so that the token tracker displays the correct amount
        setPrivateMessagesArray((prevMessages) => {
          const newMessages = [...prevMessages];
          newMessages[newMessages.length - 1] = {
            ...newMessages[newMessages.length - 1],
            message: result,
            tokens: aiTokens,
            files: [],
          };
          return newMessages;
        });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;

      if (width < 1200) {
        setHistoryDrawerOpen(false);
      } else {
        setHistoryDrawerOpen(true);
      }
    }
    window.addEventListener('resize', handleResize);
  });

  const getChatPrompt = () => {
    if (inputRef.current) {
      return inputRef.current.value;
    }
    return '';
  };

  const handleSubmit = useCallback(
    (_prompt?: Prompt, input?: string) => {
      if (input) {
        dispatch(setIsLoading(true));
        const message = input;

        setPrivateMessagesArray([
          ...privateMessagesArray,
          {
            conversationId: 'privateStreaming',
            message,
            from: MessageFrom.USER,
            createdAt: '',
            id: '',
            deleted: false,
            tokens: 0,
            files: [],
          },
        ]);

        if (features.streaming && ![Llm.O1, Llm.O1_MINI].includes(llm as Llm)) {
          dispatch(
            getStreamingResponsePrivate({
              message: { message, files: [] },
              messageArray: privateMessagesArray,
              userId: currentUser?.id ?? '',
              llm,
            })
          ).then((action: PayloadAction<Response | unknown>) => {
            setIsStreaming(true);
            const response = action.payload as Response;
            handleStreamingResponse(response);
          });
        } else {
          dispatch(
            postMessagePrivate({
              message: { message: message, files: [] },
              messageArray: privateMessagesArray,
              llm,
              gleanApplicationId,
            })
          );
        }
      } else if (inputRef.current) {
        const ref = inputRef.current;

        if (ref.value === '' && uploadedFiles.length === 0) {
          setShowErrorMessage('Please enter your message!');
          setTimeout(() => {
            setShowErrorMessage('');
          }, 5000);
          return;
        }

        setIsStreaming(true);
        dispatch(setIsLoading(true));
        const message = getChatPrompt();

        const tempUploadedFiles = [...uploadedFiles];
        setUploadedFiles([]);
        ref.value = '';

        if (features.streaming) {
          try {
            const privateMessage = {
              conversationId: 'privateStreaming',
              message,
              from: MessageFrom.USER,
              createdAt: '',
              id: '',
              deleted: false,
              tokens: 0,
              files: tempUploadedFiles,
              azureCitations: '',
              citations: [],
            };
            addNewMessage(privateMessage);
            const updatedMessages = [...privateMessagesArray, privateMessage];

            dispatch(
              getStreamingResponsePrivate({
                message: { message, files: tempUploadedFiles },
                messageArray: updatedMessages,
                userId: currentUser?.id ?? '',
                llm,
              })
            ).then((action: PayloadAction<Response | unknown>) => {
              const response = action.payload as Response;
              handleStreamingResponse(response);
              setShouldShowInfo(false);
            });
          } catch (error) {
            // Remove last problematic message
            setPrivateMessagesArray((prevMessages) => {
              const tempMessages = [...prevMessages];
              tempMessages.pop();
              return [...tempMessages];
            });
          } finally {
            setUploadedFiles([]);
          }
        } else {
          try {
            const privateMessage = {
              conversationId: '',
              message,
              from: MessageFrom.USER,
              createdAt: '',
              id: '',
              deleted: false,
              tokens: 0,
              files: tempUploadedFiles,
              azureCitations: '',
              citations: [],
            };
            addNewMessage(privateMessage);
            const updatedMessages = [...privateMessagesArray, privateMessage];

            dispatch(
              postMessagePrivate({
                message: { message, files: tempUploadedFiles },
                messageArray: updatedMessages,
                llm,
                gleanApplicationId,
              })
            ).then((action: PayloadAction<Message | unknown>) => {
              const response = action.payload as Message;
              addNewMessage(response);
              setShouldShowInfo(false);
            });
          } catch (error) {
            // Remove last problematic message
            setPrivateMessagesArray((prevMessages) => {
              const tempMessages = [...prevMessages];
              tempMessages.pop();
              return [...tempMessages];
            });
          } finally {
            setUploadedFiles([]);
          }
        }
      }
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    [
      llm,
      uploadedFiles,
      gleanApplicationId,
      privateMessagesArray,
      currentUser?.id,
      features.streaming,
      handleStreamingResponse,
      dispatch,
    ]
  );

  useEffect(() => {
    const handleUserScroll = () => {
      if (!isProgrammaticScroll.current) {
        dispatch(setIsScrolling(true));
      }
    };

    const container = messageContainerRef.current;

    if (container) {
      container.addEventListener('scroll', handleUserScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleUserScroll);
      }
    };
  }, [dispatch, messageContainerRef]);

  const programmaticScroll = useCallback(() => {
    if (isLoading) {
      if (messageContainerRef.current && !isScrolling) {
        isProgrammaticScroll.current = true;
        messageContainerRef.current.scrollTo({
          top: messageContainerRef.current.scrollHeight,
        });
        setTimeout(() => {
          isProgrammaticScroll.current = false;
        }, 100);
      }
    } else {
      setTimeout(() => {
        if (messageContainerRef.current) {
          isProgrammaticScroll.current = true;
          messageContainerRef.current.scrollTo({
            top: messageContainerRef.current.scrollHeight,
            behavior: 'smooth',
          });
          setTimeout(() => {
            isProgrammaticScroll.current = false;
          }, 100);
        }
      }, 100);
    }
  }, [messageContainerRef, isLoading, isScrolling]);

  useEffect(() => {
    programmaticScroll();

    // set tokens based on the most recent message in the conversation where the ai is the sender
    const mostRecentAIMessages = privateMessagesArray.filter(
      (message: Message) => message.from === MessageFrom.AI
    );
    dispatch(
      setCurrentNumTokens(
        mostRecentAIMessages.length > 0
          ? mostRecentAIMessages[mostRecentAIMessages.length - 1].tokens
          : 0
      )
    );
  }, [dispatch, privateMessagesArray, programmaticScroll, isLoading, isScrolling]);

  return (
    <PageContainer>
      <NavDrawer
        drawerWidth={drawerWidth}
        drawerOpen={historyDrawerOpen}
        setDrawerOpen={setHistoryDrawerOpen}
        showConversationList={false}
      />
      <MainContainer open={historyDrawerOpen}>
        <NavBar mode={NavBarMode.NIGEL} />
        <div
          style={{ overflow: 'auto', width: '100%', minHeight: 'calc(100% - 64px)' }}
          ref={messageContainerRef}
        >
          <StyledChatContainer maxWidth="md">
            <div style={{ position: 'sticky', zIndex: '999', marginBottom: '10px', top: '0' }}>
              <PrivateStyledAlert icon={`🥸`}>
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontSize: '17px', fontWeight: 500, color: 'white' }}
                  >
                    {`This is a private chat. Your messages are not saved if you leave this page!`}
                  </Typography>
                </div>
              </PrivateStyledAlert>
            </div>
            <div
              style={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '20px',
              }}
            >
              {shouldShowInfo && (
                <>
                  <Typography
                    variant="h4"
                    sx={{ marginBottom: '10px', fontWeight: '700', fontSize: '36px' }}
                    align="left"
                  >
                    {`Welcome to Private Chat`}
                    <br />
                  </Typography>
                  <Typography variant="subtitle1" sx={{ fontSize: '16px' }} align="left">
                    Private Chat allows you to chat with Nigel without having to worry about the
                    conversation history being saved. <br />
                    This also means if you leave this page, you will lose all converstaion you have
                    in progress! <br />
                    Happy Chatting! 😊
                  </Typography>
                </>
              )}
            </div>
            <ChatContainer>
              {privateMessagesArray.length === 0 && (
                <ChatBubbleComponent
                  llm={llm}
                  key={'placeholder'}
                  message={{
                    from: MessageFrom.AI,
                    message:
                      'Start a new conversation with me by typing in the box or select an old conversation from the left! 😊',
                  }}
                  handleSubmit={handleSubmit}
                />
              )}
              {privateMessagesArray.map((message, index) => (
                <ChatBubbleComponent
                  llm={llm}
                  key={index}
                  message={message}
                  handleSubmit={handleSubmit}
                  privateChat={true}
                />
              ))}
              <div>
                {filesToUpload.map((fileToUpload: File, index: number) => (
                  <div key={index} style={{ padding: '5px' }}>
                    <FileCard
                      fileToUpload={fileToUpload}
                      handleFileDelete={() => {
                        handleFileDelete(index);
                      }}
                    />
                  </div>
                ))}
                {uploadedFiles.map((uploadedFile: MessageFile, index: number) => (
                  <div key={index} style={{ padding: '5px', paddingBottom: '20px' }}>
                    <FileCard
                      uploadedFile={uploadedFile}
                      handleFileDelete={() => {
                        handleFileDelete(index);
                      }}
                      handleTextChange={(newText) => {
                        handleTextChange(index, newText);
                      }}
                    />
                  </div>
                ))}
              </div>
              <ChatRow>
                <div style={{ paddingBottom: '20px', flexGrow: 1 }}>
                  <TextField
                    inputRef={(ref) => (inputRef.current = ref)}
                    variant="outlined"
                    label="Please enter a message"
                    InputProps={{
                      inputProps: {
                        style: { fontSize: '14px' },
                      },
                      endAdornment: (
                        <>
                          {features.file_upload_button && (
                            <InputAdornment position="start">
                              <Tooltip
                                title={
                                  <Typography variant="caption">
                                    {' '}
                                    Upload a document. 100MB limit.{' '}
                                  </Typography>
                                }
                              >
                                <IconButton component="label" style={{ left: '20px' }}>
                                  <input type="file" multiple hidden onChange={handleFileUpload} />
                                  <Attachment />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          )}
                        </>
                      ),
                    }}
                    multiline
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={!!showErrorMessage}
                    helperText={showErrorMessage}
                    autoFocus
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey && filesToUpload.length === 0) {
                        e.preventDefault();
                        handleSubmit();
                      }
                    }}
                    autoComplete="off"
                  />
                  {features.advanced_token_tracking_display && <LimitWarning />}
                </div>
                <div
                  style={{
                    // Roughly centered on the text field
                    marginTop: '7px',
                  }}
                >
                  <Tooltip
                    title={
                      <Typography variant="caption">{isLoading ? 'Cancel' : 'Send'}</Typography>
                    }
                  >
                    <span>
                      <SendButton
                        onClick={() => {
                          !isStreaming ? handleSubmit() : handleCancel();
                        }}
                        sx={{ color: isStreaming ? '#f52c00' : '#070154' }}
                        disabled={filesToUpload.length > 0 || (isLoading && !isStreaming)}
                      >
                        {selectedApp !== AppEnum.BIA &&
                        selectedApp !== AppEnum.DALLE &&
                        isStreaming ? (
                          <Stop />
                        ) : (
                          <Send />
                        )}
                      </SendButton>
                    </span>
                  </Tooltip>
                </div>
              </ChatRow>
            </ChatContainer>
          </StyledChatContainer>
        </div>
      </MainContainer>
    </PageContainer>
  );
};

export default PrivateChat;
