import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Container, styled, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';

import type { ProjectRunAnalysisQuestion } from '../../../../Types/dealGPT';
import { FeedbackInterface } from '../../../../Types/feedback';
import KPIs from './KPIs';

const Section = styled('div')({
  border: '1px solid #E9E9E9',
  padding: '5px',
  borderRadius: '2px',
});

const FilterContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '16px',
  marginTop: '-55px',

  [theme.breakpoints.up('sm')]: {
    padding: '0px',
  },
}));

type AnalysisQuestionFilterProps = {
  selected?: boolean;
};

const AnalysisQuestionFilter = styled(Typography)<AnalysisQuestionFilterProps>(
  ({ selected = false }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '125%',
    color: '#092B49',
    backgroundColor: selected ? '#ced7e6' : '#edf1f7',
    borderRadius: '100px',
    padding: '8px 16px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  })
);

interface KPIPageProps {
  pageQuestions?: ProjectRunAnalysisQuestion[];
  reprocessingTriggered: () => void;
  setIsLoadingThis: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoadingApplyChanges: React.Dispatch<React.SetStateAction<boolean>>;
  expandAll: boolean;
  handleFeedbackSubmit: (feedback: FeedbackInterface, projectAnalysisQuestionId: string) => void;
  handleValidateFindingsClick: (questionId: string) => void;
  getLatestUniqueQuestions: (
    questions: ProjectRunAnalysisQuestion[]
  ) => ProjectRunAnalysisQuestion[];
}

const KPIPage: React.FC<KPIPageProps> = ({
  pageQuestions,
  reprocessingTriggered,
  setIsLoadingThis,
  setIsLoadingApplyChanges,
  expandAll,
  handleFeedbackSubmit,
  handleValidateFindingsClick,
  getLatestUniqueQuestions,
}) => {
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const toggleFilters = (filter: string) => {
    setSelectedFilters((prevFilters) => {
      const index = prevFilters.indexOf(filter);
      return index > -1 ? prevFilters.filter((f) => f !== filter) : [...prevFilters, filter];
    });
  };

  const isFilterSelected = (filter: string) => {
    return selectedFilters.includes(filter);
  };

  const filteredResponses = useMemo(() => {
    const uniqueQuestions = pageQuestions ? getLatestUniqueQuestions(pageQuestions) : [];
    return uniqueQuestions
      .filter((response) => {
        if (selectedFilters.length === 0) return true;
        return (
          (selectedFilters.includes('missing') && !response.value) ||
          (selectedFilters.includes('partial') && response.value)
        );
      })
      .sort((a, b) => {
        if (!a.value && b.value) return -1;
        if (a.value && !b.value) return 1;
        return (a.displayOrder ?? 0) - (b.displayOrder ?? 0);
      });
  }, [pageQuestions, selectedFilters, getLatestUniqueQuestions]);

  const versionsMap = useMemo(() => {
    const map = new Map();
    pageQuestions?.forEach((question) => {
      const key = `${question.promptType}-${question.sequence}-${question.displayOrder}`;
      if (!map.has(key)) map.set(key, []);
      map.get(key).push(question);
    });
    return map;
  }, [pageQuestions]);

  const getVersionsForResponse = useCallback(
    (response: ProjectRunAnalysisQuestion) => {
      const key = `${response.promptType}-${response.sequence}-${response.displayOrder}`;
      return versionsMap.get(key) || [];
    },
    [versionsMap]
  );

  const handleClearFilters = () => {
    setSelectedFilters([]);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <FilterContainer maxWidth="lg" sx={{ marginBottom: '24px' }}>
          <AnalysisQuestionFilter
            key={'missing'}
            selected={isFilterSelected('missing')}
            onClick={() => toggleFilters('missing')}
          >
            {'No Answer Found'}
            {isFilterSelected('missing') && <CloseOutlinedIcon sx={{ fontSize: '16px' }} />}
          </AnalysisQuestionFilter>
          <AnalysisQuestionFilter
            key={'partial'}
            selected={isFilterSelected('partial')}
            onClick={() => toggleFilters('partial')}
          >
            {'Answer Must Validate'}
            {isFilterSelected('partial') && <CloseOutlinedIcon sx={{ fontSize: '16px' }} />}
          </AnalysisQuestionFilter>
          <Typography
            sx={{
              fontSize: '14px',
              alignSelf: 'center',
              color: '#00a3ff',
              cursor: 'pointer',
            }}
            onClick={() => {
              handleClearFilters();
            }}
          >
            Clear Filters
          </Typography>
        </FilterContainer>
      </div>
      {filteredResponses &&
        filteredResponses.map((response: ProjectRunAnalysisQuestion, index: number) => (
          <Section key={`${response.pageName}-${response.promptType}-${index}`}>
            <KPIs
              key={`${response.pageName}-${response.promptType}-${index}`}
              versions={getVersionsForResponse(response)}
              textfieldName={`KPI-response-${index}`}
              setIsLoadingThis={setIsLoadingThis}
              setIsLoadingApplyChanges={setIsLoadingApplyChanges}
              reprocessingTriggered={reprocessingTriggered}
              expandAll={expandAll}
              handleFeedbackSubmit={handleFeedbackSubmit}
              handleValidateFindingsClick={handleValidateFindingsClick}
            />
          </Section>
        ))}
    </div>
  );
};

export default KPIPage;
