import { DescriptionOutlined, EditNoteOutlined } from '@mui/icons-material';
import { styled, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { SourceDocument } from '../../../Types/dealGPT';
import { DealGPTView } from '../../../Types/enums';
import { AnalyticsEvent, useAnalytics } from '../../Providers/AnalyticsProvider';

const SourceItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginTop: '4px',
  color: '#1979CD',
});

const SourceLink = styled('a')({
  color: '#1979CD',
  textDecoration: 'none',

  '&:visited': {
    color: '#800080',
  },
});

interface ReferenceDocumentsProps {
  questionId: string;
  documents?: SourceDocument[] | null;
  showAnalysisText?: boolean;
  showReferenceTitle?: boolean;
  showValidationTitle?: boolean;
  siteNotes?: string[];
  view?: DealGPTView;
}

const ReferenceDocuments: React.FC<ReferenceDocumentsProps> = ({
  questionId,
  documents,
  showAnalysisText = false,
  showReferenceTitle = true,
  showValidationTitle = false,
  siteNotes = [],
  view = DealGPTView.ANALYSIS,
}) => {
  const [showingAll, setShowingAll] = useState(false);
  const [docsToShow, setDocsToShow] = useState(
    documents ? (documents?.length > 3 ? documents.slice(0, 2) : documents) : []
  );
  const analytics = useAnalytics();

  useEffect(() => {
    if (!documents) {
      setDocsToShow([]);
    } else {
      if (showingAll) {
        setDocsToShow(documents);
      } else {
        setDocsToShow(documents?.length > 2 ? documents.slice(0, 2) : documents);
      }
    }
  }, [documents, showingAll]);

  return (
    <div>
      {showReferenceTitle && (
        <Typography sx={{ fontSize: '14px', fontWeight: '700', color: '#757575' }}>
          REFERENCE DOCUMENT(S)
        </Typography>
      )}
      {showValidationTitle && (
        <Typography sx={{ fontSize: '14px', fontWeight: '700', color: '#757575' }}>
          VALIDATION DOCUMENT(S)
        </Typography>
      )}
      {(showAnalysisText || siteNotes.length > 0) && (
        <>
          <SourceItem sx={{ color: '#00a3ff' }}>
            <EditNoteOutlined sx={{ fontSize: '14px' }} />
            <Typography sx={{ fontWeight: '500', marginLeft: '8px', fontSize: '14px' }}>
              Site Visit Notes
            </Typography>
          </SourceItem>
          {siteNotes.map((notes, index) => (
            <SourceItem sx={{ color: '#00a3ff' }} key={notes + '-' + index}>
              <EditNoteOutlined sx={{ fontSize: '14px', marginLeft: '20px' }} />
              <Typography sx={{ fontWeight: '500', marginLeft: '8px', fontSize: '14px' }}>
                {notes}
              </Typography>
            </SourceItem>
          ))}
        </>
      )}
      {docsToShow.map((document, index) => (
        <div key={document.fileName + '-' + index}>
          {document.fileName === 'Notes' ? (
            <SourceItem>
              <EditNoteOutlined sx={{ fontSize: '14px' }} />
              <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{document.fileName}</div>}>
                <span>
                  <Typography
                    component="p"
                    sx={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      WebkitLineClamp: 1,
                      fontWeight: '450',
                      marginLeft: '8px',
                      fontSize: '14px',
                    }}
                  >
                    {document.fileName}
                  </Typography>
                </span>
              </Tooltip>
            </SourceItem>
          ) : (
            <SourceItem>
              <DescriptionOutlined sx={{ fontSize: '14px', color: '#00a3ff' }} />
              <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{document.fileName}</div>}>
                <span>
                  <SourceLink
                    key={document.gleanId ?? 'notes'}
                    href={document.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      const analyticsEvent =
                        view == DealGPTView.ANALYSIS
                          ? AnalyticsEvent.WM_IA_A_SOURCE_CLICK
                          : AnalyticsEvent.WM_IA_D_SOURCE_CLICK;
                      analytics.event(analyticsEvent, {
                        id: questionId,
                        value: document.fileName,
                      });
                    }}
                  >
                    <Typography
                      component="p"
                      sx={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: 1,
                        fontWeight: '450',
                        marginLeft: '8px',
                        fontSize: '14px',
                      }}
                    >
                      {document.fileName}
                    </Typography>
                  </SourceLink>
                </span>
              </Tooltip>
            </SourceItem>
          )}
        </div>
      ))}
      {documents && documents.length > 2 && (
        <Typography>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              const analyticsEvent =
                view == DealGPTView.ANALYSIS
                  ? AnalyticsEvent.WM_IA_A_SHOW_ALL_SOURCES
                  : AnalyticsEvent.WM_IA_D_SHOW_ALL_SOURCES;
              analytics.event(analyticsEvent, {
                id: questionId,
                value: (!showingAll).toString(),
              });
              setShowingAll(!showingAll);
            }}
            style={{
              color: '#757575',
              textDecoration: 'underline',
              verticalAlign: 'baseline',
              fontSize: '0.90rem',
              fontStyle: 'italic',
            }}
          >
            {showingAll ? 'Show Fewer' : 'Show All'}
          </a>
        </Typography>
      )}
    </div>
  );
};

export default ReferenceDocuments;
