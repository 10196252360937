import { CircularProgress, styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { getScopeAreas } from '../../../services/dealGPT';
import { ProjectScopeAreaType, ProjectType } from '../../../Types/dealGPT';
import { RunType } from '../../../Types/enums';
import ScopeAreaDetail from '../PhaseTwo/ScopeAreaDetail';

const ScopeAreaContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  backgroundColor: '#ffffff',
  justifyContent: 'flex-start',
});

const ScopeAreaListItemsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  '& > div:not(:last-child)': {
    borderBottom: '1px solid #C4C4C4',
  },
});

type AnalysisProps = {
  project: ProjectType;
};

const ScopeAreas = ({ project }: AnalysisProps): JSX.Element => {
  const [projectScopeAreas, setProjectScopeAreas] = useState<ProjectScopeAreaType[]>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchScopeAreas = async () => {
      const scopeAreasResponse = await getScopeAreas({
        projectType: project.projectType,
        projectPhase: RunType.ANALYSIS,
        projectId: project.id,
      });
      setProjectScopeAreas(scopeAreasResponse);
      setIsLoading(false);
    };

    if (!projectScopeAreas) {
      fetchScopeAreas();
    }
  }, [project.id, project.projectType, projectScopeAreas]);
  return (
    <ScopeAreaContainer>
      <div style={{ width: '100%' }}>
        <Typography sx={{ fontSize: '22px' }}>Scope Area</Typography>
      </div>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '20px',
            width: '100%',
            margin: '20px 0',
          }}
        >
          <CircularProgress sx={{ margin: 'auto' }} />
          <Typography align="center">
            This can occasionally take 20-30 seconds as our servers warm up, but will be fast after
            that. Thank you for your patience.
          </Typography>
        </div>
      ) : (
        <>
          <ScopeAreaListItemsContainer>
            {projectScopeAreas &&
              projectScopeAreas.length > 0 &&
              projectScopeAreas.map((scopeArea: ProjectScopeAreaType, index) => (
                <ScopeAreaDetail
                  key={scopeArea.scope + index}
                  scopeArea={scopeArea}
                  projectId={project.id}
                />
              ))}
          </ScopeAreaListItemsContainer>
          {!projectScopeAreas ||
            (projectScopeAreas.length === 0 && (
              <div style={{ marginTop: '15px' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: '400' }}>
                  No scope areas found
                </Typography>
              </div>
            ))}
        </>
      )}
    </ScopeAreaContainer>
  );
};

export default ScopeAreas;
