import {
  Key,
  NotInterestedOutlined,
  SkipNextOutlined,
  VerifiedOutlined,
} from '@mui/icons-material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import SyncRoundedIcon from '@mui/icons-material/SyncRounded';
import { styled, Tooltip } from '@mui/material';
import React, { ReactElement } from 'react';

import { DealGPTQuestionStatus } from '../../Types/enums';

const QuestionStatusContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '4px',
  padding: '4px 8px',
  border: '1.5px solid #070154',
});

type IconProps = {
  iconColor: string;
};

const Icon = styled('div')<IconProps>(({ iconColor }) => ({
  display: 'flex',
  alignItems: 'center',

  svg: {
    color: iconColor,
    fontSize: '16px',
  },
}));

const statusInfo = {
  [DealGPTQuestionStatus.ANSWERED]: {
    text: 'COMPLETE',
    icon: <CheckCircleOutlinedIcon />,
    iconColor: '#070154',
    bgColor: '#1dd566',
  },
  [DealGPTQuestionStatus.PENDING]: {
    text: 'PENDING',
    icon: <SyncRoundedIcon />,
    iconColor: '#070154',
    bgColor: '#00a3ff',
  },
  MORE_INFO_NEEDED: {
    text: 'PARTIAL',
    icon: <ReportProblemOutlinedIcon />,
    iconColor: '#070154',
    bgColor: '#ffc700',
  },
  [DealGPTQuestionStatus.ERROR]: {
    text: 'ERROR',
    icon: <ReportProblemOutlinedIcon />,
    iconColor: '#070154',
    bgColor: '#f52c00',
  },
  [DealGPTQuestionStatus.NOT_FOUND]: {
    text: 'MISSING',
    icon: <NotInterestedOutlined />,
    iconColor: '#070154',
    bgColor: '#f52c00',
  },
  [DealGPTQuestionStatus.SKIPPED]: {
    text: 'SKIPPED',
    icon: <SkipNextOutlined />,
    iconColor: '#070154',
    bgColor: '#8da3ba',
  },
  VALIDATED: {
    text: 'VALIDATED',
    icon: <VerifiedOutlined />,
    iconColor: '#070154',
    bgColor: '#f900d3',
  },
  RELATED: {
    text: 'KEY QUESTION',
    icon: <Key />,
    iconColor: '#070154',
    bgColor: '#00a3ff',
  },
};

type QuestionStatusProps = {
  statuses: string[];
};

const QuestionStatus = ({ statuses }: QuestionStatusProps): JSX.Element => {
  const generateQuestionStatusBanner = (status: string): ReactElement => {
    const { text, icon, iconColor, bgColor } = statusInfo[status as keyof typeof statusInfo];

    const statusElement = (
      <div key={status}>
        <Tooltip title={text}>
          <QuestionStatusContainer style={{ backgroundColor: bgColor }}>
            <Icon iconColor={iconColor}>{icon}</Icon>
          </QuestionStatusContainer>
        </Tooltip>
      </div>
    );

    return statusElement;
  };

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      {statuses && statuses.map((status) => generateQuestionStatusBanner(status))}
    </div>
  );
};

export default QuestionStatus;
