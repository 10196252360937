import { Button, MenuItem, styled, Typography } from '@mui/material';
import { TextField } from 'mui-rff';
import React, { useEffect, useRef } from 'react';
import { Form } from 'react-final-form';

import { ProjectConfigQuestion } from '../../../../Types/dealGPT';
import { AnalysisKPIQuestionType } from '../../../../Types/enums';

const FlexDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

const HeaderLabel = styled(Typography)({
  marginBottom: '10px',
  color: '#092B49',
  fontWeight: '700',
});

export type KeyQuestionProps = {
  question: ProjectConfigQuestion;
  handleDelete: () => void;
  handleUpdate: (question: ProjectConfigQuestion) => void;
};

const KpiQuestion = ({ question, handleDelete, handleUpdate }: KeyQuestionProps): JSX.Element => {
  const currentQuestionValue = useRef(question);

  useEffect(() => {
    currentQuestionValue.current = question;
  }, [question]);

  return (
    <FlexDiv>
      <Form
        onSubmit={() => {}}
        initialValues={{
          kpiQuestion: question.question,
          kpiType: question.kpiType,
        }}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <HeaderLabel>Enter in KPI Question:</HeaderLabel>
                <div>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: '10px' }}
                    onClick={() => {
                      handleUpdate({ ...question, question: values.kpiQuestion });
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      currentQuestionValue.current.question = '';
                      handleDelete();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                  <TextField name="kpiQuestion" multiline />
                  <TextField
                    select
                    required
                    name="kpiType"
                    sx={{
                      ml: '10px',
                      width: '100px',
                    }}
                  >
                    {Object.keys(AnalysisKPIQuestionType).map((type) => {
                      if (type === AnalysisKPIQuestionType.LOOKUP_LIST) return null;
                      return (
                        <MenuItem key={type} value={type} sx={{ padding: '10px !important' }}>
                          {type}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
            </div>
          </form>
        )}
      />
    </FlexDiv>
  );
};

export default KpiQuestion;
