import { Edit } from '@mui/icons-material';
import { Button, styled, Typography } from '@mui/material';
import { TextField } from 'mui-rff';
import React, { useState } from 'react';
import { Form } from 'react-final-form';

const FlexDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

const HeaderLabel = styled(Typography)({
  color: '#092B49',
  fontWeight: '700',
});

interface InvestmentThesisProps {
  investmentThesis: string;
  submitInvestmentThesis: (investmentThesis: string) => Promise<void>;
}

const InvestmentThesis: React.FC<InvestmentThesisProps> = ({
  investmentThesis,
  submitInvestmentThesis,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(investmentThesis == '');
  return (
    <FlexDiv>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Form
          onSubmit={() => {}}
          initialValues={{
            investmentThesis,
          }}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '200px',
                  height: '60px',
                }}
              >
                <HeaderLabel>Investment Thesis</HeaderLabel>
                {isEditing ? (
                  <Button
                    disabled={!isEditing}
                    variant="outlined"
                    onClick={() => {
                      setIsEditing(false);
                      submitInvestmentThesis(values.investmentThesis);
                    }}
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    startIcon={<Edit />}
                    onClick={() => setIsEditing(true)}
                  >
                    Edit
                  </Button>
                )}
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                {isEditing ? (
                  <TextField name="investmentThesis" multiline />
                ) : (
                  <Typography sx={{ whiteSpace: 'pre' }}>{investmentThesis}</Typography>
                )}
              </div>
            </form>
          )}
        />
      </div>
    </FlexDiv>
  );
};

export default InvestmentThesis;
