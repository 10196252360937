import { Add } from '@mui/icons-material';
import { Button, styled, Tooltip, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { softDeleteQuestion, validateFindingsAnalysis } from '../../../services/dealGPT';
import type {
  PageInfo,
  ProjectRunAnalysisQuestion,
  ValidateFindingsOptions,
} from '../../../Types/dealGPT';
import { AnalysisQuestionType } from '../../../Types/enums';
import { FeedbackInterface } from '../../../Types/feedback';
import { AnalyticsEvent, useAnalytics } from '../../Providers/AnalyticsProvider';
import { getLatestUniqueQuestions, sortQuestions } from '../utils';
import KPIsPage from './KPIs/KPIsPage';
import PromptAnswer from './PromptAnswer';
import QuestionAnswer from './QuestionAnswer';
import RubricPage from './Rubric/RubricPage';

const StepPageContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

const Section = styled('div')({
  '&:not(:first-of-type)': {
    borderTop: '1px solid #E9E9E9',
    paddingTop: '20px',
  },
});

interface StepPageProps {
  page: PageInfo;
  initialPageQuestions?: ProjectRunAnalysisQuestion[];
  pageQuestions?: ProjectRunAnalysisQuestion[];
  latestRunId?: string | null;
  projectId: string;
  setIsLoadingThis: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoadingApplyChanges: React.Dispatch<React.SetStateAction<boolean>>;
  reprocessingTriggered: () => void;
  handleFeedbackSubmit: (feedback: FeedbackInterface, projectAnalysisQuestionId: string) => void;
  setShouldFetchQuestions: React.Dispatch<React.SetStateAction<boolean>>;
}

const StepPage: React.FC<StepPageProps> = ({
  page,
  initialPageQuestions,
  pageQuestions,
  latestRunId,
  projectId,
  setIsLoadingThis,
  setIsLoadingApplyChanges,
  reprocessingTriggered,
  handleFeedbackSubmit,
  setShouldFetchQuestions,
}) => {
  const [responses, setResponses] = useState<ProjectRunAnalysisQuestion[]>([]);
  const [allVersions, setAllVersions] = useState<ProjectRunAnalysisQuestion[]>([]);
  const [highestQuestionIndex, setHighestQuestionIndex] = useState<number>(1);
  const [suggestSaveOrProcessAll, setSuggestSaveOrProcessAll] = useState<'save' | 'process' | null>(
    null
  );
  const [expandAllKpis, setExpandAllKpis] = useState<boolean>(false);
  const [expandAllRubric, setExpandAllRubric] = useState<boolean>(false);
  const analytics = useAnalytics();

  const handleAddQuestion = useCallback(() => {
    analytics.event(AnalyticsEvent.WM_IA_A_FR_ADD_QUESTION, { id: latestRunId?.toString() });
    if (initialPageQuestions) {
      const newQuestion: ProjectRunAnalysisQuestion =
        page.name === 'Flash Report'
          ? { ...initialPageQuestions[initialPageQuestions.length - 1] }
          : { ...initialPageQuestions[0] };
      newQuestion.displayOrder = highestQuestionIndex + 1;
      // console.log('newQuestion', newQuestion);
      setResponses([...responses, newQuestion]);
      setHighestQuestionIndex(highestQuestionIndex + 1);
    }
  }, [responses, highestQuestionIndex, initialPageQuestions, analytics, latestRunId, page.name]);

  const handleDeleteQuestion = async (questionIndex: number) => {
    analytics.event(AnalyticsEvent.WM_IA_A_FR_DELETE_QUESTION, { id: latestRunId?.toString() });
    const deletedQuestion = responses[questionIndex];

    // console.log('deletedQuestion', deletedQuestion);

    if (latestRunId && deletedQuestion.response) {
      const isDeleted = await softDeleteQuestion(deletedQuestion);
      if (isDeleted) {
        reprocessingTriggered();
      }
    } else {
      setResponses(responses.filter((_, index) => index !== questionIndex));
    }
  };

  const toggleCollapseExpandAllKpis = () => {
    analytics.event(AnalyticsEvent.WM_IA_A_KPI_EXPAND_ALL, {
      id: latestRunId?.toString(),
      value: `Scope=${page.scope},Expand=${!expandAllKpis}`,
    });
    setExpandAllKpis(!expandAllKpis);
  };

  const toggleCollapseExpandAllRubric = () => {
    analytics.event(AnalyticsEvent.WM_IA_A_RUBRIC_EXPAND_ALL, {
      id: latestRunId?.toString(),
      value: `Scope=${page.scope},Expand=${!expandAllRubric}`,
    });
    setExpandAllRubric(!expandAllRubric);
  };

  const handleValidateFindingsClick = useCallback(
    async (questionId: string) => {
      setIsLoadingThis(true);
      try {
        const questionObject = pageQuestions?.find((q) => q.id === questionId);

        //Getting Validate Findigs for current question.
        const options: ValidateFindingsOptions = {
          response: questionObject?.response ?? '',
          question: questionObject?.prettyQuestion ?? '',
          scope: page.scope ?? '',
        };
        analytics.event(AnalyticsEvent.WM_IA_A_VALIDATE, {
          id: questionObject?.id,
          value: `Scope=${page.scope}`,
        });
        const responseValidation = questionObject
          ? await validateFindingsAnalysis(options, questionObject.id, projectId)
          : undefined;
        if (responseValidation?._isSuccess) {
          if (responseValidation._data) {
            enqueueSnackbar('Validation complete.', { variant: 'info' });
            setShouldFetchQuestions(true);
          }
        } else {
          enqueueSnackbar(`Failed to validate findings`, {
            variant: 'error',
          });
        }
      } catch (error) {
        enqueueSnackbar(`Failed to validate findings: ${error}`, { variant: 'error' });
      }
    },
    [page.scope, projectId, pageQuestions, analytics, setIsLoadingThis, setShouldFetchQuestions]
  );

  const processThisPageButton = useMemo(
    () => (
      <Tooltip
        title={
          <div style={{ whiteSpace: 'pre-line' }}>
            {
              "Generate or regenerate an LLM output for every question on this page!\n\nDon't worry, you can go back to a previous version."
            }
          </div>
        }
      >
        <Button
          variant="outlined"
          onClick={async () => {
            analytics.event(AnalyticsEvent.WM_IA_A_GENERATE_PAGE, {
              id: latestRunId?.toString(),
              value: `Page=${page.name},Scope=${page.scope}`,
            });
            setSuggestSaveOrProcessAll('process');
            await new Promise((resolve) => setTimeout(resolve, 1000));
            setSuggestSaveOrProcessAll(null);
          }}
        >
          Generate This Page
        </Button>
      </Tooltip>
    ),
    [setSuggestSaveOrProcessAll, analytics, latestRunId, page.name, page.scope]
  );

  useEffect(() => {
    if (responses.length === 0) {
      handleAddQuestion();
    }
  }, [responses, handleAddQuestion]);

  useEffect(() => {
    // console.log('initial page questions', initialPageQuestions);
    // console.log('page questions', pageQuestions);

    // Clear these out when switching pages
    setResponses([]);
    setAllVersions([]);

    if (pageQuestions && pageQuestions.length > 0) {
      // console.log('all versions', page.name, pageQuestions);
      setAllVersions(pageQuestions);

      const sortedUniqueQuestions = sortQuestions(getLatestUniqueQuestions(pageQuestions));
      // console.log('sortedUniqueQuestions', sortedUniqueQuestions);
      setResponses(sortedUniqueQuestions);
      if (
        Number(sortedUniqueQuestions[sortedUniqueQuestions.length - 1].displayOrder) &&
        sortedUniqueQuestions[0].type === AnalysisQuestionType.QUESTION_AND_ANSWER
      ) {
        setHighestQuestionIndex(
          Number(sortedUniqueQuestions[sortedUniqueQuestions.length - 1].displayOrder)
        );
      }
    } else if (
      initialPageQuestions &&
      initialPageQuestions.length > 0 &&
      initialPageQuestions[0].type === AnalysisQuestionType.QUESTION_AND_ANSWER
    ) {
      // if (initialPageQuestions.length === 1) {
      const tempQuestions = initialPageQuestions.map((q) => {
        if (q.prettyQuestion) {
          q.userInput = q.prettyQuestion;
        }
        return q;
      });
      const sortedQuestions = sortQuestions(tempQuestions);

      setResponses(sortedQuestions);
    }
  }, [initialPageQuestions, pageQuestions, page.name]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <StepPageContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h2" sx={{ fontSize: '24px' }}>
            {page.name}
          </Typography>
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          {responses &&
            responses.some(
              (response) => response.type === AnalysisQuestionType.QUESTION_AND_ANSWER
            ) &&
            processThisPageButton}
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          {responses &&
            responses.some((response) => response.type === AnalysisQuestionType.KPI) && (
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#1979CD',
                  cursor: 'pointer',
                  marginBottom: '16px',
                  alignSelf: 'flex-end',
                }}
                onClick={toggleCollapseExpandAllKpis}
              >
                {expandAllKpis ? 'Collapse all' : 'Expand all'}
              </Typography>
            )}
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          {responses &&
            responses.some((response) => response.type === AnalysisQuestionType.RUBRIC) && (
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#1979CD',
                  cursor: 'pointer',
                  marginBottom: '16px',
                  alignSelf: 'flex-end',
                }}
                onClick={toggleCollapseExpandAllRubric}
              >
                {expandAllRubric ? 'Collapse all' : 'Expand all'}
              </Typography>
            )}
        </div>
        <div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            {responses &&
              page.name !== 'Rubric' &&
              responses.map((response, index) => {
                if (response.type === AnalysisQuestionType.QUESTION_AND_ANSWER) {
                  return (
                    <Section key={`${response.pageName}-${response.promptType}-${index}`}>
                      <QuestionAnswer
                        index={index}
                        responseObject={response}
                        versions={allVersions.filter(
                          (v) =>
                            v.promptType === response.promptType &&
                            v.sequence === response.sequence &&
                            v.displayOrder === response.displayOrder
                        )}
                        setIsLoadingThis={setIsLoadingThis}
                        setIsLoadingApplyChanges={setIsLoadingApplyChanges}
                        reprocessingTriggered={reprocessingTriggered}
                        handleDelete={handleDeleteQuestion}
                        suggestSaveOrProcessAll={suggestSaveOrProcessAll}
                        setSuggestSaveOrProcessAll={setSuggestSaveOrProcessAll}
                        handleFeedbackSubmit={handleFeedbackSubmit}
                        handleValidateFindingsClick={handleValidateFindingsClick}
                      />
                    </Section>
                  );
                } else if (response.type === AnalysisQuestionType.PROMPT_ANSWER) {
                  return (
                    <Section key={`${response.pageName}-${response.promptType}-${index}`}>
                      <PromptAnswer
                        versions={allVersions.filter(
                          (v) =>
                            v.promptType === response.promptType &&
                            v.sequence === response.sequence &&
                            v.displayOrder === response.displayOrder
                        )}
                        textfieldName={`PA-response-${index}`}
                        setIsLoadingThis={setIsLoadingThis}
                        setIsLoadingApplyChanges={setIsLoadingApplyChanges}
                        reprocessingTriggered={reprocessingTriggered}
                        timeToProcess={
                          page.name === 'Rubric Summary' ? '5-10 minutes' : '1-2 minutes'
                        }
                        handleFeedbackSubmit={handleFeedbackSubmit}
                      />
                    </Section>
                  );
                }
              })}
            {responses?.some(
              (response) => response.type === AnalysisQuestionType.QUESTION_AND_ANSWER
            ) && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '16px',
                }}
              >
                <Tooltip
                  title={<div style={{ whiteSpace: 'pre-line' }}>{'Add another question'}</div>}
                >
                  <Button variant="outlined" onClick={handleAddQuestion} sx={{ width: '100px' }}>
                    <Add /> Add
                  </Button>
                </Tooltip>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                  {processThisPageButton}
                </div>
              </div>
            )}
            {responses && page.name.includes('KPIs') && (
              <KPIsPage
                pageQuestions={pageQuestions}
                reprocessingTriggered={reprocessingTriggered}
                setIsLoadingThis={setIsLoadingThis}
                setIsLoadingApplyChanges={setIsLoadingApplyChanges}
                expandAll={expandAllKpis}
                handleFeedbackSubmit={handleFeedbackSubmit}
                getLatestUniqueQuestions={getLatestUniqueQuestions}
                handleValidateFindingsClick={handleValidateFindingsClick}
              />
            )}
            {responses && page.name.includes('Rubric') && page.name !== 'Rubric Summary' && (
              <RubricPage
                pageQuestions={pageQuestions}
                reprocessingTriggered={reprocessingTriggered}
                setIsLoadingThis={setIsLoadingThis}
                setIsLoadingApplyChanges={setIsLoadingApplyChanges}
                expandAll={expandAllRubric}
                handleFeedbackSubmit={handleFeedbackSubmit}
                handleValidateFindingsClick={handleValidateFindingsClick}
              />
            )}
          </div>
        </div>
      </StepPageContainer>
    </div>
  );
};

export default StepPage;
