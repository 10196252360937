import { Button, Container, List, styled, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  getLatestRun,
  getRunStatus,
  getScopeQuestions,
  updateAnalysisQuestionFeedback,
} from '../../../services/dealGPT';
import {
  InitialQuestionCount,
  PageInfo,
  PageProgress,
  ProjectRunAnalysisQuestion,
  ProjectType,
} from '../../../Types/dealGPT';
import { AnalysisQuestionType, RunType } from '../../../Types/enums';
import { FeedbackInterface } from '../../../Types/feedback';
import { AnalyticsEvent, useAnalytics } from '../../Providers/AnalyticsProvider';
import LoadingMask from '../LoadingMask';
import AnalysisNavItem from '../PhaseTwo/AnalysisNavItem';
import Notes from '../PhaseTwo/Notes';
import StepPage from '../PhaseTwo/StepPage';

type AnalysisProps = {
  project: ProjectType;
};

const NavList = styled(List)({
  display: 'flex',
  gap: '2px',
  marginBottom: '24px',
});

const Analysis = ({ project }: AnalysisProps): JSX.Element => {
  const scope = useParams<{ scope?: string }>().scope || '';
  const [pages, setPages] = useState<PageInfo[]>([]);
  const [shouldFetchQuestions, setShouldFetchQuestions] = useState<boolean>(true);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [highestStepIndex, setHighestStepIndex] = useState<number>(0);
  const [latestRunId, setLatestRunId] = useState<string>();
  const [initialQuestions, setInitialQuestions] = useState<ProjectRunAnalysisQuestion[]>();
  const [questions, setQuestions] = useState<ProjectRunAnalysisQuestion[]>();
  const [isLoadingThis, setIsLoadingThis] = useState<boolean>(false);
  const [isLoadingApplyChanges, setIsLoadingApplyChanges] = useState<boolean>(false);
  const [allQuestions, setAllQuestions] = useState<ProjectRunAnalysisQuestion[]>();
  const [isScanning, setIsScanning] = useState<boolean>(false);
  const [isScanCompleted, setIsScanCompleted] = useState<boolean>(false);
  const [pageProgress, setPageProgress] = useState<PageProgress[]>([]);
  const navigate = useNavigate();
  const initialPageQuestionCountRef = useRef<InitialQuestionCount[]>([]);
  const analytics = useAnalytics();

  const handleNextPage = () => {
    const event =
      stepIndex == pages.length - 1
        ? AnalyticsEvent.WM_IA_A_FINISH_CLICK
        : AnalyticsEvent.WM_IA_A_NEXT_CLICK;
    analytics.event(event, {
      id: project.id,
      value: pages[stepIndex].name,
    });
    setStepIndex(stepIndex + 1);

    if (stepIndex + 1 > highestStepIndex) {
      setHighestStepIndex(stepIndex + 1);
    }

    if (stepIndex == pages.length - 1) {
      navigate(`/intellio-advantage/${project.id}/scope-areas`);
    }
  };

  const handlePreviousPage = () => {
    analytics.event(AnalyticsEvent.WM_IA_A_PREVIOUS_CLICK, {
      id: project.id,
      value: pages[stepIndex].name,
    });
    setStepIndex(stepIndex - 1);
  };

  const reprocessingTriggered = () => {
    console.log('reprocessing');
    setShouldFetchQuestions(true);
    initialPageQuestionCountRef.current = [];
  };

  const calculateProgress = (
    allQuestions: ProjectRunAnalysisQuestion[],
    initCounts: InitialQuestionCount[],
    pages: PageInfo[]
  ): PageProgress[] => {
    return pages.map((page) => {
      // Set initial values
      const initValuePage =
        initCounts.find((initCount) => initCount.pageName === page.name)?.initial ?? 0;
      const answeredValuePage =
        initCounts.find((initCount) => initCount.pageName === page.name)?.answered ?? 0;

      // Set new answered values
      const totalAnswered = allQuestions.filter(
        (question) =>
          (question.status === 'ANSWERED' || question.status === 'INPUT_PENDING') &&
          question.pageName === page.name
      ).length;

      // Calculate progress
      let progress =
        initValuePage > 0
          ? Math.floor(((totalAnswered - answeredValuePage) / initValuePage) * 100)
          : 0;
      progress = Math.max(progress, 0);
      progress = Math.min(progress, 100);

      // Return the progress for this page
      return {
        pageName: page.name,
        progress: progress,
      };
    });
  };

  useEffect(() => {
    if (initialQuestions?.length && initialQuestions.length > 0) {
      // TODO add extra logic to this map to handle whether or not its loading or not
      // see if at least one question in questions is pending or something like that
      // console.log('initialQuestions', initialQuestions);

      // get distinct page names from initialQuestions
      const distinctPageNames = Array.from(
        new Set(initialQuestions?.map((question) => question.pageName))
      );

      setPages(
        distinctPageNames?.map((name, index) => ({
          name,
          sequence: index,
        })) || []
      );
    }
  }, [initialQuestions]);

  useEffect(() => {
    const checkLatestRun = async () => {
      const runResponse = await getLatestRun({
        projectId: project.id,
        scope,
        runType: RunType.ANALYSIS,
      });

      if (runResponse._data) {
        setLatestRunId(runResponse._data.id);
      }
    };

    let interval: NodeJS.Timer;
    const intervalTime = 3000;

    // Get all scope questions, including all previous versions
    const fetchAllScopeQuestions = async () => {
      await checkLatestRun();

      if (!latestRunId) {
        return;
      }

      console.log(`fetching questions for runId ${latestRunId}, scope ${scope}`);

      const response = await getScopeQuestions({
        projectId: project.id,
        scope,
        runId: latestRunId,
      });

      if (response._data) {
        console.log('questions', response._data);

        setAllQuestions(response._data);
        setInitialQuestions(response._data.filter((question) => question.status === 'INITIAL'));
        setLatestRunId(response._data[0].runId);
        // Determine if the run is complete
        const status = await getRunStatus(project.id, scope, response._data[0].runId);

        if (!status.toLowerCase().startsWith('currently scanning')) {
          setQuestions(
            response._data.filter(
              (question) => question.status === 'ANSWERED' || question.status === 'INPUT_PENDING'
            )
          );
          setShouldFetchQuestions(false);
          setIsLoadingThis(false);
          setIsLoadingApplyChanges(false);
          clearInterval(interval);
          setIsScanning(false);
          setIsScanCompleted(true);
          initialPageQuestionCountRef.current = [];
        } else {
          setIsScanning(true);
          setIsScanCompleted(false);
        }
      }
    };
    if (shouldFetchQuestions) {
      setIsLoadingThis(true);
      setIsLoadingApplyChanges(true);
      fetchAllScopeQuestions();
      interval = setInterval(() => fetchAllScopeQuestions(), intervalTime);
    }

    return () => {
      clearInterval(interval);
    };
  }, [project.id, scope, latestRunId, shouldFetchQuestions, navigate]);

  const initialPageQuestions = useMemo(() => {
    if (initialQuestions && pages.length > 0) {
      return initialQuestions.filter((question) => question.pageName === pages[stepIndex].name);
    }
  }, [initialQuestions, pages, stepIndex]);

  const pageQuestions = useMemo(() => {
    if (questions && pages.length > 0) {
      return questions.filter((question) => question.pageName === pages[stepIndex].name);
    }
  }, [questions, pages, stepIndex]);

  const initPageQuestionCount: InitialQuestionCount[] = useMemo(() => {
    if (isScanning) {
      if (allQuestions) {
        const progress = calculateProgress(
          allQuestions,
          initialPageQuestionCountRef.current,
          pages
        );
        setPageProgress(progress);
      }
      if (initialPageQuestionCountRef.current.length > 0) {
        return initialPageQuestionCountRef.current;
      }

      if (!allQuestions || allQuestions.length === 0) {
        return [];
      }
      const newCounts = pages.map((page) => {
        const totalInitial = allQuestions.filter(
          (question) => question.status === 'INITIAL' && question.pageName === page.name
        ).length;
        const totalAnswered = allQuestions.filter(
          (question) =>
            (question.status === 'ANSWERED' || question.status === 'INPUT_PENDING') &&
            question.pageName === page.name
        ).length;

        return {
          pageName: page.name,
          initial: totalInitial,
          answered: totalAnswered,
        };
      });

      // Update the ref only if it's the first time
      if (initialPageQuestionCountRef.current.length === 0) {
        initialPageQuestionCountRef.current = newCounts;
      }
      return newCounts;
    } else {
      return initialPageQuestionCountRef.current;
    }
  }, [allQuestions, pages, isScanning]);
  //log for debug
  console.log('initPageQuestionCount', initPageQuestionCount);

  const handleFeedbackSubmit = async (
    feedback: FeedbackInterface,
    projectAnalysisQuestionId: string
  ) => {
    analytics.event(AnalyticsEvent.WM_IA_A_FEEDBACK_CLICK, {
      id: projectAnalysisQuestionId,
      value: `Page=${pages[stepIndex].name},Feedback=${feedback.thumbsUp}`,
    });
    await updateAnalysisQuestionFeedback(projectAnalysisQuestionId, feedback);

    setQuestions(
      (prevQuestions) =>
        prevQuestions?.map((question) =>
          question.id === projectAnalysisQuestionId
            ? {
                ...question,
                analysisQuestionFeedback: feedback,
              }
            : question
        ) || []
    );
  };

  return (
    <div>
      <div style={{ marginBottom: '20px', display: 'flex' }}>
        <Typography variant="h2" sx={{ color: 'grey', paddingRight: '10px', fontSize: '24px' }}>
          Scope:
        </Typography>
        <Typography variant="h2" sx={{ fontSize: '24px' }}>
          {scope}
        </Typography>
      </div>
      <NavList>
        {pages.map((page, index) => (
          <AnalysisNavItem
            index={index}
            stepIndex={stepIndex}
            highestStepIndex={highestStepIndex}
            pageName={page.name}
            setStepIndex={setStepIndex}
            setHighestStepIndex={setHighestStepIndex}
            key={page.name}
            isLoadingThis={isLoadingThis}
            isLoadingApplyChanges={isLoadingApplyChanges}
            pageQuestions={questions?.filter((question) => question.pageName === page.name) ?? []}
            isScanning={isScanning}
            isScanCompleted={isScanCompleted}
            progress={pageProgress.find((p) => p.pageName === page.name)?.progress || 0}
          />
        ))}
      </NavList>
      <Container
        sx={{
          backgroundColor: '#ffffff',
          padding: '32px',
        }}
      >
        {stepIndex > 0 && (
          <Button
            variant="outlined"
            onClick={handlePreviousPage}
            disabled={
              questions?.every(
                (question) => question.status === 'INITIAL' || question.status === 'INPUT_PENDING'
              ) || isLoadingThis
            }
            style={{ position: 'absolute', right: '130px', zIndex: 10 }}
          >
            Previous Page
          </Button>
        )}
        {stepIndex !== pages.length - 1 && (
          <Button
            variant="contained"
            onClick={handleNextPage}
            disabled={
              questions?.every(
                (question) => question.status === 'INITIAL' || question.status === 'INPUT_PENDING'
              ) || isLoadingThis
            }
            style={{ position: 'absolute', right: '24px', zIndex: 10 }}
          >
            Next Page
          </Button>
        )}
        {stepIndex == pages.length - 1 && (
          <Button
            variant="contained"
            onClick={handleNextPage}
            disabled={
              questions?.every(
                (question) => question.status === 'INITIAL' || question.status === 'INPUT_PENDING'
              ) || isLoadingThis
            }
            style={{ minWidth: '97px', position: 'absolute', right: '24px', zIndex: 10 }}
          >
            Finish
          </Button>
        )}
        {/* ASSUMPTION - notes will always be the first step */}
        {stepIndex === 0 && (
          <Notes
            project={project}
            scope={scope ?? ''}
            question={initialQuestions?.find(
              (question) => question.type === AnalysisQuestionType.NOTES
            )}
            reprocessingTriggered={reprocessingTriggered}
            latestRunId={latestRunId || undefined}
            firstRun={
              questions?.every(
                (question) => question.status === 'INITIAL' || question.status === 'INPUT_PENDING'
              ) || isLoadingThis
            }
            isParentLoading={isLoadingThis}
          />
        )}
        {stepIndex > 0 && (
          <StepPage
            page={pages[stepIndex]}
            initialPageQuestions={initialPageQuestions}
            pageQuestions={pageQuestions}
            latestRunId={latestRunId}
            projectId={project.id}
            setIsLoadingThis={setIsLoadingThis}
            setIsLoadingApplyChanges={setIsLoadingApplyChanges}
            reprocessingTriggered={reprocessingTriggered}
            handleFeedbackSubmit={handleFeedbackSubmit}
            setShouldFetchQuestions={setShouldFetchQuestions}
          />
        )}
      </Container>
      <LoadingMask isLoading={isLoadingThis} />
    </div>
  );
};

export default Analysis;
