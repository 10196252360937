import { OpenInNew } from '@mui/icons-material';
import { styled, Typography } from '@mui/material';
import React from 'react';

const StyledLink = styled('a')({
  color: 'inherit',
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline',
  },
});

const OpenInNewIcon = (
  <OpenInNew sx={{ fontSize: '20px', marginRight: '2px', verticalAlign: 'text-top' }} />
);

const Announcements: React.FC = () => {
  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{
          height: '20px',
          color: '#0047FF',
          margin: 0,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        1,000,000th message!
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
        <div style={{ height: '30%', marginBottom: '10px' }}>
          <Typography variant="body2">
            🎉 We recently hit 1,000,000 messages in Nigel
            <br />
            Congratulations to <b>Sydnee Vallee</b> for sending the millionth message! Here&apos;s
            to another million messages, or more, in 2025!
          </Typography>
        </div>
        <div style={{ height: '30%' }}>
          <Typography variant="body2">
            📝 Take the AI tool usage survey!{' '}
            <StyledLink
              href={`https://forms.office.com/pages/responsepage.aspx?id=5Tuiws_ScE6Ezhit-H1XH-hY7Y0lwzxDsg59Ml3pVa1UQzNBUlBPTjFOMEZSNjBCVVI1MENIVk5ISi4u&route=shorturl`}
              target="_blank"
            >
              {OpenInNewIcon}
              <b>HERE</b>
            </StyledLink>
            <br />
            Our leaders want everyone to feel empowered to leverage AI, whether it&apos;s making
            work more efficient or driving impact for clients. Take the AI tool usage survey HERE
            before <b>Friday, January 31 EOD</b>. Three random participants will be chosen to win 50
            points in Shout Out!
          </Typography>
        </div>
        <div
          style={{
            height: '120px',
            marginTop: '10px',
            marginBottom: '10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        ></div>
      </div>
    </>
  );
};

export default Announcements;
