import { ArrowBack } from '@mui/icons-material';
import { Button, CircularProgress, Container, IconButton, styled, Typography } from '@mui/material';
import { StatusCodes } from 'http-status-codes';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { getAllProjectRuns, getProject, getScopeAreas, startRun } from '../../../services/dealGPT';
import { StyledTab, StyledTabs } from '../../../theme/CustomComponents';
import type { ProjectRun, ProjectType, RunProjectOptions } from '../../../Types/dealGPT';
import { DealGPTView, RunType, UserRole } from '../../../Types/enums';
import DealGPTContainer from '../../DealGPT/ViewModels/DealGPTContainer';
import { AnalyticsEvent, useAnalytics } from '../../Providers/AnalyticsProvider';
import { useAuth } from '../../Providers/AuthProvider';
import { setPageTitle } from '../PageTitle';
import Analysis from './Analysis';
import DealGPTChat from './DealGPTChat';
import Discovery from './Discovery';
import ScopeAreas from './ScopeAreas';

const ProjectContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  marginBottom: '100px',
  height: '100%',
});

const TitleContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

export type DealGPTProjectContainerProps = { selectedView: DealGPTView };

const DealGPTProjectContainer = ({ selectedView }: DealGPTProjectContainerProps): JSX.Element => {
  const [project, setProject] = useState<ProjectType>();
  const [isLoadingRescan, setIsLoadingRescan] = useState<boolean>(false);
  const [projectRunStatus, setProjectRunStatus] = useState<string>();
  const [timer, setTimer] = useState<string | null>(null);
  const [currentProjectId, setCurrentProjectId] = useState<string>();
  const [view, setView] = useState<DealGPTView>(DealGPTView.DISCOVERY);
  const [projectRuns, setProjectRuns] = useState<ProjectRun[]>();
  const [selectedRun, setSelectedRun] = useState<ProjectRun>();
  const [distinctScopes, setDistinctScopes] = useState<string[]>([]);
  const [openFilterDialog, setOpenFilterDialog] = useState<boolean>(false);
  const [selectedScopePreferencesFilters, setSelectedScopePreferencesFilters] = useState<string[]>(
    []
  );
  const [isLoadingScopesDialog, setIsLoadingScopesDialog] = useState<boolean>(false);
  const [isSelectAllFilter, setIsSelectAllFilter] = useState(true);

  const { projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const analytics = useAnalytics();

  // const rawScope = useParams<{ scope?: string }>().scope;

  // const scope = rawScope && rawScope.trim() !== '' ? `${rawScope} Analysis` : 'Analysis';

  // "Currently Scanning"
  // "Completed Successfully"

  // "Failed: User is not a member of the team associated with the project"
  // "Failed: No questions found in the reporting database"
  // "Unexpected Failure: " + error

  const handleRescanDocuments = useCallback(
    async (projectId: string) => {
      setIsLoadingRescan(true);
      try {
        enqueueSnackbar(
          'Rescanning documents. You may continue to use the app, this will take ~20 minutes.',
          { variant: 'info' }
        );

        //Pass Array of selected scope areas to startRun on those.
        const options = { scopes: selectedScopePreferencesFilters } as RunProjectOptions;
        const newRun = await startRun(projectId, options);
        setSelectedRun(newRun);
      } catch (error) {
        enqueueSnackbar(`Failed to kickoff rescan: ${error}`, { variant: 'error' });
      }
      setIsLoadingRescan(false);
    },
    [selectedScopePreferencesFilters]
  );

  const handleCloseFilterDialog = () => {
    setOpenFilterDialog(false);
  };

  const handleOpenFilterDialog = async () => {
    if (project) {
      setOpenFilterDialog(true);
      setIsLoadingScopesDialog(true);
      try {
        const responseScopes = await getScopeAreas({
          projectType: project.projectType,
          projectPhase: RunType.DISCOVERY,
        });

        setDistinctScopes(responseScopes.map((scope) => scope.scope));
      } catch (error) {
        enqueueSnackbar(`Failed to kickoff rescan: ${error}`, { variant: 'error' });
        handleCloseFilterDialog();
      }
      setIsLoadingScopesDialog(false);
    }
  };

  const isScopePreferenceSelected = (scope: string) => {
    return selectedScopePreferencesFilters.includes(scope);
  };

  // Toggle for each Scope Area in modal
  const toggleFilter = (scope: string) => {
    if (isScopePreferenceSelected(scope)) {
      setSelectedScopePreferencesFilters((prevFilters) =>
        prevFilters.filter((prevScope) => prevScope !== scope)
      );
    } else {
      setSelectedScopePreferencesFilters([...selectedScopePreferencesFilters, scope]);
    }
  };

  //Toggle Select/Clear all scope area filters in modal.
  const toggleSelectAllScopeFilter = () => {
    if (isSelectAllFilter) {
      setSelectedScopePreferencesFilters(distinctScopes);
    } else {
      setSelectedScopePreferencesFilters([]);
    }
    setIsSelectAllFilter(!isSelectAllFilter);
  };

  const handleEditProjectClick = async () => {
    if (projectId) {
      navigate(`/intellio-advantage/edit-project/${projectId}`);
    }
  };

  const handleConfigureProjectClick = async () => {
    if (projectId) {
      navigate(`/intellio-advantage/configure-project/${projectId}`);
    }
  };

  const handleViewChange = (event: React.SyntheticEvent, newView: DealGPTView) => {
    setView(newView);
    analytics.event(AnalyticsEvent.WM_IA_TAB_VIEW, {
      id: projectId,
      value: newView,
    });
    if (projectId) {
      if (newView === DealGPTView.DISCOVERY) {
        navigate(`/intellio-advantage/${projectId}`);
      }
      if (newView === DealGPTView.CHAT) {
        navigate(`/intellio-advantage/${projectId}/chat`);
      }
    }
  };

  // Update Page title with current project name
  useEffect(() => {
    const projectName = project?.name;
    setPageTitle({ projectId, projectName });
  }, [project, projectId]);

  useEffect(() => {
    let intervalTimer: NodeJS.Timeout;

    const fetchProject = async () => {
      if (projectId && !isLoadingRescan) {
        const projectResponse = await getProject(
          projectId,
          currentUser?.roles?.includes(UserRole.DEALGPT_ADMIN)
        );

        if (projectResponse._isSuccess) {
          if (projectResponse._data) {
            setProject(projectResponse._data);
            setProjectRunStatus(projectResponse._data.projectRunStatus);

            if (
              projectResponse._data.projectRunStatus?.toLowerCase().startsWith('currently scanning')
            ) {
              setTimer(new Date(Date.now()).toLocaleString());
            } else {
              setTimer(null);
            }
          }
        } else {
          enqueueSnackbar(`Failed to fetch project: ${projectResponse._error}`, {
            variant: 'error',
          });

          if (projectResponse._statusCode === StatusCodes.UNAUTHORIZED) {
            navigate('/intellio-advantage', { replace: true });
          }
        }
      }
    };

    // Fetch project every minute and a half
    if (projectRunStatus?.startsWith('Currently Scanning')) {
      fetchProject();
      intervalTimer = setInterval(fetchProject, 90000);
    }

    if (
      !projectRunStatus ||
      projectRunStatus === 'Completed Successfully' ||
      projectId !== currentProjectId
    ) {
      fetchProject();
      setTimer(null);
      return () => clearInterval(intervalTimer);
    }

    if (projectId !== currentProjectId) {
      setCurrentProjectId(projectId);
    }
  }, [
    projectId,
    isLoadingRescan,
    projectRunStatus,
    currentProjectId,
    navigate,
    currentUser?.roles,
  ]);

  useEffect(() => {
    if (location.pathname.includes('analysis') || location.pathname.includes('scope-areas')) {
      setView(DealGPTView.ANALYSIS);
    }
    if (location.pathname.includes('chat')) {
      setView(DealGPTView.CHAT);
    }
  }, [location, view, setView]);

  useEffect(() => {
    if (project) {
      const handleGetProjectRuns = async () => {
        try {
          const projectRuns = await getAllProjectRuns(project.id, 'DISCOVERY');

          if (projectRuns._isSuccess) {
            const runData = projectRuns._data?.sort(
              (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
            setProjectRuns(runData ?? []);
          }
        } catch (error) {
          throw new Error('Failed to load project runs.');
        }
      };

      // Retrieves project runs for the following cases:
      // 1. Project runs have not yet been loaded in
      // 2. A scan is in progress and current number of project runs is equal to 0
      // 3. A scan is in progress and the most recent project run does not match the projects latest run id
      if (projectRuns === undefined) {
        handleGetProjectRuns();
      } else if (projectRuns.length == 0 && projectRunStatus?.startsWith('Currently Scanning')) {
        handleGetProjectRuns();
      } else if (
        projectRuns.length > 0 &&
        !projectRuns[0].endedAt &&
        projectRunStatus?.startsWith('Completed')
      ) {
        handleGetProjectRuns();
      } else if (projectRuns.length > 0 && project.lastDiscoveryRunId) {
        if (projectRuns[0].id != project.lastDiscoveryRunId) {
          handleGetProjectRuns();
        }
      }
    }
  }, [project, projectRuns, projectRunStatus, selectedRun]);

  if (!project) {
    return (
      <DealGPTContainer>
        <CircularProgress sx={{ margin: 'auto' }} />
      </DealGPTContainer>
    );
  }

  return (
    <DealGPTContainer>
      <div style={{ overflow: 'auto', width: '100%', height: '100%' }}>
        <Container maxWidth="lg" sx={{ paddingTop: '100px', height: '100%' }}>
          <ProjectContainer>
            <TitleContainer>
              <IconButton
                sx={{ height: '24px', width: '24px', marginRight: '10px' }}
                onClick={() => {
                  navigate(`/intellio-advantage`);
                }}
              >
                <ArrowBack sx={{ color: '#092B49' }} />
              </IconButton>
              <Typography sx={{ fontSize: '36px', fontWeight: '700' }}>
                {project.clientName && <span>{project.clientName} - </span>}
                <span>{project.name}</span>
              </Typography>
              <div
                style={{
                  marginLeft: '50px',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '10px',
                }}
              >
                {currentUser?.roles?.includes(UserRole.DEALGPT_ADMIN) && (
                  <Button
                    sx={{ flexGrow: '1', whiteSpace: 'nowrap' }}
                    variant="contained"
                    onClick={handleEditProjectClick}
                  >
                    Edit Project
                  </Button>
                )}
                <Button
                  sx={{ flexGrow: '1', whiteSpace: 'nowrap' }}
                  variant="contained"
                  onClick={handleConfigureProjectClick}
                >
                  Configure Project
                </Button>
              </div>
            </TitleContainer>
            <StyledTabs value={view} onChange={handleViewChange}>
              <StyledTab disableRipple value={DealGPTView.DISCOVERY} label="Discovery" />
              <StyledTab
                disableRipple
                value={DealGPTView.ANALYSIS}
                label={'Analysis'}
                onClick={() => {
                  analytics.event(AnalyticsEvent.WM_IA_TAB_VIEW, {
                    id: projectId,
                    value: DealGPTView.ANALYSIS,
                  });
                  navigate(`/intellio-advantage/${projectId}/scope-areas`);
                }}
              />
              <StyledTab disableRipple value={DealGPTView.CHAT} label="Chat" />
            </StyledTabs>
            {selectedView === DealGPTView.DISCOVERY && (
              <Discovery
                project={project}
                isLoadingRescan={isLoadingRescan}
                setIsLoadingRescan={setIsLoadingRescan}
                isLoadingScopesDialog={isLoadingScopesDialog}
                handleRescanDocuments={handleRescanDocuments}
                handleOpenFilterDialog={handleOpenFilterDialog}
                handleCloseFilterDialog={handleCloseFilterDialog}
                isScopePreferenceSelected={isScopePreferenceSelected}
                isSelectAllFilter={isSelectAllFilter}
                toggleSelectAllScopeFilter={toggleSelectAllScopeFilter}
                toggleFilter={toggleFilter}
                openFilterDialog={openFilterDialog}
                timer={timer}
                projectRuns={projectRuns}
                setSelectedRun={setSelectedRun}
                selectedRun={selectedRun}
                projectRunStatus={projectRunStatus ?? ''}
                distinctScopes={distinctScopes}
                numScopeAreasSelected={selectedScopePreferencesFilters.length}
              />
            )}
            {selectedView === DealGPTView.SCOPE_AREAS && <ScopeAreas project={project} />}
            {selectedView === DealGPTView.CHAT && <DealGPTChat project={project} />}
            {selectedView === DealGPTView.ANALYSIS && <Analysis project={project} />}
          </ProjectContainer>
        </Container>
      </div>
    </DealGPTContainer>
  );
};

export default DealGPTProjectContainer;
