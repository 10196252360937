import { CircularProgress } from '@mui/material';
import React from 'react';

interface LoadingMaskProps {
  isLoading: boolean;
}

const LoadingMask: React.FC<LoadingMaskProps> = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <div style={{ width: '100%', height: '100%', position: 'absolute', top: 0 }}>
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: 5,
              background: 'white',
              opacity: 0.8,
            }}
          />
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              left: 'calc(50% - 20px)', // The middle of the parent component - half the circular size
              top: 'calc(50% - 20px)',
              zIndex: 6,
            }}
          >
            <CircularProgress />
          </div>
        </div>
      )}
    </>
  );
};

export default LoadingMask;
