import { Checkbox, FormControlLabel, styled, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectPrompts, setSelectedTags } from '../../redux/reducers/promptReducer';
import { AppDispatch } from '../../redux/store';
import { Tag } from '../../Types/enums';

const FiltersContainer = styled('div')({
  padding: '0px',
  height: 'auto',
  userSelect: 'none',
  marginBottom: '100px',
});

const TitleRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: '15px',
});

const CheckboxContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '20px',
  marginTop: '5px',
});

const IndividualCheckboxContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

type GroupName = 'General' | 'Industry' | 'Practice';
type TagGroupings = {
  [key in GroupName]: string[];
};

const TAG_GROUPINGS: TagGroupings = {
  General: [
    'BRAINSTORMING',
    'EVALUATION',
    'EMAIL',
    'GENERAL',
    'NOTES',
    'PRODUCTIVITY',
    'RESEARCH',
    'SUMMARIZE',
  ],
  Industry: ['CONSUMER', 'ENERGY', 'FINANCIAL', 'HEALTHCARE', 'HIGH_TECH'],
  Practice: [
    'CUSTOMER_SOLUTIONS',
    'HUMAN_CAPITAL',
    'MERGERS_AND_AQUISITIONS',
    'OPERATIONS_EXCELLENCE',
    'FINANCIAL_MANAGEMENT',
    'TECHNOLOGY',
    'PRODUCT_EXPERIENCE',
    'INTELLIO_INSIGHTS',
  ],
};

const PromptFilters = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const { tagOptions, selectedTags } = useSelector(selectPrompts);

  const handleTagClick = (event: React.ChangeEvent<HTMLInputElement>, tag: string) => {
    let newTags: string[] = [];
    if (event.target.checked) {
      newTags = [...selectedTags, tag];
    } else {
      newTags = selectedTags.filter((t) => t !== tag);
    }

    dispatch(setSelectedTags(newTags));
  };
  return (
    <FiltersContainer id="filter-container">
      <TitleRow>
        <Typography
          variant="h5"
          sx={{
            fontSize: '16px',
            fontWeight: '700',
            marginTop: '10px',
            marginBottom: '10px',
            color: '#F900D3',
          }}
        >
          Prompt Filters
        </Typography>
      </TitleRow>
      {Object.keys(TAG_GROUPINGS).map((group: string, index: number) => (
        <div key={index}>
          <Typography variant="h6" sx={{ fontWeight: '500', fontSize: '16px', color: '#0047FF' }}>
            {group}
          </Typography>
          <CheckboxContainer>
            {(() => {
              const total = TAG_GROUPINGS[group as GroupName].reduce((acc, tag) => {
                const tagName = Tag[tag as keyof typeof Tag];
                return acc + tagOptions[tagName];
              }, 0);

              if (total > 0) {
                return TAG_GROUPINGS[group as GroupName].map((tag, index2: number) => {
                  const tagName = Tag[tag as keyof typeof Tag];
                  const tagCount = tagOptions[tagName];
                  return tagCount > 0 ? (
                    <IndividualCheckboxContainer
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        fontWeight: '500',
                        color: '#070154',
                      }}
                      key={index2}
                    >
                      <FormControlLabel
                        key={index2}
                        control={
                          <Checkbox
                            checked={selectedTags.includes(tagName)}
                            onChange={(event) => handleTagClick(event, tagName)}
                          />
                        }
                        label={`${tagName}`}
                        sx={{
                          userSelect: 'none',
                          display: 'flex',
                          alignItems: 'flex-start',
                          span: { display: 'flex', alignItems: 'flex-start', paddingTop: '0px' },
                        }}
                      />
                      <Typography style={{ float: 'right', color: 'grey', fontWeight: '500' }}>
                        {tagOptions[tagName]}
                      </Typography>
                    </IndividualCheckboxContainer>
                  ) : null;
                });
              } else {
                return (
                  <Typography
                    key={index}
                    variant="h6"
                    sx={{ fontWeight: '500', fontSize: '16px', color: '#070154' }}
                  >
                    No {group} prompts
                  </Typography>
                );
              }
            })()}
          </CheckboxContainer>
        </div>
      ))}
    </FiltersContainer>
  );
};

export default PromptFilters;
