import { styled, Typography } from '@mui/material';
import React, { useState } from 'react';

import { modifyTone } from '../../../services/dealGPT';
import { ProjectRunAnalysisQuestion } from '../../../Types/dealGPT';
import { DealGPTTone } from '../../../Types/enums';
import { AnalyticsEvent, useAnalytics } from '../../Providers/AnalyticsProvider';

const ModifyToneContainer = styled('div')({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  flexWrap: 'wrap',
});

type ToneChipProps = {
  selected: boolean;
};

const ToneChip = styled(Typography)<ToneChipProps>(({ selected }) => ({
  backgroundColor: selected ? '#CED7E6' : '#E8EEF8',
  color: selected ? '#ffffff' : '#092B49',
  padding: '8px 16px',
  borderRadius: '100px',
  fontSize: '14px',
  cursor: 'pointer',
}));

interface ModifyToneProps {
  question?: ProjectRunAnalysisQuestion | null;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  reprocessingTriggered: () => void;
}

const ModifyTone: React.FC<ModifyToneProps> = ({
  question,
  setIsLoading,
  reprocessingTriggered,
}) => {
  const [selectedTone, setSelectedtone] = useState<DealGPTTone>();
  const analytics = useAnalytics();

  const handleToneClick = async (tone: DealGPTTone) => {
    // send prompt with input
    setSelectedtone(tone);
    setIsLoading(true);
    if (question) {
      analytics.event(AnalyticsEvent.WM_IA_A_MODIFY_TONE, {
        id: question.id,
        value: question.pageName,
      });
      console.log('Make input', tone, ':', question.response);
      await modifyTone(tone, question);
      reprocessingTriggered();
      setSelectedtone(undefined);
    }
  };

  return (
    <ModifyToneContainer>
      <Typography sx={{ fontSize: '14px', color: '#757575' }}>Modify Voice/Tone</Typography>
      <ModifyToneContainer>
        {(Object.keys(DealGPTTone) as Array<keyof typeof DealGPTTone>).map((tone) => (
          <ToneChip
            key={tone}
            selected={selectedTone === DealGPTTone[tone]}
            onClick={() => handleToneClick(DealGPTTone[tone])}
          >
            {DealGPTTone[tone]}
          </ToneChip>
        ))}
      </ModifyToneContainer>
    </ModifyToneContainer>
  );
};

export default ModifyTone;
