import * as filesaver from 'file-saver';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  fetchCategoriesInfo,
  fetchProjectScopesAndCategories,
  generateReport,
} from '../../../services/dealGPT';
import type {
  CategoryPartialType,
  CategoryType,
  ProjectRun,
  ProjectScopesCategories,
  ProjectType,
} from '../../../Types/dealGPT';
import { DealGPTDiscoveryView, DealGPTQuestionStatus } from '../../../Types/enums';
import { AnalyticsEvent, useAnalytics } from '../../Providers/AnalyticsProvider';
import { useFeatures } from '../../Providers/FeatureProvider';
import DiscoveryView from '../Views/DiscoveryView';

export type DiscoveryProps = {
  project: ProjectType;
  isLoadingRescan: boolean;
  setIsLoadingRescan: (isLoading: boolean) => void;
  openFilterDialog: boolean;
  isLoadingScopesDialog: boolean;
  isScopePreferenceSelected: (scope: string) => boolean;
  toggleFilter: (scope: string) => void;
  toggleSelectAllScopeFilter: () => void;
  handleRescanDocuments: (projectId: string) => void;
  handleOpenFilterDialog: () => void;
  handleCloseFilterDialog: () => void;
  setSelectedRun: (run: ProjectRun | undefined) => void;
  timer: string | null;
  projectRuns: ProjectRun[] | undefined;
  selectedRun?: ProjectRun;
  projectRunStatus: string;
  distinctScopes: string[];
  numScopeAreasSelected: number;
  isSelectAllFilter: boolean;
};

const Discovery = ({
  project,
  isLoadingRescan,
  setIsLoadingRescan,
  openFilterDialog,
  isLoadingScopesDialog,
  isScopePreferenceSelected,
  toggleFilter,
  toggleSelectAllScopeFilter,
  handleRescanDocuments,
  handleOpenFilterDialog,
  handleCloseFilterDialog,
  setSelectedRun,
  selectedRun,
  timer,
  projectRuns,
  projectRunStatus,
  distinctScopes,
  numScopeAreasSelected,
  isSelectAllFilter,
}: DiscoveryProps): JSX.Element => {
  const [selectedScopeFilters, setSelectedScopeFilters] = useState<string[]>([]);
  const [scopes, setScopes] = useState<string[]>([]);
  const [selectedStatusFilters, setSelectedStatusFilters] = useState<DealGPTQuestionStatus[]>([]);
  const [statuses, setStatuses] = useState<DealGPTQuestionStatus[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [lastSelectedRun, setLastSelectedRun] = useState<ProjectRun | undefined>(selectedRun);
  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);
  const [lastScanned, setLastScanned] = useState<string>('Scan in progress...');
  const [openRescanDialog, setOpenRescanDialog] = useState<boolean>(false);
  const [expandAllCategories, setExpandAllCategories] = useState<boolean>(false);
  const [runProgressData, setRunProgressData] = useState<CategoryType[]>([]);
  const [shouldFetchRunProgress, setShouldFetchRunProgress] = useState<boolean>(false);
  const [aggregateProgressData, setAggregateProgressData] = useState<CategoryType>({
    name: 'aggregate',
    quantity: 0,
    quantityAnswered: 0,
    quantityMoreInfoNeeded: 0,
    quantityNotFound: 0,
    quantitySkipped: 0,
    scope: '',
  });
  const [discoveryView, setDiscoveryView] = useState<DealGPTDiscoveryView>(
    DealGPTDiscoveryView.ALL_QUESTIONS
  );
  const [isRunFetchCompleted, setIsRunFetchCompleted] = useState(false);
  const startTimeMarkRef = useRef(performance.mark('discovery-start'));

  const features = useFeatures();
  const analytics = useAnalytics();

  const handleOpenRescanDialog = () => {
    setOpenRescanDialog(true);
  };

  const handleCloseRescanDialog = () => {
    setOpenRescanDialog(false);
  };

  const handleConfirmFilterDialog = () => {
    analytics.event(AnalyticsEvent.WM_IA_D_RUN_PROJECT, {
      id: project.id,
      value: `Scopes=${selectedScopeFilters.toString()}`,
    });
    handleRescanDocuments(project.id);
    handleCloseFilterDialog();
  };

  const handleGenerateReport = useCallback(async () => {
    try {
      setIsLoadingReport(true);
      analytics.event(AnalyticsEvent.WM_IA_D_GENERATE_REPORT, { id: project.id });
      let reportStatuses = [];

      if (discoveryView === DealGPTDiscoveryView.SITE_VISIT_PREP) {
        reportStatuses = [
          DealGPTQuestionStatus.MORE_INFO_NEEDED,
          DealGPTQuestionStatus.NOT_FOUND,
          DealGPTQuestionStatus.SKIPPED,
        ];
      } else {
        reportStatuses = selectedStatusFilters.length == 0 ? statuses : selectedStatusFilters;
      }

      const response = await generateReport(
        project.id,
        discoveryView,
        selectedScopeFilters.length == 0 ? scopes : selectedScopeFilters,
        reportStatuses
      );
      filesaver.saveAs(
        new Blob([response._data ?? '']),
        `Intellio®-Advantage-${project.name}-${new Date().toISOString()}.csv`
      );
    } catch (error) {
      enqueueSnackbar(`Failed to generate report: ${error}`, { variant: 'error' });
    } finally {
      setIsLoadingReport(false);
    }
  }, [
    discoveryView,
    project.id,
    project.name,
    scopes,
    selectedScopeFilters,
    statuses,
    selectedStatusFilters,
    analytics,
  ]);

  const handleClearScopeFilters = () => {
    setSelectedScopeFilters([]);
  };

  const getScope = (scope: string) => {
    return selectedScopeFilters.find((s: string) => s === scope);
  };

  const isScopeSelected = (scope: string) => {
    const selectedScope = getScope(scope);
    return !!selectedScope;
  };

  const toggleScopeFilters = (scope: string) => {
    setIsFiltering(true);
    let selected = false;
    if (selectedScopeFilters.find((s) => s === scope)) {
      setSelectedScopeFilters((prevFilters) =>
        prevFilters.filter((prevScope) => prevScope !== scope)
      );
    } else {
      setSelectedScopeFilters([...selectedScopeFilters, scope]);
      selected = true;
    }
    analytics.event(AnalyticsEvent.WM_IA_D_QUESTION_FILTER, {
      id: project.id,
      value: `${scope}=${selected}`,
    });
  };

  const handleClearStatusFilters = () => {
    setSelectedStatusFilters([]);
  };

  const isStatusSelected = (status: DealGPTQuestionStatus) => {
    return selectedStatusFilters.includes(status);
  };

  const toggleStatusFilters = (status: DealGPTQuestionStatus) => {
    setIsFiltering(true);
    let selected = false;
    if (selectedStatusFilters.find((s) => s === status)) {
      setSelectedStatusFilters((prevFilters) =>
        prevFilters.filter((prevStatus) => prevStatus !== status)
      );
    } else {
      setSelectedStatusFilters([...selectedStatusFilters, status]);
      selected = true;
    }
    analytics.event(AnalyticsEvent.WM_IA_D_QUESTION_FILTER, {
      id: project.id,
      value: `${status}=${selected}`,
    });
  };

  const wrapSetSearchQuery = (query: string) => {
    setIsFiltering(true);
    setSearchQuery(query);
  };

  const toggleCollapseExpandAllCategories = () => {
    analytics.event(AnalyticsEvent.WM_IA_D_EXPAND_ALL_CATEGORIES, {
      id: project.id,
      value: (!expandAllCategories).toString(),
    });
    setExpandAllCategories(!expandAllCategories);
  };

  const aggregateCategoryData = (categoriesInfo: CategoryType[]) => {
    const aggregateInfo: CategoryType = {
      name: 'aggregate',
      quantity: 0,
      quantityAnswered: 0,
      quantityMoreInfoNeeded: 0,
      quantityNotFound: 0,
      quantitySkipped: 0,
      scope: '',
    };

    categoriesInfo.forEach((category) => {
      aggregateInfo.quantity += category.quantity;
      if (
        aggregateInfo.quantityAnswered !== undefined &&
        aggregateInfo.quantityMoreInfoNeeded !== undefined &&
        aggregateInfo.quantityNotFound !== undefined &&
        aggregateInfo.quantitySkipped !== undefined &&
        category.quantityAnswered !== undefined &&
        category.quantityMoreInfoNeeded !== undefined &&
        category.quantityNotFound !== undefined &&
        category.quantitySkipped !== undefined
      ) {
        aggregateInfo.quantityAnswered += category.quantityAnswered;
        aggregateInfo.quantityMoreInfoNeeded += category.quantityMoreInfoNeeded;
        aggregateInfo.quantityNotFound += category.quantityNotFound;
        aggregateInfo.quantitySkipped += category.quantitySkipped;
      }
    });

    return aggregateInfo;
  };

  const getIncludedStatuses = (scopeAndCategories: ProjectScopesCategories[]) => {
    const includedStatuses: DealGPTQuestionStatus[] = [];

    scopeAndCategories.forEach((scopeAndCategory) => {
      if (
        !includedStatuses.includes(DealGPTQuestionStatus.ANSWERED) &&
        scopeAndCategory.quantityOfAnsweredQuestions > 0
      ) {
        includedStatuses.push(DealGPTQuestionStatus.ANSWERED);
      }
      if (
        !includedStatuses.includes(DealGPTQuestionStatus.MORE_INFO_NEEDED) &&
        scopeAndCategory.quantityOfMoreInfoNeededQuestions > 0
      ) {
        includedStatuses.push(DealGPTQuestionStatus.MORE_INFO_NEEDED);
      }
      if (
        !includedStatuses.includes(DealGPTQuestionStatus.NOT_FOUND) &&
        scopeAndCategory.quantityOfNotFoundQuestions > 0
      ) {
        includedStatuses.push(DealGPTQuestionStatus.NOT_FOUND);
      }
      if (
        !includedStatuses.includes(DealGPTQuestionStatus.SKIPPED) &&
        scopeAndCategory.quantityOfSkippedQuestions > 0
      ) {
        includedStatuses.push(DealGPTQuestionStatus.SKIPPED);
      }
      if (
        !includedStatuses.includes(DealGPTQuestionStatus.PENDING) &&
        scopeAndCategory.quantityOfPendingQuestions > 0
      ) {
        includedStatuses.push(DealGPTQuestionStatus.PENDING);
      }
    });

    return includedStatuses;
  };

  useEffect(() => {
    setShouldFetchRunProgress(false);
    // Fetch question scope and categories
    let categories: CategoryPartialType[] = [];
    const fetchQuestionScopeAndCategories = async () => {
      if (selectedRun) {
        console.log('running once');
        console.log(selectedRun);

        const result = await fetchProjectScopesAndCategories(project.id, selectedRun.id);
        if (result._isSuccess && result._data) {
          // extract scopes into a set
          const scopesArray = result._data.map((scopeAndCategory) => scopeAndCategory.scope);
          const scopesSet = Array.from(new Set(scopesArray));

          const categoriesArray = result._data.map((scopeAndCategory) => ({
            name: scopeAndCategory.category,
            scope: scopeAndCategory.scope,
            quantity: scopeAndCategory.quantityOfQuestions,
          }));

          setStatuses(getIncludedStatuses(result._data ?? []));
          setScopes(scopesSet);
          categories = categoriesArray;
        } else if (result._isSuccess && result._data?.length === 0) {
          setStatuses([]);
          setScopes([]);
          setRunProgressData([]);
          setAggregateProgressData({
            name: 'aggregate',
            quantity: 0,
            quantityAnswered: 0,
            quantityMoreInfoNeeded: 0,
            quantityNotFound: 0,
            quantitySkipped: 0,
            scope: '',
          });

          enqueueSnackbar('No questions for selected run', { variant: 'info' });
        }

        const filterRunProgressData = async () => {
          try {
            const selectedCategoryScopes: CategoryPartialType[] =
              selectedScopeFilters.length === 0
                ? categories
                : selectedScopeFilters
                    .map((scope) => {
                      return categories.filter((category) => category.scope === scope);
                    })
                    .flat();

            const result = await fetchCategoriesInfo(
              project.id,
              selectedCategoryScopes,
              selectedRun.id,
              discoveryView === DealGPTDiscoveryView.SITE_VISIT_PREP
                ? [
                    DealGPTQuestionStatus.MORE_INFO_NEEDED,
                    DealGPTQuestionStatus.NOT_FOUND,
                    DealGPTQuestionStatus.SKIPPED,
                  ]
                : selectedStatusFilters,
              searchQuery
            );
            if (result._isSuccess && result._data) {
              const progressInfo = result._data;
              setRunProgressData(progressInfo);
              setAggregateProgressData(aggregateCategoryData(progressInfo));
            } else {
              enqueueSnackbar(`Failed to fetch run progress data: ${result._error}`, {
                variant: 'error',
              });
            }
          } catch (error) {
            enqueueSnackbar(`Failed to fetch run progress data: ${error}`, { variant: 'error' });
            // setIsLoadingRunData(false);
          }
        };
        await filterRunProgressData();
        setLastSelectedRun(selectedRun);
        setIsFiltering(false);
        setIsRunFetchCompleted(true);
      }
    };

    // TODO: hotfix, make sure this doesn't execute if it doesn't have to (i.e. when project.projectRunStatus is not 'running' or 'completed')
    // but we have to make sure it runs once to load questions on initial page load
    fetchQuestionScopeAndCategories();
  }, [
    project.id,
    project.lastScanned,
    timer,
    project.projectRunStatus,
    selectedRun,
    lastSelectedRun,
    runProgressData.length,
    selectedScopeFilters,
    selectedStatusFilters,
    searchQuery,
    discoveryView,
    shouldFetchRunProgress,
  ]);

  useEffect(() => {
    if (project.lastScanned) {
      setLastScanned(new Date(project.lastScanned).toLocaleString());
    } else {
      setLastScanned(
        'Never scanned, please reach out to the Intellio® Advantage team to kick off a scan.'
      );
    }
  }, [handleRescanDocuments, project.id, project.lastScanned]);

  useEffect(() => {
    // Check if all Run/category data have loaded
    if (isRunFetchCompleted) {
      // Mark the end of the loading process
      const endTimeMark = performance.mark('discovery-end');

      // Calculate page load time
      const pageLoadTime = endTimeMark.startTime - startTimeMarkRef.current.startTime;

      //Send Google Analytic Event
      analytics.event(AnalyticsEvent.WM_IA_D_PAGE_LOAD_TIME, {
        id: project.id,
        value: pageLoadTime.toFixed(2),
      });

      // Clean up performance entries
      performance.clearMarks();
      performance.clearMeasures();
    }
  }, [isRunFetchCompleted, analytics, project.id]);

  return (
    <DiscoveryView
      features={features}
      project={project}
      scopes={scopes}
      statuses={statuses}
      selectedStatusFilters={selectedStatusFilters}
      distinctScopes={distinctScopes}
      numScopeAreasSelected={numScopeAreasSelected}
      searchQuery={searchQuery}
      setSearchQuery={wrapSetSearchQuery}
      isFiltering={isFiltering}
      selectedRun={selectedRun}
      isLoadingRescan={isLoadingRescan}
      setIsLoadingRescan={setIsLoadingRescan}
      isLoadingReport={isLoadingReport}
      isLoadingScopesDialog={isLoadingScopesDialog}
      openFilterDialog={openFilterDialog}
      openRescanDialog={openRescanDialog}
      lastScanned={lastScanned}
      setShouldFetchRunProgress={setShouldFetchRunProgress}
      timer={timer}
      projectRuns={projectRuns}
      runProgressData={runProgressData}
      aggregateProgressData={aggregateProgressData}
      expandAllCategories={expandAllCategories}
      handleOpenFilterDialog={handleOpenFilterDialog}
      handleConfirmFilterDialog={handleConfirmFilterDialog}
      handleOpenRescanDialog={handleOpenRescanDialog}
      handleCloseRescanDialog={handleCloseRescanDialog}
      handleCloseFilterDialog={handleCloseFilterDialog}
      handleGenerateReport={handleGenerateReport}
      setSelectedRun={setSelectedRun}
      isScopeSelected={isScopeSelected}
      isStatusSelected={isStatusSelected}
      isScopePreferenceSelected={isScopePreferenceSelected}
      isSelectAllFilter={isSelectAllFilter}
      toggleFilter={toggleFilter}
      toggleSelectAllScopeFilter={toggleSelectAllScopeFilter}
      toggleScopeFilters={toggleScopeFilters}
      toggleStatusFilters={toggleStatusFilters}
      handleClearScopeFilters={handleClearScopeFilters}
      handleClearStatusFilters={handleClearStatusFilters}
      toggleCollapseExpandAllCategories={toggleCollapseExpandAllCategories}
      projectRunStatus={projectRunStatus ?? ''}
      discoveryView={discoveryView}
      setDiscoveryView={setDiscoveryView}
    />
  );
};

export default Discovery;
