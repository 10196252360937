import { useEffect, useRef, useState } from 'react';
import { RubricCore } from 'Types/dealGPT';

import { getRubricCore } from '../services/dealGPT';

/**
 * Custom hook that fetches and organizes rubric core data by area and dimension
 * @param {string} scope - The scope parameter used to fetch specific rubric core data
 * @returns {Record<string, Record<string, RubricCore[]>>} An object containing rubric core items grouped by area and dimension
 */
export const useRubricCore = (
  scope: string | undefined
): Record<string, Record<string, RubricCore[]>> => {
  const [rubricCoreByArea, setRubricCoreByArea] = useState<
    Record<string, Record<string, RubricCore[]>>
  >({});

  // Ref to store the previous scope value, used to prevent unnecessary fetches
  const prevScopeRef = useRef<string | undefined>();

  useEffect(() => {
    // Little optimization to prevent unnecessary fetches when parent component re-renders
    if (!scope || scope === prevScopeRef.current) return;
    prevScopeRef.current = scope;

    const getCore = async () => {
      const core = (await getRubricCore(scope))?._data;
      if (!core) return;

      // Group by area and dimension
      const grouped = core.reduce(
        (acc: Record<string, Record<string, RubricCore[]>>, item: RubricCore) => {
          acc[item.area] = acc[item.area] || {};
          acc[item.area][item.dimension] = acc[item.area][item.dimension] || [];
          acc[item.area][item.dimension].push(item);
          return acc;
        },
        {}
      );

      setRubricCoreByArea(grouped);
    };

    getCore();
  }, [scope, prevScopeRef]);

  return rubricCoreByArea;
};
