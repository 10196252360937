import { Button, CircularProgress, styled, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getLatestRun, getScopeQuestions } from '../../../services/dealGPT';
import type { ProjectScopeAreaType } from '../../../Types/dealGPT';
import { RunType, ScopeAreasStatus, UserRole } from '../../../Types/enums';
import { AnalyticsEvent, useAnalytics } from '../../Providers/AnalyticsProvider';
import { useAuth } from '../../Providers/AuthProvider';
import ScopeAreaStatus from './ScopeAreaStatus';

const ScopeAreaDetailContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: '#ffffff',
  alignItems: 'center',
  padding: '20px 0',
});

const SecondColumnContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const StyledButton = styled(Button)({
  width: '100px',
  height: '35px',
});

export type ScopeAreaDetailProps = {
  scopeArea: ProjectScopeAreaType;
  projectId: string;
};

const ScopeAreaDetail = ({ scopeArea, projectId }: ScopeAreaDetailProps): JSX.Element => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (id: string, scope: string, freshRun: boolean) => {
    setIsLoading(true);
    let latestRunId = undefined;

    if (!freshRun) {
      // if it isn't a fresh run, try to find existing run
      const runResponse = await getLatestRun({
        projectId: id,
        scope,
        runType: RunType.ANALYSIS,
      });

      if (runResponse._data) {
        latestRunId = runResponse._data.id;
      }
    }

    await getScopeQuestions({
      projectId: id,
      scope,
      runId: latestRunId,
    });
    const event = AnalyticsEvent.WM_IA_A_SCOPE_AREA_VIEW;

    analytics.event(event, { id: id, value: scope });
    navigate(`/intellio-advantage/${id}/analysis/${scope}`);
  };

  if (!scopeArea) {
    return <></>;
  }

  return (
    <ScopeAreaDetailContainer key={scopeArea.scope}>
      <Typography
        sx={{ fontSize: '16px', fontWeight: '600', marginLeft: '15px', verticalAlign: 'middle' }}
      >
        {scopeArea.scope}
      </Typography>
      <SecondColumnContainer>
        {isLoading ? <CircularProgress size={'20px'} sx={{ marginLeft: '10px' }} /> : null}
        <ScopeAreaStatus status={scopeArea.status || ScopeAreasStatus.NOT_STARTED} />
        <StyledButton
          variant="outlined"
          onClick={() => handleClick(projectId, scopeArea.scope, false)}
          disabled={isLoading}
        >
          View
        </StyledButton>
        {currentUser?.roles.includes(UserRole.DEALGPT_ADMIN) && (
          <StyledButton
            variant="contained"
            onClick={() => handleClick(projectId, scopeArea.scope, true)}
            disabled={isLoading}
          >
            New Run
          </StyledButton>
        )}
      </SecondColumnContainer>
    </ScopeAreaDetailContainer>
  );
};

export default ScopeAreaDetail;
