import { Workbook } from 'exceljs';

/**
 * Downloads the Excel file
 * @param {Workbook} workbook - The Excel workbook to be downloaded
 * @param {string} filename - The name of the Excel file
 */
export const downloadExcel = async (workbook: Workbook, filename: string) => {
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
